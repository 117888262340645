import { MaterialMetricsPerDisclosureQuery_ } from 'models';
import { CompanyBothIcon, CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { FrequencyEnums } from '../DisclosureRequirements';

export type MaterialMetricPerDisclosure =
  MaterialMetricsPerDisclosureQuery_['assessableMetrics'][number];

export type TableMetricData = MaterialMetricPerDisclosure & {
  isExpanded?: boolean;
  isChild?: boolean;
  subRows?: TableMetricData[];
  type: 'metric' | 'tag';
  parentMetric?: MaterialMetricPerDisclosure;
};

export type MaterialMetricTag = {
  tagType: string;
  isOptional: boolean;
  id: any;
  materialTagValues: {
    tagType: string;
    tagValue: string;
  }[];
};

export type MetricTag = {
  type: string;
  value: string;
  subRows?: TableMetricData[];
  subTags: MaterialMetricTag[];
  parentMetric?: MaterialMetricPerDisclosure;
};

export type TagStatus = {
  state: BreakdownStatus;
  number: number;
};

export enum BreakdownStatus {
  required = 'required',
  added = 'added',
  available = 'available',
  requested = 'requested',
  notApplicable = 'notApplicable',
}

export enum DataCollectionLevel {
  company = 'company',
  reportingUnits = 'reportingUnits',
  group = 'group',
  subsidiaries = 'subsidiaries',
}

export enum DataCollectionRowEnum {
  metric = 'metric',
  tag = 'tag',
}

export type DataCollectionType =
  | DataCollectionLevel.group
  | DataCollectionLevel.subsidiaries
  | DataCollectionLevel.company
  | DataCollectionLevel.reportingUnits;

export const DataCollectionLabelOptions = {
  [DataCollectionLevel.group]: {
    text: 'Parent company',
    icon: <CompanyTopDownIcon />,
  },
  [DataCollectionLevel.subsidiaries]: {
    text: 'Subsidiaries',
    icon: <CompanyBottomUpIcon />,
  },
  [DataCollectionLevel.company]: {
    text: 'Company level',
    icon: <CompanyTopDownIcon />,
  },
  [DataCollectionLevel.reportingUnits]: {
    text: 'Business units',
    icon: <CompanyBottomUpIcon />,
  },
  mixed: {
    text: 'Mixed',
    icon: <CompanyBothIcon />,
  },
};

export const GROUP_DATA_GATHERING_OPTIONS: { label: string; value: string }[] = [
  { label: 'Group level', value: DataCollectionLevel.group },
  { label: 'Subsidiaries', value: DataCollectionLevel.subsidiaries },
];

export const SUBSIDIARY_DATA_GATHERING_OPTIONS: { label: string; value: string }[] = [
  { label: 'Company level', value: DataCollectionLevel.company },
  { label: 'Business units', value: DataCollectionLevel.reportingUnits },
];

export const FREQUENCY_OPTIONS: { label: string; value: string }[] = [
  { label: 'Yearly', value: FrequencyEnums.yearly },
  { label: 'Quarterly', value: FrequencyEnums.quarterly },
];
