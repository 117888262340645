import { Skeleton, HStack, Box } from '@chakra-ui/react';
import { ESRSNumberInput, Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import {
  TimePeriods,
  TimePeriodsEnums,
  DatapointSourceEnum,
} from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { debounce } from 'lodash';
import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AggregatedMetricsTableData,
  showResultAsPercentage,
  showResult,
  hasQuarterlyFrequency,
  isFullMetricOnBULevel,
} from '../../../../AggregatedMetrics';
import { DatapointFieldsFragment_ } from 'models';
import {
  MetricsTableData,
  findParentRow,
  isFrequencyYearly,
  useAggregatedRowValue,
  usePercentageValues,
} from '../../../../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuantitativeInputs.hooks';
import { PercentageValueView } from '../../MetricYear/MetricYear';

export const QuarterField = ({
  row,
  frequency,
  value,
  selectedQuarter,
  showAsPercentage,
  allMetrics,
  isNarrativeTable,
  currentMetric,
}: {
  row: MetricsTableData;
  frequency: string;
  value: number | string;
  selectedQuarter: TimePeriods;
  showAsPercentage?: boolean;
  allMetrics: AggregatedMetricsTableData[];
  isNarrativeTable?: boolean;
  currentMetric: AggregatedMetricsTableData;
}) => {
  const isYearly = useMemo(() => isFrequencyYearly(row.metric), [frequency]);

  const tags = useMemo(() => {
    return currentMetric.tags ?? [];
  }, [currentMetric]);

  const percentageTotal = useMemo(() => {
    if (currentMetric.tags?.length) {
      const parentMetric = allMetrics?.find(
        (m) => m.metric.reference === currentMetric.metric.reference && !m.tags?.length
      );
      return showResultAsPercentage(value, parentMetric?.result?.[selectedQuarter] ?? 0);
    }

    return showResultAsPercentage(100, 100);
  }, [allMetrics, currentMetric]);

  if (
    isYearly &&
    selectedQuarter !== TimePeriodsEnums.year &&
    row.metric.childrenMetrics.length === 0
  )
    return (
      <Typography variant="body" color="text.hint" p={isNarrativeTable ? '8px 0px' : ''}>
        {showResult(value)}
        {' (Collected yearly)'}
      </Typography>
    );

  if (showAsPercentage) {
    return (
      <Typography variant="body" p={isNarrativeTable ? '8px 0px' : ''}>
        {showResult(value)}

        <Tooltip
          label={`${currentMetric.metric.title} ${tags.length ? ' by ' : ''} ${tags.map(
            (x) => x.tagValue
          )}: ${percentageTotal.replace('(', '').replace(')', '')}`}
          cursor="pointer"
        >
          <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}>
            {percentageTotal}
          </span>
        </Tooltip>
      </Typography>
    );
  }
  return (
    <Typography variant="body" maxW="250px" p={isNarrativeTable ? '8px 0px' : ''}>
      {showResult(value)}{' '}
      {!hasQuarterlyFrequency(currentMetric.metric) &&
      selectedQuarter !== TimePeriodsEnums.year &&
      currentMetric.metric.childrenMetrics?.length === 0
        ? '(Collected yearly)'
        : ''}
    </Typography>
  );
};

const DatapointInput = ({
  hasOptedOut,
  updateDatapoint,
  value,
  datapoint,
  isDisabled = false,
}: {
  value?: number;
  hasOptedOut: boolean;
  datapoint?: DatapointFieldsFragment_;
  updateDatapoint: (value: number | null, datapoint?: DatapointFieldsFragment_) => void;
  isDisabled?: boolean;
}) => {
  const [inputKey, setInputKey] = useState(Date.now());
  const isManualChangeRef = useRef(false);

  const debounceSave = useCallback(
    debounce((newValue) => {
      updateDatapoint(newValue, datapoint);
    }, 500),
    [updateDatapoint, datapoint]
  );

  const handleChange = (val: number | null) => {
    isManualChangeRef.current = true; // mark change as manual (not ai) to prevent re-render
    debounceSave(val);
  };

  // change key to force re-render after ai answer generation and change defaultValue
  useEffect(() => {
    if (!isManualChangeRef.current && datapoint?.value !== value) {
      setInputKey(Date.now());
    }
  }, [datapoint, value]);

  return (
    <ESRSNumberInput
      key={inputKey}
      value={value}
      onChange={handleChange}
      isClearable={hasOptedOut || isDisabled ? false : true}
      variant="ghost"
      onClick={(e) => e.stopPropagation()}
      opacity={hasOptedOut ? 0.4 : 1}
      isDisabled={hasOptedOut || isDisabled}
      width="150px"
      paddingLeft="6px"
      colorProps={{
        _disabled: {
          bg: 'bg.default',
          cursor: 'not-allowed',
          border: 'none',
          boxShadow: 'none',
        },
      }}
      onBlur={() => (isManualChangeRef.current = false)}
    />
  );
};

export const QuarterInput = ({
  row,
  selectedQuarter,
  isOverview,
  companyReportingUnit,
  assessmentProjectLeaderId,
  setRowData,
  nestedMetrics,
}: {
  row: MetricsTableData;
  selectedQuarter: TimePeriods;
  isOverview?: boolean;
  companyReportingUnit?: string;
  assessmentProjectLeaderId?: string;
  setRowData?: (param: MetricsTableData | undefined) => void;
  nestedMetrics: MetricsTableData[];
}) => {
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const {
    answer,
    onDatapointChange,
    loading,
    dataPointsPerQuarter,
    dataPointPerYear,
    isAggregated,
  } = useGetDatapointValues(row, companyReportingUnit);

  const isYearly = useMemo(() => isFrequencyYearly(row.metric), [row]);

  const dataPointPerQuarter = useMemo(
    () => dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter),
    [selectedQuarter, dataPointsPerQuarter]
  );
  const { result: aggregatedValues } = useAggregatedRowValue(row, isYearly, companyReportingUnit);

  const showAsPercentage = useMemo(() => {
    return row.metric.showAsPercentage ?? false;
  }, [row]);

  const nestedRows =
    nestedMetrics?.find((m) => m.metric.reference === row.metric.reference) ?? nestedMetrics[0];

  const percentageValue = usePercentageValues(
    findParentRow(nestedRows, row),
    row,
    selectedQuarter,
    isYearly,
    isAggregated ? aggregatedValues[selectedQuarter] : dataPointPerYear?.value,
    companyReportingUnit
  );

  const updateDatapoints = (value: number | null, dp: Partial<DatapointFieldsFragment_>) => {
    const data = value;
    onDatapointChange({
      value: data === null ? data : String(data),
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dp,
      tags: row.tags,
      assessmentProjectLeaderId: dp.ownerId ?? assessmentProjectLeaderId,
    });
  };

  const drawerDatapoint = useMemo(
    () => (!!row?.subRows?.length || isYearly ? dataPointPerYear : dataPointPerQuarter?.value),
    [isYearly, dataPointPerYear, dataPointPerQuarter]
  );

  useEffect(() => {
    if (drawerDatapoint?.id === urlDatapointId && openDrawer) {
      setRowData?.(row);
    }
  }, [urlDatapointId, drawerDatapoint, openDrawer]);

  if (isYearly && selectedQuarter !== TimePeriodsEnums.year) {
    return (
      <Box py="8px">
        <Typography variant="body" color="text.hint" w="100%">
          {isAggregated ? showResult(aggregatedValues.Year) ?? 0 : dataPointPerYear?.value ?? 0}{' '}
          (Collected yearly)
        </Typography>
      </Box>
    );
  }
  if (!isYearly && selectedQuarter === TimePeriodsEnums.year)
    return (
      <Box py="8px">
        <Typography variant="body" color="text.hint" w="100%">
          {showResult(aggregatedValues[TimePeriodsEnums.year])} (Collected quarterly)
        </Typography>
      </Box>
    );

  if (isAggregated) {
    return (
      <Box py="8px">
        <Typography variant="body" w="100%">
          {showResult(aggregatedValues[selectedQuarter])}{' '}
          {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
        </Typography>
      </Box>
    );
  }

  if (isYearly) {
    return (
      <Skeleton isLoaded={!loading} w="100%">
        {isOverview ? (
          <Typography variant="body" w="100%">
            {dataPointPerQuarter?.value?.value ?? 0}{' '}
            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </Typography>
        ) : (
          <HStack mx="-4px" spacing="4px">
            <DatapointInput
              hasOptedOut={answer?.hasOptedOut ?? false}
              value={dataPointPerYear?.value ? Number(dataPointPerYear?.value) : undefined}
              datapoint={dataPointPerYear}
              updateDatapoint={(value, datapoint) =>
                updateDatapoints(value, datapoint ?? { timeframe: TimePeriodsEnums.year })
              }
              isDisabled={dataPointPerYear?.source === DatapointSourceEnum.moreScope}
            />

            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </HStack>
        )}
      </Skeleton>
    );
  }
  if (!isYearly) {
    return (
      <Skeleton isLoaded={!loading}>
        {isOverview ? (
          <Box py="8px">
            <Typography variant="body" w="100%">
              {dataPointPerQuarter?.value?.value ?? 0}{' '}
              {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
            </Typography>
          </Box>
        ) : (
          <HStack mx="-4px" spacing="4px">
            <DatapointInput
              hasOptedOut={answer?.hasOptedOut ?? false}
              value={
                dataPointPerQuarter?.value?.value
                  ? Number(dataPointPerQuarter?.value?.value)
                  : undefined
              }
              datapoint={dataPointPerQuarter?.value}
              updateDatapoint={(value, datapoint) =>
                updateDatapoints(value, datapoint ?? { timeframe: dataPointPerQuarter?.field })
              }
            />
            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </HStack>
        )}
      </Skeleton>
    );
  }
  return (
    <Box py="8px">
      <Typography variant="body" color="text.hint">
        N/A
      </Typography>
    </Box>
  );
};

export const QuantitativeMetricsInput = ({
  metricRow,
  selectedQuarter,
  companyReportingUnit,
  metrics,
  assessmentProjectLeaderId,
  setRowData,
  isNarrativeTable,
  isBusinessUnit,
  isGroup,
}: {
  metricRow: AggregatedMetricsTableData;
  selectedQuarter: TimePeriods;
  metrics: AggregatedMetricsTableData[];
  companyReportingUnit: string;
  assessmentProjectLeaderId: string;
  setRowData: (param: MetricsTableData | undefined) => void;
  isNarrativeTable?: boolean;
  isBusinessUnit?: boolean;
  isGroup: boolean;
}) => {
  const materialMetric = useMemo(() => metricRow.metric.materialMetrics?.[0], [metricRow]);

  const showInput = useMemo(
    () =>
      (!metricRow.subRows?.length &&
        ((materialMetric?.dataCollection === DataCollectionLevel.group && isGroup) ||
          (materialMetric?.dataCollection === DataCollectionLevel.company && !isBusinessUnit))) ||
      (isFullMetricOnBULevel(metricRow.metric) && isBusinessUnit),
    [metricRow, materialMetric, isBusinessUnit, isGroup]
  );

  return showInput ? (
    <QuarterInput
      row={metricRow}
      selectedQuarter={selectedQuarter}
      companyReportingUnit={companyReportingUnit}
      assessmentProjectLeaderId={assessmentProjectLeaderId}
      setRowData={setRowData}
      nestedMetrics={metrics}
    />
  ) : (
    <QuarterField
      row={metricRow}
      frequency={materialMetric?.frequency ?? 'yearly'}
      value={metricRow.result?.[selectedQuarter] ?? 0}
      selectedQuarter={selectedQuarter}
      allMetrics={metrics}
      isNarrativeTable={isNarrativeTable}
      currentMetric={metricRow}
      showAsPercentage={metricRow.metric?.showAsPercentage ?? false}
    />
  );
};
