import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetMaterialityAssessment,
  getUnassessedMandatoryStandardData,
  useAddMandatoryMaterialityAssessment,
} from './MaterialityAssessment.hooks';
import { ContentHeader, ContentLayout, Table } from 'Molecules';
import { Typography } from 'Tokens';
import { ColumnDef } from '@tanstack/react-table';
import { MaterialMap, MaterialStandard } from './MaterialityAssessment.d';
import { TableContainer, VStack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useCurrentCompanyId } from 'utils/hooks';
import { MaterialityAssessmentHeader } from './MaterialitySettingsHeader';
import { Tag } from 'Atoms';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { useGetSubsidiaryType } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  CollectOnlyMaterialityAssessment,
  CollectOnlyMaterialityStatus,
} from './CollectOnlyMaterialityAssessment';
import { MaterialityStatusBox } from './DoubleMaterialityAssessment/MaterialityUtils';

export const MaterialityAssessmentTable = ({
  assessment,
  hasParentMaterialityAssessment,
  companyId,
  esrsAssessmentId,
  setStep,
  isOnboarding,
  isCollectOnly = false,
}: {
  assessment: MaterialMap;
  hasParentMaterialityAssessment: boolean;
  companyId: string | null;
  esrsAssessmentId: string;
  setStep?: (step: number) => void;
  isOnboarding: boolean;
  isCollectOnly?: boolean | null;
}) => {
  const navigate = useNavigate();
  const columns: ColumnDef<MaterialStandard>[] = useMemo(() => {
    const initial: ColumnDef<MaterialStandard>[] = [
      {
        header: 'Standard',
        accessorKey: 'standardName',
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <VStack spacing={0} alignItems="start">
              <Typography variant="bodyStrong">{row.original.standardName}</Typography>
              <Typography variant="micro">{row.original.standardRef}</Typography>
            </VStack>
          );
        },
        meta: {
          width: '50%',
        },
      },
    ];

    if (hasParentMaterialityAssessment) {
      initial.push({
        header: 'Group materiality',
        accessorKey: 'Group materiality',
        enableSorting: false,
        cell: ({ row }) => {
          if (
            row.original.isParentMaterial === undefined ||
            row.original.isParentMaterial === null
          ) {
            return <Typography variant="body">Not assessed</Typography>;
          }
          if (!row.original.isTopical) return <Typography variant="body">Mandatory</Typography>;
          return row.original.isParentMaterial ? (
            <Typography variant="body">Material</Typography>
          ) : (
            <Typography variant="body">Not material</Typography>
          );
        },
      });
    }
    initial.push({
      header: hasParentMaterialityAssessment ? 'Your company' : 'Status',
      accessorKey: hasParentMaterialityAssessment ? 'Your company' : 'Status',
      enableSorting: false,
      cell: ({ row }) => {
        if (isCollectOnly) {
          return <CollectOnlyMaterialityStatus row={row.original} />;
        }
        if (row.original.isDataGatheringOnly) {
          return <Tag title="Gather data" variant="default" borderRadius="6px" />;
        }
        if (!row.original.isTopical) return <MaterialityStatusBox materiality="mandatory" />;
        if (row.original.isMaterial === undefined || row.original.isMaterial === null) {
          return <MaterialityStatusBox materiality="toAssess" />;
        } else {
          return row.original.isMaterial ? (
            <MaterialityStatusBox materiality="material" />
          ) : (
            <MaterialityStatusBox materiality="notMaterial" />
          );
        }
      },
    });
    return initial;
  }, [hasParentMaterialityAssessment, isCollectOnly]);
  return (
    <TableContainer border="1px solid" borderRadius="8px" borderColor="border.decorative" w="100%">
      <Typography pt="12px" pl="16px" variant="h3">
        {assessment.categoryName}
      </Typography>
      <Table
        columns={columns}
        data={assessment.materialStandards}
        onRowClick={(row) => {
          if (isOnboarding) {
            navigate(
              `/${companyId}/esrs/${esrsAssessmentId}/onboarding/materiality/standard/${row.standardRef}`
            );
          } else navigate(`materiality/standard/${row.standardRef}`);
          setStep?.(3);
        }}
        rowProps={{
          _hover: {
            cursor: 'pointer',
            bg: 'bg.hover',
          },
        }}
        cellPadding="0px 16px"
        headerPadding="12px 16px"
        bottomRowBorder={false}
        rowIcon={<ArrowNarrowRightIcon />}
      />
    </TableContainer>
  );
};

export const MaterialityAssessment = ({
  setStep,
  isOnboarding = false,
}: {
  setStep?: (step: number) => void;
  isOnboarding?: boolean;
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const { companyId } = useCurrentCompanyId();
  const addMaterialStandard = useAddMandatoryMaterialityAssessment();

  const { materialityAssessments, loading } = useGetMaterialityAssessment(esrsAssessmentId);
  const { isCollectOnly, subsidiaryTypeLoading } = useGetSubsidiaryType(esrsAssessmentId);

  const hasParentMaterialityAssessment = useMemo(() => {
    return materialityAssessments[0]?.hasParentMaterialityAssessment;
  }, [materialityAssessments]);

  useEffect(() => {
    if (!isCollectOnly) {
      const mandatoryStandards = getUnassessedMandatoryStandardData(materialityAssessments);
      addMaterialStandard(mandatoryStandards);
    }
  }, [materialityAssessments, isCollectOnly]);

  if (isCollectOnly)
    return (
      <CollectOnlyMaterialityAssessment
        materialityAssessments={materialityAssessments}
        hasParentMaterialityAssessment={hasParentMaterialityAssessment}
        companyId={companyId}
        loading={loading || subsidiaryTypeLoading}
        setStep={setStep}
        isOnboarding={isOnboarding}
      />
    );
  return (
    <ContentLayout
      variant="inline.nopad"
      isLoading={loading || subsidiaryTypeLoading}
      header={!setStep && isOnboarding && <ContentHeader title="Materiality assessment" />}
    >
      <VStack mt={isOnboarding ? '0px' : '28px'} w="100%" alignItems="stretch" spacing="24px">
        {!isOnboarding && <MaterialityAssessmentHeader title="Manage materiality assessment" />}
        {isOnboarding && (
          <VStack gap="2px" alignItems="start">
            <Typography variant="h2">Step 2: Choose material topics</Typography>
            <Typography
              variant="body"
              color="text.muted"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
            >
              Select which standards are material for the company
            </Typography>
          </VStack>
        )}
        {/* sort the general one to always appear first */}
        {materialityAssessments
          .sort(
            (a, b) => ORDER_OF_CATEGORIES?.[a.categoryName] - ORDER_OF_CATEGORIES?.[b.categoryName]
          )
          .map((mAssessment) => {
            return (
              <MaterialityAssessmentTable
                assessment={mAssessment}
                hasParentMaterialityAssessment={hasParentMaterialityAssessment}
                esrsAssessmentId={esrsAssessmentId}
                companyId={companyId}
                isOnboarding={isOnboarding}
                setStep={setStep}
              />
            );
          })}
      </VStack>
    </ContentLayout>
  );
};
