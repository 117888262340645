import { ReportItem } from 'Molecules/ReportsCard';
import { usePaiRequests } from 'containers/CompanyPai/CompanyPai.hooks';
import { uniq } from 'lodash';
import { CompanyAssessmentsQuery_, GetReportsQuery_ } from 'models';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringToYear } from 'utils/date';

export enum AssessmentTypes {
  taxonomy = 'EU Taxonomy',
  pai = 'PAI',
  esrs = 'ESRS',
}

export const getLatestTaxonomyDate = (
  assessment:
    | GetReportsQuery_['taxonomyReports'][number]
    | GetReportsQuery_['groupTaxonomyReports'][number]['consolidatedCompanyAssessment']
    | CompanyAssessmentsQuery_['assessments'][number]
) => {
  let max = assessment?.updatedAt;
  assessment?.bAssesssments.forEach((bu) => {
    if (bu.updatedAt > max) {
      max = bu.updatedAt;
    }
    bu.lastEditedFinancials.forEach((financial) => {
      if (financial.financials?.updatedAt > max) {
        max = financial.financials?.updatedAt;
      }
    });
    bu.activityReports.forEach((report) => {
      if (report.answers?.[0]?.updatedAt > max) {
        max = report.answers?.[0]?.updatedAt;
      }
    });
  });
  return max;
};

const getLatestEsrsDate = (assessment: GetReportsQuery_['esrsAssessments'][number]) => {
  let max = assessment.updatedAt;
  assessment.materialStandards.forEach((std) => {
    std.materialMetrics.forEach((metric) => {
      if (metric.updatedAt > max) {
        max = metric.updatedAt;
      }
    });
  });
  assessment.reportingUnits.forEach((ru) => {
    ru.answers.forEach((ans) => {
      if (ans.datapoints?.[0]?.updatedAt > max) {
        max = ans.datapoints?.[0]?.updatedAt;
      }
    });
  });
  return max;
};

const getLatestPaiDate = (assessment?: GetReportsQuery_['paiCompanyReports'][number]) => {
  let max = assessment?.updatedAt;
  if (assessment?.lastEditedAnswer?.[0]?.updatedAt > max) {
    max = assessment?.lastEditedAnswer?.[0]?.updatedAt;
  }
  return max ?? 0;
};

export const useHomeAssessments = (
  assessments?: GetReportsQuery_,
  companyId?: string,
  isGroup?: boolean
) => {
  const navigate = useNavigate();

  const { requests } = usePaiRequests(companyId ?? undefined);

  const taxonomyReports = useMemo(() => {
    const taxonomy: ReportItem[] =
      assessments?.taxonomyReports?.map((report) => {
        return {
          id: report.id,
          status:
            (report.cachedResult?.progress?.financials === 100 &&
              report.cachedResult?.progress?.screening === 100) ||
            report.isLocked
              ? 'done'
              : 'todo',
          title: report.aggregate.title,
          type: AssessmentTypes.taxonomy,
          lastEdited: getLatestTaxonomyDate(report) ?? 0,
          year: stringToYear(report.startDate),
          onClick: () => navigate(`assessments/${report.id}`),
        };
      }) ?? [];

    const groupTaxonomy: ReportItem[] =
      assessments?.groupTaxonomyReports?.map((report) => ({
        id: report.id,
        status: report.consolidatedCompanyAssessment?.isLocked ? 'done' : 'todo',
        title: report.name,
        type: AssessmentTypes.taxonomy,
        lastEdited: getLatestTaxonomyDate(report.consolidatedCompanyAssessment) ?? 0,
        year: stringToYear(report.consolidatedCompanyAssessment?.startDate),
        onClick: () => navigate(`assessments/${report.id}`),
      })) ?? [];

    return isGroup ? groupTaxonomy : taxonomy;
  }, [assessments, isGroup]);

  const paiReports = useMemo(() => {
    const pai: Array<ReportItem & { period?: string }> =
      (assessments &&
        requests?.map((r) => ({
          id: r.report?.id,
          title: String(r.year),
          period: r.period,
          year: r.year,
          type: AssessmentTypes.pai,
          lastEdited: getLatestPaiDate(r.report) ?? 0,
          onClick: () => navigate(`pai/${r.year}/year`),
          count: uniq(
            r.requests
              .map((request) => request.selectedIndicators)
              .flat()
              .filter(
                (s, _, array) =>
                  s.isNew === true &&
                  !array.some(
                    (a) => a.indicator.reference === s.indicator.reference && a.isNew === false
                  )
              )
              .map((selected) =>
                selected.indicator.investorMetrics
                  .map((metric) =>
                    metric.investorMetric.investeeMetrics.map(
                      (investee) => investee.metric.reference
                    )
                  )
                  .flat()
              )
              .flat()
          ).filter(
            (m) => r.report && r.report?.answers.every((a) => a.investeeMetricReference !== m)
          ).length,
        }))) ??
      [];

    return pai;
  }, [assessments, requests]);

  const esrsAssessments = useMemo(() => {
    const esrs: ReportItem[] =
      assessments?.esrsAssessments.map((assessment) => ({
        id: assessment.id,
        status: 'todo',
        title: String(assessment.reportingYear),
        type: AssessmentTypes.esrs,
        year: assessment.reportingYear,
        lastEdited: getLatestEsrsDate(assessment) ?? 0,
        onClick: () => navigate(`esrs/${assessment.id}`),
      })) ?? [];
    return esrs;
  }, [assessments]);

  const allReports = useMemo(
    () =>
      [...taxonomyReports, ...paiReports, ...esrsAssessments].sort((a, b) => {
        const dateA = new Date(a.lastEdited ?? 0);
        const dateB = new Date(b.lastEdited ?? 0);
        return dateB.getTime() - dateA.getTime();
      }),
    [taxonomyReports, paiReports]
  );

  return allReports;
};
