import { Tabs } from 'Atoms';
import { ContentHeader, ContentLayout } from 'Molecules';
import { useLocalStorage } from 'usehooks-ts';
import { MaterialityAssessment } from '.';
import { DataCollectionSetup } from './pieces/MaterialityAssessment/DataCollectionSetup';

export const ESRSAssessmentSettings = () => {
  const [selectedSettingsTab, setSelectedSettingsTab] = useLocalStorage(
    'selectedSettingsTab',
    'materiality'
  );

  const onChangeTab = (tabId: string): void => {
    setSelectedSettingsTab(tabId);
  };

  return (
    <ContentLayout
      backButton
      onBackButtonClick={() => {
        localStorage.removeItem('selectedSettingsTab');
      }}
      header={<ContentHeader title="Configure assessment" />}
    >
      <Tabs
        defaultIndex={0}
        onChange={(tabId) => {
          if (typeof tabId == 'string') {
            onChangeTab(tabId);
          }
        }}
        currentTab={selectedSettingsTab}
        items={[
          {
            title: 'Materiality assessment',
            id: 'materiality',
            content: <MaterialityAssessment isOnboarding={false} />,
          },
          {
            title: 'Data collection setup',
            id: 'data-collection',
            content: <DataCollectionSetup isOnboarding={false} onChangeTab={onChangeTab} />,
          },
        ]}
      />
    </ContentLayout>
  );
};
