import {
  useGetReportingUnitsQuery,
  useGetParentMaterialityQuery,
  useGetSubsidiaryTypeQuery,
} from 'models';
import { useMemo } from 'react';
export const COMPANY_LEVEL = 'Company level';

export type SelectedMetric = {
  title?: string;
  description?: string;
  reference?: string;
  shortTitle?: string;
  tags?: { type: string }[];
  isDisclosure?: boolean;
};

export const useAssessmentReportingUnits = (assessmentId?: string) => {
  const { data, loading } = useGetReportingUnitsQuery({
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });

  const reportingUnitAssessments = useMemo(() => data?.reportingUnits ?? [], [data]);

  return { reportingUnitAssessments, loading };
};

export const useMaterialStandardId = (standardRef: string, esrsAssessmentId: string) => {
  const { data, loading } = useGetParentMaterialityQuery({
    variables: { standardRef: standardRef ?? '', assessmentId: esrsAssessmentId },
    skip: !standardRef || !esrsAssessmentId,
  });
  const companyAssessmentId = useMemo(
    () => data?.materialityAssessment?.[0]?.id ?? undefined,
    [data]
  );
  const parentAssessmentId = useMemo(
    () =>
      data?.materialityAssessment?.[0]?.esrsAssessment.parentData?.materialStandards?.[0]?.id ??
      undefined,
    [data, standardRef]
  );
  const isDataGatheringOnly = useMemo(
    () => data?.materialityAssessment?.[0]?.isDataGatheringOnly,
    [data]
  );

  const assessmentYear = useMemo(
    () => data?.materialityAssessment?.[0]?.esrsAssessment?.reportingYear,
    [data]
  );

  const hasParentAssessment = useMemo(
    () => !!data?.materialityAssessment?.[0]?.esrsAssessment.parentData?.id,
    [data]
  );

  return {
    companyAssessmentId,
    parentAssessmentId,
    isDataGatheringOnly,
    assessmentYear,
    hasParentAssessment,
    loading,
  };
};

export const useGetSubsidiaryType = (esrsAssessmentId: string) => {
  const { data: subsidiaryTypeData, loading: subsidiaryTypeLoading } = useGetSubsidiaryTypeQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const isCollectOnly = useMemo(
    () => subsidiaryTypeData?.esrsAssessment?.collectOnly ?? false,
    [subsidiaryTypeData]
  );
  return { isCollectOnly, subsidiaryTypeLoading };
};
