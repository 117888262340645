import { IconButton, TooltipProps } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';

export function HelpTooltip({
  label,
  placement = 'top',
  iconColor,
  ...props
}: Omit<TooltipProps, 'children'> & { iconColor?: string }) {
  return (
    <Tooltip
      label={label}
      hasArrow
      arrowSize={10}
      arrowShadowColor="grayscale.800"
      placement={placement}
      color="bg.default"
      // shouldWrapChildren
      {...props}
    >
      <IconButton
        aria-label="help"
        icon={<HelpIcon color={iconColor ?? 'inherit'} />}
        size="xs"
        variant="ghost"
      />
    </Tooltip>
  );
}
