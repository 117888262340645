import { CircularProgress, HStack, VStack } from '@chakra-ui/react';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import {
  useGroupStandardProgress,
  useRegularCompanyStandardProgress,
  useReportingUnitStandardProgress,
} from 'containers/Esrs/pieces/Assessment/StandardProgress/StandardProgress.hooks';
import { ShortUser, User } from 'models';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const InfoCard = ({ title, content }: { title: string; content: ReactNode }) => {
  return (
    <VStack alignItems="start" bg="bg.muted" flex={1} p="20px" borderRadius="8px" gap="10px">
      <Typography variant="body">{title}</Typography>
      {content}
    </VStack>
  );
};

const RegularCompanyStandardProgress = () => {
  const { esrsAssessmentId, standardRef } = useParams();

  const { progress } = useRegularCompanyStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
  });

  return (
    <HStack spacing="8px">
      <CircularProgress
        size="28px"
        thickness="16px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="h3">{`${progress}%`}</Typography>
    </HStack>
  );
};

const ReportingUnitStandardProgress = () => {
  const { esrsAssessmentId, standardRef, reportingUnitId } = useParams();

  const { progress } = useReportingUnitStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
    reportingUnitId: reportingUnitId ?? '',
  });

  return (
    <HStack spacing="8px">
      <CircularProgress
        size="28px"
        thickness="16px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="h3">{`${progress}%`}</Typography>
    </HStack>
  );
};

const GroupStandardProgress = () => {
  const { esrsAssessmentId, standardRef } = useParams();

  const { progress } = useGroupStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
  });

  return (
    <HStack spacing="8px">
      <CircularProgress
        size="28px"
        thickness="16px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="h3">{`${progress}%`}</Typography>
    </HStack>
  );
};

export const DisclosureRequirementHeader = ({
  isGroup,
  owner,
}: {
  isGroup: boolean;
  owner?: Partial<User> | null;
}) => {
  const url = window.location.href;
  const isBusinessUnit = useMemo(() => url.includes('bu-standard'), [url]);

  return (
    <VStack alignItems="stretch">
      <Typography variant="h2">Disclosure Requirements</Typography>
      <HStack w="100%" gap="8px">
        <InfoCard
          title="Data collection progress"
          content={
            isGroup ? (
              <GroupStandardProgress />
            ) : isBusinessUnit ? (
              <ReportingUnitStandardProgress />
            ) : (
              <RegularCompanyStandardProgress />
            )
          }
        />
        <InfoCard
          title="Main responsible"
          content={
            <HStack spacing="8px">
              <UserAvatar user={owner as ShortUser} size="sm" boxSize="28px" border="none" />
              <Typography variant="h3">{owner?.displayName}</Typography>
            </HStack>
          }
        />
      </HStack>
    </VStack>
  );
};
