import { Box, Grid, VStack } from '@chakra-ui/react';
import { EmptyState } from 'Atoms';
import { ContentLayout, ContentHeader, Loader, Select } from 'Molecules';
import { ActivitiesIllustration, Typography } from 'Tokens';
import { CompanyBothIcon, CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StandardProgress } from '../StandardProgress/StandardProgress';
import { useGetSubsidiaryStandardsOverview } from './AssessmentOverview.hooks';
import { GetFlaggedTargetsQuery_ } from 'models';
import { FlaggedTargets } from './FlaggedTargets';

export enum MaterialTopicFilter {
  ALL = 'all',
  MATERIAL = 'material',
  NOT_MATERIAL = 'not-material',
}

export const MATERIAL_TOPIC_FILTER_OPTIONS = [
  {
    label: 'All available data',
    description: 'Topics that were assessed as material by a parent company and by subsidiaries',
    icon: <CompanyBothIcon color="text.default" />,
    value: MaterialTopicFilter.ALL,
  },
  {
    label: 'Only material topics',
    description: 'Topics that were assessed as material by a parent company (this company)',
    icon: <CompanyTopDownIcon color="text.default" />,
    value: MaterialTopicFilter.MATERIAL,
  },
  {
    label: 'Only not material topics',
    description: 'Topics that were assessed as material by subsidiaries',
    icon: <CompanyBottomUpIcon color="text.default" />,
    value: MaterialTopicFilter.NOT_MATERIAL,
  },
];

export const EsrsAssessmentOverview = ({
  esrsAssessmentId,
  companyName,
  targets,
}: {
  esrsAssessmentId: string;
  companyName: string;
  targets?: GetFlaggedTargetsQuery_['targets'];
}) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState('all');

  const { categories, loading } = useGetSubsidiaryStandardsOverview(filter, esrsAssessmentId);

  const noStandardsToShow = useMemo(
    () => categories.filter((c) => c.materialStandards.length).length === 0,
    [categories]
  );

  const hasNonMaterialStandards = useMemo(
    () =>
      categories.some((c) =>
        c.materialStandards.some((std) => std.isMaterialForGroup && !std.isMaterialForSubsidiary)
      ),
    [categories]
  );

  const filteredCategories = useMemo(() => {
    return categories.map((cat) => ({
      ...cat,
      materialStandards: cat.materialStandards.filter((std) => {
        if (filter === 'all') return true;
        if (filter === 'not-material') {
          return std.isMaterialForGroup && !std.isMaterialForSubsidiary;
        } else if (filter === 'material') {
          return std.isMaterialForSubsidiary;
        }
      }),
    }));
  }, [categories, filter]);

  const flaggedStandards = useMemo(
    () => targets?.map((target) => target.materialStandard.standardRef) ?? [],
    [targets]
  );

  if (loading) return <Loader />;

  return (
    <>
      {noStandardsToShow ? (
        <Box w="100%" h="100%" p="16px">
          <EmptyState
            title="Start with materiality assessment"
            description="Add material standards, disclosure requirements and metrics to get started"
            icon={<ActivitiesIllustration boxSize="120px" />}
            callToAction={{
              text: 'Begin assessment',
              variant: 'primary',
              onClick: () => navigate('settings'),
            }}
          />
        </Box>
      ) : (
        <ContentLayout
          isLoading={loading}
          variant="inline"
          paddingTop="32px"
          paddingBottom="56px"
          header={<ContentHeader title={`${companyName} overview`} size="md" />}
        >
          {!!targets?.length && <FlaggedTargets targets={targets} />}
          <VStack alignItems="start" spacing="40px" mt="24px">
            {hasNonMaterialStandards && (
              <Box width="280px">
                <Select<{ value: string; label: string }>
                  value={{
                    label:
                      MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
                    value: filter,
                  }}
                  onChange={(value) => setFilter(value?.value ? value.value : 'all')}
                  options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
                    return {
                      label: currOption?.label ?? '',
                      value: currOption.value,
                    };
                  })}
                  size="md"
                />
              </Box>
            )}
            {filteredCategories.map((category, categoryIndex) => (
              <Fragment key={category.categoryRef}>
                {category.materialStandards.length > 0 && (
                  <VStack key={category.categoryRef} alignItems="start" width="100%">
                    <Typography variant="overline">{category.categoryName}</Typography>
                    <Grid templateColumns="repeat(3, 1fr)" overflow="visible" gap="12px" w="full">
                      {category.materialStandards.map((standard, standardIndex, standardsArr) => (
                        <StandardProgress
                          standard={standard}
                          standardIndex={standardIndex}
                          categoryIndex={categoryIndex}
                          standardLength={standardsArr.length}
                          key={standard.standardRef}
                          isFlagged={flaggedStandards?.includes(standard.standardRef)}
                        />
                      ))}
                    </Grid>
                  </VStack>
                )}
              </Fragment>
            ))}
          </VStack>
        </ContentLayout>
      )}
    </>
  );
};
