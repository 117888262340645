import { VStack, Image } from '@chakra-ui/react';
import { Button, FormField, Input, ExternalLink, EmptyState, ButtonLink } from 'Atoms';
import { NoAccessIllustration, Typography } from 'Tokens';
import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { Trans, useTranslation } from 'utils/translation';
import { useSignOut } from '@nhost/react';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { LoginPage } from './Login';
import { CELSIA_CONTACT_EMAIL } from 'utils/users';
import { SignOutIcon } from 'Tokens/Icons/Function';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { useSearchParams } from 'react-router-dom';

type LoginInput = {
  organization: string;
};

const validRedirectDomains = [
  '^http://localhost:3000/',
  '^http://localhost:3001/',
  '^https://app\\.celsia\\.io/',
  '^https://sustainability\\.app\\.novata\\.com/',
  '^https://novata\\.dev/',
  '^https://.*\\.demo\\.novata\\.com/',
];
const validateRedirectUrl = (url: string) => {
  console.log('Verifying redirect url:', url);
  console.log(
    'Result',
    validRedirectDomains.find((domain) => url.match(domain))
  );
  return validRedirectDomains.find((domain) => url.match(domain));
};

export const SSOLoginForm = ({
  onSuccess,
  organization,
}: {
  onSuccess: () => any;
  organization?: string;
}) => {
  const toast = useToast();
  const { t } = useTranslation('login');
  const { signOut } = useSignOut();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [searchParams] = useSearchParams();

  const { register, handleSubmit, control, setValue } = useForm<LoginInput>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      organization: organization || '',
    },
  });
  const { isValid, isDirty, errors, isSubmitting } = useFormState({ control });

  const handleValidateOrganization = async (orgName: string) => {
    const { res, error } = await nhost.functions.call<
      { result: { orgId: string; name: string } },
      { orgName: string }
    >('validate-organization', {
      orgName: orgName.trim().toLocaleLowerCase(),
    });

    if (res?.data?.result?.orgId) {
      return res?.data?.result?.orgId;
    }

    if (error) {
      console.log('error', error);
      setErrorMessage(error.message);
    }

    setErrorMessage(t('login:inputs.sso.notFoundError'));
  };

  const onSSOLogin = async (values: { organization: string; redirectUrl?: string }) => {
    setErrorMessage(undefined);
    const orgId = await handleValidateOrganization(values.organization);
    if (!orgId) return;

    const options: { organization: string; redirectTo?: string } = {
      organization: orgId,
    };

    if (values.redirectUrl) {
      options.redirectTo = values.redirectUrl;
    }

    await signOut().finally(() => {
      nhost.auth
        .signIn({
          provider: 'workos',
          options,
        })
        .then((res) => {
          const { error } = res;
          if (error) {
            setErrorMessage(error.message);
            toast({
              text: error.message,
              variant: 'danger',
            });
          } else {
            onSuccess();
          }
        })
        .catch((err) => {
          toast({
            text: err.message,
            variant: 'danger',
          });
        });
    });
  };

  useEffect(() => {
    const queryOrgName = searchParams.get('orgName');
    const queryRedirectUrl = searchParams.get('redirectUrl');
    let redirectUrl;

    if (queryRedirectUrl && validateRedirectUrl(decodeURI(queryRedirectUrl))) {
      // validation is also handled by the allowed domains in nhost.toml this is just an extra check
      redirectUrl = queryRedirectUrl;
    }

    if (queryOrgName) {
      setValue('organization', queryOrgName);
      onSSOLogin({ organization: queryOrgName, redirectUrl });
    }
  }, [searchParams]);

  return (
    <form onSubmit={handleSubmit(onSSOLogin)}>
      <VStack spacing="16px">
        <FormField
          id="organization"
          label={t('login:label.organization')}
          isInvalid={!!errors.organization}
          error={errors.organization?.message}
        >
          <Input
            {...register('organization', {
              required: t('login:inputs.sso.required'),
              // This should check in the backend if it exists
              validate: (value) =>
                value.trim().includes(' ') ? t('login:inputs.sso.whiteSpaceValidation') : true,
            })}
            placeholder={t('login:inputs.sso.placeholder')}
            size="md"
            width="100%"
          />
        </FormField>
        {errorMessage && (
          <Typography variant="detail" color="text.critical">
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="primary"
          width="full"
          type="submit"
          isLoading={isSubmitting}
          isDisabled={!isValid || !isDirty}
        >
          {t('login:logIn')}
        </Button>
      </VStack>
    </form>
  );
};

export function SSOLogin() {
  const { t } = useTranslation('login');
  const toast = useToast();

  return (
    <LoginPage heading={t('login:loginHeader')}>
      <SSOLoginForm
        onSuccess={() => toast({ text: t('login:inputs.sso.redirect'), variant: 'default' })}
      />
      <ButtonLink to="/login" variant="ghost" w="100%" leftIcon={<ArrowLeftIcon />}>
        {t('login:inputs.sso.login')}
      </ButtonLink>
    </LoginPage>
  );
}

const contactEmail = CELSIA_CONTACT_EMAIL;

export const NoCompanyWelcomeModal = () => {
  const { t } = useTranslation(['login', 'common']);
  const { signOut } = useSignOut();

  return (
    <VStack w="100%" h="100vh" p="16px" spacing="16px">
      <Image src="/assets/celsia_logo.svg" h="32px" w="100px" />
      <EmptyState
        title={t('login:noAccess.title')}
        description={
          <Trans i18nKey={'login:noAccess.message'} values={{ contactEmail }}>
            <Typography />
            <Typography>
              <ExternalLink href={`mailto:${contactEmail}`} />
            </Typography>
            <Typography />
          </Trans>
        }
        callToAction={{
          text: t('common:actions.logOut'),
          variant: 'secondary',
          onClick: () => {
            signOut();
          },
          leftIcon: <SignOutIcon color="inherit" />,
        }}
        icon={<NoAccessIllustration boxSize="120px" />}
      />
    </VStack>
  );
};
