import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  Table as ChakraTable,
  Td,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Button, DateInput, EmptyState, Tag } from 'Atoms';
import {
  IndicatorConfiguration,
  PaiIndicatorsModal,
} from 'Features/PortfolioPai/PaiIndicatorsModal';
import { useTranslation } from 'utils/translation';
import {
  PortfolioCompany,
  useGetPaiPortfolioReportQuery,
  useGetTaxonomyPortfolioReportQuery,
} from 'models';
import { BackButton, ContentHeader, ContentLayout } from 'Molecules';
import { UserAssigner } from 'Organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { EUTaxonomyIllustration, PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useCurrentCompany, useCurrentCompanyId, useToast } from 'utils/hooks';
import usePortfolios, {
  DEFAULT_PORTFOLIO_DATES,
  getDefaultDeadline,
  portfolioOwnerContext,
  useUpsertPortfolioReport,
} from './Portfolios.hooks';
import { EligibilityStatus } from 'Features/PortfolioView';
import {
  formatDisplayQuarterYear,
  formatQuarterYear,
  formatQuarterYearToDate,
  getQuarterFromDate,
} from 'utils/date';
import QuarterPicker from './pieces/QuarterDateInput';
import AdvancedDateSwitch from './pieces/AdvancedDateSwitch';

export type ReportSettingsForm = {
  reportingPeriod: Date;
  reportingPeriodEndDate: Date;
  euContactPersonId: string;
  paiContactPersonId: string;
  euYearlyDateToSendOut: Date;
  euYearlyDeadlineDate: Date;
  paiQ1DateToSendOut: Date;
  paiQ1DeadlineDate: Date;
  paiQ2DateToSendOut: Date;
  paiQ2DeadlineDate: Date;
  paiQ3DateToSendOut: Date;
  paiQ3DeadlineDate: Date;
  paiQ4DateToSendOut: Date;
  paiQ4DeadlineDate: Date;
  paiYearlyDateToSendOut: Date;
  paiYearlyDeadlineDate: Date;
  indicators: IndicatorConfiguration[];
};

const thisOrNewDate = (date: Date | undefined) => {
  return date ? new Date(date) : new Date();
};

const PortfolioSettings = ({ isEditing = false }: { isEditing: boolean }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation(['pai', 'portfolio']);

  const { portfolioId, reportingYear = '' } = useParams();
  const [quarter, year] = useMemo(() => {
    return reportingYear.split('-');
  }, [reportingYear]);

  const [customPeriodsEnabled, setCustomPeriodEnabled] = useState(
    !!reportingYear && quarter !== 'Q1'
  );

  const { getPortfolio } = usePortfolios();

  const isNewPortfolioSetup = useMemo(() => reportingYear === 'new-portfolio', [reportingYear]);

  const reportYearNumber = useMemo(() => {
    if (isNewPortfolioSetup) {
      return new Date().getFullYear();
    }
    return Number(year);
  }, [reportingYear]);

  const portfolio = getPortfolio(portfolioId ?? '', isNewPortfolioSetup ? null : reportingYear);
  const { company } = useCurrentCompany();

  const { data: reportPai, loading: isLoadingPai } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year: reportYearNumber,
    },
    skip: !portfolioId || isNewPortfolioSetup,
    context: portfolioOwnerContext,
  });
  const paiReport = useMemo(() => {
    return reportPai?.report?.[0];
  }, [reportPai]);

  const { data: reportTaxonomyData } = useGetTaxonomyPortfolioReportQuery({
    variables: {
      portfolioId,
      year: reportYearNumber,
    },
    skip: isNewPortfolioSetup,
    context: portfolioOwnerContext,
  });
  const taxonomyReport = useMemo(() => {
    return reportTaxonomyData?.TaxonomyPortfolioReport?.[0];
  }, [reportTaxonomyData]);

  const {
    resetField,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
  } = useForm<ReportSettingsForm>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { companyId } = useCurrentCompanyId();

  useEffect(() => {
    resetField('reportingPeriod', {
      defaultValue: formatQuarterYearToDate(quarter, reportYearNumber),
    });
  }, [reportingYear]);

  const selectedYear = watch('reportingPeriod')?.getFullYear();
  const indicators = watch('indicators');

  const isQuarterlySelected = useMemo(
    () => Array.isArray(indicators) && indicators.some((i) => i.periods.q1 === true),
    [indicators]
  );

  useEffect(() => {
    if (taxonomyReport) {
      resetField('euContactPersonId', { defaultValue: taxonomyReport?.contactPersonId });
      resetField('euYearlyDeadlineDate', { defaultValue: taxonomyReport?.dueDate });
      resetField('euYearlyDateToSendOut', { defaultValue: taxonomyReport?.sendOutDate });
    }
  }, [taxonomyReport]);

  useEffect(() => {
    resetField('paiContactPersonId', {
      defaultValue: paiReport?.contactPerson?.id ?? '',
    });
    resetField('indicators', {
      defaultValue:
        paiReport?.selectedIndicators.map((x) => ({
          ...x,
          selectedPortfolioCompanyIds:
            x.paiReportIndicatorPortfolioCompanies.map((c) => c?.portfolioCompanyId) ?? [],
          periods: x.periods,
        })) ?? [],
    });
  }, [paiReport, selectedYear, isEditing, reportYearNumber]);

  const upsertReport = useUpsertPortfolioReport(reportYearNumber);

  const onSubmit = (data: ReportSettingsForm) => {
    const hasYears = portfolio?.allCompaniesInPortfolio?.some((c) => !!c?.year);
    const selectedQuarter = getQuarterFromDate(
      data.reportingPeriod ?? formatQuarterYearToDate(quarter, reportYearNumber)
    );

    const filteredCompanies =
      portfolio?.allCompaniesInPortfolio
        ?.filter((c: PortfolioCompany) => {
          if (!hasYears) {
            return true;
          }
          if (isEditing) {
            return formatQuarterYear(c?.quarter, c?.year) === reportingYear;
          }
          return c?.year === data.reportingPeriod?.getFullYear() - 1;
        })
        .filter((c: PortfolioCompany) => !!c.company?.id)
        .map((comp: PortfolioCompany) => ({
          id: comp?.year === null ? comp.id : undefined,
          portfolioId: portfolioId ?? '',
          companyId: comp.company?.id ?? '',
          year: data.reportingPeriod?.getFullYear() ?? reportYearNumber,
          quarter: selectedQuarter,
          valueOfInvestments: {
            q1: comp?.valueOfInvestments?.q4 ?? 1,
            q2: comp?.valueOfInvestments?.q4 ?? 1,
            q3: comp?.valueOfInvestments?.q4 ?? 1,
            q4: comp?.valueOfInvestments?.q4 ?? 1,
          },
          marketValue: comp?.marketValue ?? 1,
          contactPersonName: comp?.contactPersonName ?? '',
          contactPersonEmail: comp?.contactPersonEmail ?? '',
          eligibilityStatus: comp?.eligibilityStatus ?? EligibilityStatus.eligible,
        })) ?? [];

    upsertReport(
      filteredCompanies,
      {
        portfolioId: portfolioId ?? '',
        contactPersonId: data?.euContactPersonId ?? undefined,
        sendOutDate: data.euYearlyDateToSendOut,
        dueDate: data.euYearlyDeadlineDate,
        year: data.reportingPeriod?.getFullYear() ?? reportYearNumber,
        quarter: selectedQuarter,
      },
      {
        portfolioId: portfolioId ?? '',
        contactPersonId: data?.paiContactPersonId ?? undefined,
        indicators: data.indicators,
        reportingPeriod: thisOrNewDate(data.reportingPeriod),
        sendOutDates: {
          Q1: data.paiQ1DateToSendOut,
          Q2: data.paiQ2DateToSendOut,
          Q3: data.paiQ3DateToSendOut,
          Q4: data.paiQ4DateToSendOut,
          year: data.paiYearlyDateToSendOut,
        },
        dueDates: {
          Q1: data.paiQ1DeadlineDate,
          Q2: data.paiQ2DeadlineDate,
          Q3: data.paiQ3DeadlineDate,
          Q4: data.paiQ4DeadlineDate,
          year: data.paiYearlyDeadlineDate,
        },
        year: data.reportingPeriod?.getFullYear() ?? reportYearNumber,
      }
    ).then(() => {
      toast({
        text: t('portfolio:settings.savedSettings'),
      });
      navigate(
        `/${companyId}/portfolios/${portfolioId}/${selectedQuarter ?? 'Q1'}-${data.reportingPeriod?.getFullYear() ?? reportYearNumber}/companies`
      );
    });
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <ContentLayout
      variant="narrow"
      backButton={
        <BackButton
          onClick={() => navigate(`portfolios/${portfolio?.id}/${reportYearNumber ?? ''}`)}
        />
      }
      header={<ContentHeader title={t('portfolio:settings.settingsTitle')} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Center width="100%">
          <VStack alignItems="flex-start" spacing="56px" pt="16px">
            <VStack alignItems="flex-start" width="100%" spacing="24px">
              <VStack alignItems="flex-start" spacing="8px" width="100%">
                <Typography variant="h2">{t('portfolio:settings.generalSettings')}</Typography>
                <Divider border="1px" borderColor="border.decorative" />
              </VStack>
              {/*  */}
              <VStack alignItems="flex-start" gap="12px" width="100%">
                <HStack justifyContent="space-between" width="100%">
                  <VStack spacing="0px" alignItems="start">
                    <Typography variant="h3">{t('portfolio:settings.reportingPeriod')}</Typography>
                    <Typography variant="body" color="text.muted">
                      {t('portfolio:settings.selectPeriod')}
                    </Typography>
                  </VStack>
                  {isEditing ? (
                    <Tag>{formatDisplayQuarterYear(reportingYear)}</Tag>
                  ) : !customPeriodsEnabled ? (
                    <Controller
                      name="reportingPeriod"
                      control={control}
                      defaultValue={new Date(new Date().getFullYear(), 0, 1)}
                      render={({ field }) => (
                        <VStack gap="4px" alignItems="start">
                          <Typography variant="body">
                            {t('portfolio:settings.selectYear')}
                          </Typography>
                          <DateInput
                            isDisabled={isEditing}
                            isReadOnly={isEditing}
                            width="220px"
                            value={field.value}
                            setValue={(date) => date && field.onChange(date)}
                            showYearPicker
                            dateFormat="yyyy"
                          />
                        </VStack>
                      )}
                    />
                  ) : (
                    <QuarterPicker
                      startDateFieldName="reportingPeriod"
                      endDateFieldName="reportingPeriodEndDate"
                      control={control}
                      resetField={resetField}
                      watch={watch}
                    />
                  )}
                </HStack>
                {!isEditing && (
                  <AdvancedDateSwitch
                    isChecked={customPeriodsEnabled}
                    onChange={() => setCustomPeriodEnabled(!customPeriodsEnabled)}
                  />
                )}
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" width="100%" spacing="24px">
              <VStack alignItems="flex-start" spacing="8px" width="100%">
                <Typography variant="h2">{t('portfolio:settings.taxonomyAssessment')}</Typography>
                <Divider border="1px" borderColor="border.decorative" />
              </VStack>
              {company?.hasTaxonomyAccess ? (
                <>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">{t('portfolio:settings.responsible')}</Typography>
                      <Typography variant="body" color="text.muted">
                        {t('portfolio:settings.reportOwner')}
                      </Typography>
                    </VStack>
                    <Stack width="220px">
                      <Controller
                        name="euContactPersonId"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Box zIndex="2">
                            <UserAssigner
                              assignedTo={field.value}
                              setAssignedTo={(id) => field.onChange(id)}
                              placeholder={t('portfolio:settings.selectUser')}
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                  </HStack>
                  <VStack alignItems="start">
                    <Typography variant="h3">{t('portfolio:settings.setupTimeline')}</Typography>
                    <Stack>
                      <Typography variant="body" color="text.muted" mb="4px">
                        {t('portfolio:settings.selectCompanies')}
                      </Typography>
                    </Stack>
                    <Box
                      width="100%"
                      border="1px solid"
                      borderColor="border.decorative"
                      borderRadius="8px"
                    >
                      <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
                        <Thead borderBottom="1px solid" borderColor="border.decorative">
                          <Td alignSelf="flex-start" p="13px 8px">
                            <Typography variant="bodyStrong">
                              {t('portfolio:settings.period')}
                            </Typography>
                          </Td>
                          <Td p="13px 8px">
                            <Typography variant="bodyStrong">
                              {t('portfolio:settings.sendOutDate')}
                            </Typography>
                          </Td>
                          <Td p="13px 8px">
                            <Typography variant="bodyStrong">
                              {t('portfolio:settings.deadline')}
                            </Typography>
                          </Td>
                        </Thead>
                        <Tr>
                          <Td p="13px 8px">
                            <Typography variant="body">{t('portfolio:settings.year')}</Typography>
                          </Td>
                          <Td p="5px 8px">
                            <Controller
                              name="euYearlyDateToSendOut"
                              control={control}
                              defaultValue={thisOrNewDate(taxonomyReport?.sendOutDate)}
                              render={({ field }) => (
                                <Box zIndex="1">
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => field.onChange(date?.toDateString())}
                                  />
                                </Box>
                              )}
                            />
                          </Td>
                          <Td p="5px 8px">
                            <Controller
                              name="euYearlyDeadlineDate"
                              control={control}
                              defaultValue={
                                taxonomyReport?.dueDate
                                  ? thisOrNewDate(taxonomyReport?.dueDate)
                                  : getDefaultDeadline(
                                      Number(selectedYear ?? reportYearNumber),
                                      'taxonomy'
                                    )
                              }
                              render={({ field }) => (
                                <DateInput
                                  width="100%"
                                  value={field.value}
                                  setValue={(date) => field.onChange(date?.toDateString())}
                                />
                              )}
                            />
                          </Td>
                        </Tr>
                      </ChakraTable>
                    </Box>
                  </VStack>
                </>
              ) : (
                <Box w="100%" h="100%">
                  <EmptyState
                    title={t('portfolio:settings.taxonomyAccessTitle')}
                    description={
                      <Typography pb="96px">{t('portfolio:settings.taxonomyAccess')}</Typography>
                    }
                    icon={<EUTaxonomyIllustration boxSize="80px" />}
                    component={true}
                  />
                </Box>
              )}
            </VStack>

            <VStack align="flex-start" width="100%" spacing="24px">
              <VStack alignItems="flex-start" spacing="8px" width="100%">
                <Typography variant="h2">{t('portfolio:settings.pai')}</Typography>
                <Divider border="1px" borderColor="border.decorative" />
              </VStack>
              {company?.hasPaiAccess ? (
                <>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">{t('portfolio:settings.responsible')}</Typography>
                      <Typography variant="body" color="text.muted">
                        {t('portfolio:settings.reportOwner')}
                      </Typography>
                    </VStack>
                    <Stack width="220px">
                      <Controller
                        name="paiContactPersonId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <UserAssigner
                            assignedTo={field.value}
                            setAssignedTo={(id) => field.onChange(id)}
                            placeholder={t('portfolio:settings.selectUser')}
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">
                        {t('portfolio:settings.selectIndicators')}
                      </Typography>
                      <Typography variant="body" color="text.muted">
                        {t('portfolio:settings.reportedIndicators')}
                      </Typography>
                    </VStack>
                    <Button size="md" variant="secondary" onClick={onOpen}>
                      {t('portfolio:settings.selectIndicators')}
                    </Button>
                    <Controller
                      name="indicators"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <PaiIndicatorsModal
                          isOpen={isOpen}
                          onClose={onClose}
                          selected={field.value}
                          setSelected={field.onChange}
                          reportingYear={reportingYear}
                        />
                      )}
                    />
                  </HStack>
                  <VStack alignItems="start">
                    {indicators && indicators.length > 0 && (
                      <HStack
                        padding="8px 4px 8px 8px"
                        border="1px"
                        borderColor="bg.unknown"
                        bg="bg.unknown.muted"
                        width="100%"
                        borderRadius="6px"
                        mb="14px"
                        mt="-12px"
                      >
                        <Typography variant="body" color="text.muted">
                          {indicators.length === 1
                            ? 'Selected 1 indicator'
                            : `Selected ${indicators.length} indicators`}
                        </Typography>
                      </HStack>
                    )}
                    <Typography variant="h3">{t('portfolio:settings.setupTimeline')}</Typography>
                    <Stack>
                      <Typography variant="body" color="text.muted" mb="4px">
                        {t('portfolio:settings.companiesSelected')}
                      </Typography>
                    </Stack>
                    <Box
                      width="100%"
                      border="1px solid"
                      borderColor="border.decorative"
                      borderRadius="8px"
                    >
                      {!isLoadingPai && (
                        <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
                          <Thead borderBottom="1px solid" borderColor="border.decorative">
                            <Td alignSelf="flex-start" p="13px 8px">
                              <Typography variant="bodyStrong">
                                {t('portfolio:settings.period')}
                              </Typography>
                            </Td>
                            <Td p="13px 8px">
                              <Typography variant="bodyStrong">
                                {t('portfolio:settings.sendOutDate')}
                              </Typography>
                            </Td>
                            <Td p="13px 8px">
                              <Typography variant="bodyStrong">
                                {t('portfolio:settings.deadline')}
                              </Typography>
                            </Td>
                          </Thead>
                          {isQuarterlySelected && (
                            <>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">
                                    {t('portfolio:quarters.Q1')}
                                  </Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ1DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q1)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ1DeadlineDate"
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q1)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.dueDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">
                                    {t('portfolio:quarters.Q2')}
                                  </Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ2DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q2)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ2DeadlineDate"
                                    control={control}
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q2)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.dueDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">
                                    {t('portfolio:quarters.Q3')}
                                  </Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ3DateToSendOut"
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q3)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.sendOutDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ3DeadlineDate"
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q3)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.dueDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">
                                    {t('portfolio:quarters.Q4')}
                                  </Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ4DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q4)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ4DeadlineDate"
                                    control={control}
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q4)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber) + 1,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.dueDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                            </>
                          )}
                          <Tr>
                            <Td p="13px 8px">
                              <Typography variant="body">{t('portfolio:settings.year')}</Typography>
                            </Td>
                            <Td p="5px 8px">
                              <Controller
                                name="paiYearlyDateToSendOut"
                                control={control}
                                defaultValue={thisOrNewDate(paiReport?.sendOutDates?.year)}
                                render={({ field }) => (
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => date && field.onChange(date)}
                                  />
                                )}
                              />
                            </Td>
                            <Td p="5px 8px">
                              <Controller
                                name="paiYearlyDeadlineDate"
                                control={control}
                                defaultValue={
                                  paiReport?.dueDates
                                    ? thisOrNewDate(paiReport?.dueDates?.year)
                                    : getDefaultDeadline(
                                        Number(selectedYear ?? reportYearNumber),
                                        'pai'
                                      )
                                }
                                render={({ field }) => (
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => date && field.onChange(date)}
                                  />
                                )}
                              />
                            </Td>
                          </Tr>
                        </ChakraTable>
                      )}
                    </Box>
                  </VStack>
                </>
              ) : (
                <Box w="100%" h="100%">
                  <EmptyState
                    title={t('pai:portfolioNoAccess.title')}
                    description={
                      <Typography pb="96px">{t('pai:portfolioNoAccess.description')}</Typography>
                    }
                    icon={<PaiIndicatorsIllustration boxSize="80px" />}
                    component={true}
                  />
                </Box>
              )}
            </VStack>
            <VStack>
              <Button variant="primary" size="md" type="submit" isDisabled={!isValid || !isDirty}>
                {portfolio?.portfolioCompanies?.length === 0 ? 'Done' : 'Send out'}
              </Button>
            </VStack>
          </VStack>
        </Center>
      </form>
    </ContentLayout>
  );
};

export default PortfolioSettings;
