import { FlaggedTargetsCountDocument_ } from './../../../../../../../backend/functions/_utils/__generated__/graphql';
import {
  Esrs_ActionTarget_Constraint_,
  Esrs_Baseline_Constraint_,
  Esrs_Baseline_Update_Column_,
  Esrs_MilestoneResult_Constraint_,
  Esrs_MilestoneResult_Update_Column_,
  Esrs_Milestone_Constraint_,
  Esrs_Milestone_Update_Column_,
  Esrs_Target_Constraint_,
  Esrs_Target_Update_Column_,
  GetActionsDocument_,
  GetReportingUnitsQuery_,
  GetDisclosureRequirementGroupsDocument_,
  GetTargetsDocument_,
  TargetsWithSubsidiariesFragment_,
  useDeleteActionTargetMutation,
  useUpsertMultipleMaterialStandardsMutation,
  useUpsertTargetMutation,
  GetMaterialStandardDocument_,
  GetTargetsSubsidiariesDocument_,
  GetParentTargetsDrDocument_,
  GetTargetsDrDocument_,
  EsrsAssessmentTargetsDocument_,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useGetTargetQuery,
  useGetActionsQuery,
  useTargetQuestionsQuery,
  useGetDisclosureRequirementRefQuery,
  DocumentationFile,
  useUpsertAttachmentsMutation,
  useAddNoteMutation,
  DocumentationFileDetailsFragment_,
  useGetTargetsSubsidiariesQuery,
  GetTargetsSubsidiariesQuery_,
  GetTargetQuery_,
  GetFlaggedTargetsDocument_,
  GetTargetDocument_,
  useGetTargetMetricsQuery,
  GetSubsidiariesQuery_,
} from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TargetFields, KeyResultEnums, DREnums } from '../../Requirement';
import { useAssessmentReportingUnits, useMaterialStandardId } from 'containers/Esrs';
import { useToast } from 'utils/hooks';
import { getDisclosureRequirementLink } from 'utils/links';
import { getSubMilestoneTotal } from './Milestones/Milestones.hooks';
import { nhost } from 'utils/nhost';

export type SubsidiariesType = NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'];

export type SubsidiariesTargetsType =
  | {
      id: string;
      company: {
        id: string;
        name: string;
      };
      target: SubsidiariesType[number]['materialStandards'][number]['targets'][number] | undefined;
    }[]
  | undefined;

export const useGetTargetData = (
  disclosureRequirementRef: string,
  esrsAssessmentId: string,
  standardRef: string,
  targetId: string | undefined,
  isGroup: boolean
) => {
  const { data: action, loading: actionLoading } = useGetDisclosureRequirementRefQuery({
    variables: {
      reference: disclosureRequirementRef,
      type: 'action',
    },
  });
  const actionRef = useMemo(
    () => action?.disclosureRequirement?.group.requirements[0]?.reference,
    [action]
  );

  const {
    companyAssessmentId: materialStandardId,
    parentAssessmentId: parentStandardId,
    assessmentYear,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: metrics, loading } = useGetTargetMetricsQuery({
    variables: {
      standardRef: standardRef,
      companyAssessmentId: materialStandardId,
      parentAssessmentId: parentStandardId || materialStandardId,
    },
    skip: !standardRef || !materialStandardId,
  });

  const { data: tData, loading: targetLoading } = useGetTargetQuery({
    variables: { targetId: targetId },
    skip: !targetId,
  });
  const targetData = useMemo(() => tData?.target, [tData]);

  const { reportingUnitAssessments: reportingUnits, loading: ruLoading } =
    useAssessmentReportingUnits(esrsAssessmentId);

  const { data: subsidiariesData, loading: subLoading } = useGetTargetsSubsidiariesQuery({
    variables: {
      esrsAssessmentId: isGroup ? esrsAssessmentId : parentStandardId,
      standardRef,
    },
    skip: isGroup ? !esrsAssessmentId : !parentStandardId,
  });

  const subsidiaries = useMemo(
    () => (isGroup ? subsidiariesData?.esrs?.subsidiaries ?? [] : []),
    [subsidiariesData]
  );
  const allSubsidiariesIds = useMemo(
    () => subsidiariesData?.esrs?.subsidiaries?.map((s) => s.id) ?? [],
    [subsidiariesData]
  );

  const subsidiariesTargets = useMemo(
    () =>
      isGroup
        ? subsidiariesData?.esrs?.subsidiaries.map((subsidiary) => ({
            id: subsidiary.id,
            company: subsidiary.company,
            target: subsidiary?.materialStandards?.[0]?.targets?.find(
              (target) =>
                target.parentTargetId === target.id || target.metricRef === targetData?.metricRef
            ),
          })) ?? []
        : [],
    [subsidiariesData, targetData]
  );

  const { data: actData, loading: actionsLoading } = useGetActionsQuery({
    variables: {
      disclosureRequirementRef,
      assessmentId: materialStandardId,
    },
    skip: !materialStandardId || !disclosureRequirementRef,
  });
  const actionsData = useMemo(() => actData?.actions, [actData]);

  const parentActionsData = useMemo(
    () =>
      targetData?.parentTargetId
        ? targetData.parentTarget?.actionTargets.map((at) => at.action)
        : [],
    [targetData]
  );

  const { data: targetQuestionsData, loading: targetQuestionsLoading } = useTargetQuestionsQuery({
    variables: {
      standardRef,
    },
    skip: !standardRef,
  });

  const targetQuestions = useMemo(() => targetQuestionsData?.TargetQuestion, [targetQuestionsData]);

  const parentMilestoneYears =
    targetData?.parentTarget?.milestones.map((milestone) => milestone.year) ?? [];

  const initialLocalAnswers: TargetFields = useMemo(() => {
    const buBaselinesArray =
      targetData?.keyResults
        .filter((res) => res.reportingUnitId !== null)
        .map((k) => ({
          [`${KeyResultEnums.baseYear}_${k.reportingUnitId}`]: k.baseYear,
          [`${KeyResultEnums.baseline}_${k.reportingUnitId}`]: k.baseline,
        })) ?? [];
    const baselinesObject = Object.assign({}, ...buBaselinesArray);

    const getMilestoneValue = (
      milestone: NonNullable<GetTargetQuery_['target']>['milestones'][number]
    ) => {
      const { totalMilestones } = getSubMilestoneTotal(subsidiariesTargets, milestone.year);
      if (isGroup && targetData?.isBottomUp) return totalMilestones;
      return milestone.milestoneResults.find((mr) => mr.reportingUnitId === null)?.value;
    };

    const parentMilestoneYearsObjects =
      targetData?.parentTarget?.milestones
        .map((milestone) => ({
          year: milestone.year,
          isRelative: true,
          value: undefined,
          reportingUnits: [],
        }))
        .filter((milestone) => !targetData.milestones.some((m) => m.year === milestone.year)) ?? [];

    const milestonesArray =
      targetData?.milestones.map((milestone) => ({
        id: milestone.id,
        year: milestone.year,
        isRelative: milestone.isRelative,
        value: getMilestoneValue(milestone),
        isRequired: milestone.isRequired,
        reportingUnits: Object.assign(
          {},
          ...(milestone.milestoneResults
            .filter((mr) => mr.reportingUnitId !== null)
            .map((mr) => ({
              [`${mr.reportingUnitId}`]: mr.value,
            })) ?? [])
        ),
      })) ?? [];

    const milestones = [...parentMilestoneYearsObjects, ...milestonesArray];

    const baseline = targetData?.keyResults.find((k) => k.reportingUnitId === null)?.baseline;

    return {
      id: targetData?.id,
      name: targetData?.name,
      direction: targetData?.direction ?? null,
      isBottomUp: targetData?.isBottomUp,
      metricRef: targetData?.metricRef ?? null,
      description: targetData?.description ?? null,
      owner: targetData?.owner ?? null,
      baseline: baseline === 'undefined' || baseline === 'null' ? null : baseline,
      baseYear: targetData?.keyResults.find((k) => k.reportingUnitId === null)?.baseYear,
      isFlagged: targetData?.keyResults.find((k) => k.reportingUnitId === null)?.isFlagged,
      relatedIRO: targetData?.relatedIRO,
      relatedPolicy: targetData?.relatedPolicy,
      parentTargetId: targetData?.parentTargetId,
      period: targetData?.period,
      periodExplanation: targetData?.periodExplanation,
      scope: targetData?.scope,
      methodologies: targetData?.methodologies,
      isEvidenceScientific: targetData?.isEvidenceScientific,
      isEvidenceScientificExplanation: targetData?.isEvidenceScientificExplanation,
      withStakeholders: targetData?.withStakeholders,
      withStakeholdersExplanation: targetData?.withStakeholdersExplanation,
      withStakeholdersDescription: targetData?.withStakeholdersDescription,
      targetChangeDescription: targetData?.targetChangeDescription,
      isProgressAligned: targetData?.isProgressAligned,
      isProgressAlignedExplanation: targetData?.isProgressAlignedExplanation,
      performanceText: targetData?.performanceText,
      performanceNumber: targetData?.performanceNumber,
      actions:
        targetData?.actionTargets.flatMap((at) => ({
          actionId: at.actionId,
          estimate: at.estimate,
        })) ?? [],
      answers: targetData?.answers,
      milestones: milestones,
      ...baselinesObject,
    };
  }, [targetData]);

  return {
    metrics,
    materialStandardId,
    parentStandardId,
    targetData,
    reportingUnits,
    subsidiaries,
    actionsData,
    parentActionsData,
    actionRef,
    initialLocalAnswers,
    allSubsidiariesIds,
    targetQuestions,
    subsidiariesTargets,
    parentMilestoneYears,
    assessmentYear,
    loading:
      loading ||
      materialLoading ||
      targetLoading ||
      ruLoading ||
      subLoading ||
      actionsLoading ||
      actionLoading ||
      targetQuestionsLoading,
  };
};

const getChosenActions = (
  targetFields: TargetFields,
  targetData: TargetsWithSubsidiariesFragment_ | null | undefined
) => {
  if (targetFields?.actions?.length || targetFields.actions[0] !== undefined) {
    const targetActions = targetFields.actions.filter((a) => !!a) ?? [];
    if (!targetData) {
      return targetActions;
    }
    return (
      targetActions.filter(
        ({ actionId }) =>
          !targetData?.actionTargets.find((actionTarget) => actionTarget.actionId === actionId)
      ) ?? []
    );
  }

  return [];
};

const targetFieldsSection = (values: TargetFields) => {
  return {
    name: values.name,
    direction: values.direction,
    ownerId: values.ownerId,
    isBottomUp: values.isBottomUp,
    metricRef: values.metricRef,
    answers: values.answers,
    relatedIRO: values.relatedIRO,
    relatedPolicy: values.relatedPolicy,
    period: values.period,
    periodExplanation: values.periodExplanation,
    scope: values.scope,
    methodologies: values.methodologies,
    isEvidenceScientific: values.isEvidenceScientific,
    isEvidenceScientificExplanation: values.isEvidenceScientificExplanation,
    withStakeholders: values.withStakeholders,
    withStakeholdersExplanation: values.withStakeholdersExplanation,
    withStakeholdersDescription: values.withStakeholdersDescription,
    targetChangeDescription: values.targetChangeDescription,
    performanceText: values.performanceText,
    performanceNumber: values.performanceNumber,
    isProgressAligned: values.isProgressAligned,
    isProgressAlignedExplanation: values.isProgressAlignedExplanation,
  };
};

export const getSubsidiaryBaselineTotal = (subsidiariesTargets: SubsidiariesTargetsType) => {
  const missingBaselines: string[] = [];

  const baselineValues = subsidiariesTargets?.map((subsidiary) => {
    const baseline = subsidiary.target?.keyResults.find(
      (kr) => kr.reportingUnitId === null
    )?.baseline;
    if (!baseline) missingBaselines.push(subsidiary.company.name);
    return Number(baseline ?? 0);
  });

  const total =
    baselineValues?.reduce((acc, cur) => {
      return acc + (cur ?? 0);
    }, 0) ?? 0;
  return { total, missingBaselines };
};

export const getBaselineTotal = (
  fields: {
    id: any;
    name: string;
    [key: string]: any;
  }[],
  values: TargetFields
) => {
  const missingBaselines: string[] = [];

  const baselineValues =
    fields?.map((field) => {
      const baseline = values[`baseline_${field.id}`];
      if (!baseline) missingBaselines.push(field.name);
      return !!baseline ? Number(baseline) : 0;
    }) ?? [];

  const total =
    baselineValues?.reduce((acc, cur) => {
      return acc + cur;
    }, 0) ?? undefined;
  return { total, missingBaselines };
};

const keyResultsFieldsSection = (data: {
  values: TargetFields;
  targetData: TargetsWithSubsidiariesFragment_ | null | undefined;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  subsidiary?: SubsidiariesType[number];
  isSubsidiary: boolean;
}) => {
  const { values, targetData, reportingUnits, subsidiary, isSubsidiary } = data;

  const baselineId =
    targetData?.keyResults.find((k) => k.reportingUnitId === null)?.id ?? undefined;

  const subsidiaryKeyResults = targetData?.subsidiariesTargets?.find(
    (target) => target?.materialStandard.assessmentId === subsidiary?.id
  )?.keyResults;

  const subsidiaryBaselineIds = subsidiaryKeyResults?.map((k) => k.id);

  const subsidiaryMainKR = subsidiaryKeyResults?.find((kr) => kr.reportingUnitId === null);

  const isFlagged =
    !values.baseYear || !subsidiaryMainKR?.baseline || subsidiaryMainKR?.baseline == ''
      ? false
      : new Date(values.baseYear).getFullYear() !==
        new Date(subsidiaryMainKR?.baseYear).getFullYear();

  if (isSubsidiary) {
    return {
      data: subsidiaryBaselineIds?.length
        ? subsidiaryBaselineIds?.map((id) => ({
            id: id,
            baseYear: values.baseYear,
            isFlagged: isFlagged,
          })) ?? []
        : [
            {
              baseYear: values.baseYear,
              reportingUnitId: null,
            },
          ],
      on_conflict: {
        constraint: Esrs_Baseline_Constraint_.KeyResultPkey_,
        update_columns: [
          Esrs_Baseline_Update_Column_.BaseYear_,
          Esrs_Baseline_Update_Column_.IsFlagged_,
        ],
      },
    };
  }

  return {
    data: [
      {
        id: baselineId,
        baseYear: values.baseYear,
        baseline: !!values.baseline ? `${values.baseline}` : null,
        reportingUnitId: null,
        isFlagged: values.isFlagged,
      },
      ...(reportingUnits?.map((ru) => ({
        id: targetData?.keyResults.find((k) => k.reportingUnitId === ru.id)?.id,
        reportingUnitId: ru.id,
        baseYear: values.baseYear,
        baseline: !!values[`${KeyResultEnums.baseline}_${ru.id}`]
          ? `${values[`${KeyResultEnums.baseline}_${ru.id}`]}`
          : null,
      })) ?? []),
    ],
    on_conflict: {
      constraint: Esrs_Baseline_Constraint_.KeyResultPkey_,
      update_columns: [
        Esrs_Baseline_Update_Column_.BaseYear_,
        Esrs_Baseline_Update_Column_.Baseline_,
        Esrs_Baseline_Update_Column_.ReportingUnitId_,
        Esrs_Baseline_Update_Column_.IsFlagged_,
      ],
    },
  };
};

const MilestonesFieldsSection = (data: {
  values: TargetFields;
  targetData: TargetsWithSubsidiariesFragment_ | null | undefined;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  isGroup: boolean;
}) => {
  const { values, targetData, reportingUnits, isGroup } = data;

  const parentMilestoneYears = targetData?.parentTarget?.milestones.map((m) => m.year) ?? [];

  const milestones =
    targetData?.parentTargetId !== null
      ? values.milestones.filter((milestone) => {
          const hasRUvalues =
            !isGroup && values.isBottomUp
              ? reportingUnits?.some((ru) => !!milestone?.reportingUnits[`${ru.id}`])
              : false;
          const hasValue = !!milestone.value && milestone.value !== 0;
          const isParentMilestone = parentMilestoneYears.includes(milestone.year);
          if (!isParentMilestone) return true;
          return !!milestone.id || hasValue || hasRUvalues;
        })
      : values.milestones;

  return {
    data:
      milestones?.map((milestone) => ({
        id: milestone?.id,
        year: Number(milestone.year),
        isRelative: milestone.isRelative,
        isRequired: parentMilestoneYears?.includes(Number(milestone.year)),
        milestoneResults: {
          data: [
            {
              id: targetData?.milestones
                ?.find((m) => m.id === milestone?.id)
                ?.milestoneResults?.find((m) => m.reportingUnitId === null)?.id,
              value: milestone.value,
              reportingUnitId: null,
            },
            ...(reportingUnits?.map((ru) => ({
              id: targetData?.milestones
                .find((m) => m.id === milestone.id)
                ?.milestoneResults?.find((m) => m.reportingUnitId === ru.id)?.id,
              reportingUnitId: ru.id,
              value: milestone?.reportingUnits[`${ru.id}`] ?? 0,
            })) ?? []),
          ],
          on_conflict: {
            constraint: Esrs_MilestoneResult_Constraint_.MilestoneResultPkey_,
            update_columns: [Esrs_MilestoneResult_Update_Column_.Value_],
          },
        },
      })) ?? [],
    on_conflict: {
      constraint: Esrs_Milestone_Constraint_.MilestoneIdKey_,
      update_columns: [
        Esrs_Milestone_Update_Column_.Year_,
        Esrs_Milestone_Update_Column_.IsRelative_,
        Esrs_Milestone_Update_Column_.IsRequired_,
      ],
    },
  };
};

export const useAddTarget = () => {
  const [upsertTarget] = useUpsertTargetMutation();
  const [deleteActionTarget] = useDeleteActionTargetMutation();
  const [addAssessments] = useUpsertMultipleMaterialStandardsMutation();
  const [upsertAttachments] = useUpsertAttachmentsMutation();
  const [addNote] = useAddNoteMutation();
  const toast = useToast();

  return useCallback(
    (
      values: TargetFields,
      targetData: TargetsWithSubsidiariesFragment_ | null | undefined,
      disclosureRequirement: string,
      materialityAssessmentId: string,
      reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined,
      subsidiaries: SubsidiariesType,
      standardRef: string,
      targetId: string | undefined,
      isGroup: boolean,
      setIsSaved: (param: boolean) => void,
      setIsSaveDisabled: (param: boolean) => void,
      userId?: string,
      note?: string | null,
      filesAttached?: DocumentationFileDetailsFragment_[],
      setNote?: (param: string | null) => void,
      setFilesAttached?: (param: DocumentationFileDetailsFragment_[]) => void
    ) => {
      const missingAssessments = subsidiaries.filter((s) => !s.materialStandards.length) ?? [];
      const toDelete: string[] =
        targetData?.actionTargets
          ?.filter(
            (actionTarget) =>
              !values.actions?.find((action) => action.actionId === actionTarget.actionId)
          )
          .map((actionTarget) => actionTarget.id) ?? [];

      const chosenActions = getChosenActions(values, targetData);
      addAssessments({
        variables: {
          materialStandards: missingAssessments.map((assessment) => ({
            assessmentId: assessment.id,
            standardRef,
            isMaterial: true,
          })),
        },
      }).then((res) => {
        upsertTarget({
          variables: {
            target: {
              id: targetId,
              disclosureRequirementRef: disclosureRequirement,
              assessmentId: materialityAssessmentId,
              ...targetFieldsSection(values),
              actionTargets: {
                data: chosenActions,
                on_conflict: {
                  constraint: Esrs_ActionTarget_Constraint_.ActionTargetActionIdTargetIdKey_,
                  update_columns: [],
                },
              },
              keyResults: keyResultsFieldsSection({
                values,
                targetData,
                reportingUnits: values?.isBottomUp ? reportingUnits : [],
                isSubsidiary: false,
              }),
              milestones: MilestonesFieldsSection({
                values,
                targetData,
                reportingUnits: values?.isBottomUp ? reportingUnits : [],
                isGroup,
              }),
              subsidiariesTargets: values.isBottomUp
                ? {
                    data: [
                      ...subsidiaries.map((s) => ({
                        id: targetData?.subsidiariesTargets.find(
                          (target) => target?.materialStandard.assessmentId === s?.id
                        )?.id,
                        disclosureRequirementRef: disclosureRequirement,
                        assessmentId: s.materialStandards.length
                          ? s.materialStandards[0].id
                          : res.data?.insert_esrs_MaterialStandard?.returning.filter(
                              (r) => r.assessmentId === s.id
                            )[0].id,
                        name: 'New target',
                        metricRef: targetData?.metricRef,
                        keyResults: keyResultsFieldsSection({
                          values,
                          targetData,
                          subsidiary: s,
                          isSubsidiary: true,
                        }),
                        attachmentBox: {
                          data: {},
                          on_conflict: {
                            constraint: AttachmentBox_Constraint_.AttachmentBoxTargetIdKey_,
                            update_columns: [AttachmentBox_Update_Column_.TargetId_],
                          },
                        },
                        noteHistory: {
                          data: {},
                          on_conflict: {
                            constraint: NoteHistory_Constraint_.NoteHistoryTargetIdKey_,
                            update_columns: [NoteHistory_Update_Column_.TargetId_],
                          },
                        },
                      })),
                    ],
                    on_conflict: {
                      constraint: Esrs_Target_Constraint_.TargetPkey_,
                      update_columns: [Esrs_Target_Update_Column_.Direction_],
                    },
                  }
                : undefined,
            },
          },
          refetchQueries: [
            GetTargetDocument_,
            GetDisclosureRequirementGroupsDocument_,
            GetTargetsDrDocument_,
            GetTargetsDocument_,
            GetTargetsSubsidiariesDocument_,
            GetParentTargetsDrDocument_,
            GetMaterialStandardDocument_,
            EsrsAssessmentTargetsDocument_,
            GetFlaggedTargetsDocument_,
            FlaggedTargetsCountDocument_,
          ],
          awaitRefetchQueries: true,
        })
          .then((response) => {
            setIsSaved(true);
            deleteActionTarget({
              variables: {
                id: toDelete,
              },
              refetchQueries: [
                GetTargetDocument_,
                GetDisclosureRequirementGroupsDocument_,
                GetTargetsDocument_,
                GetActionsDocument_,
                GetTargetsSubsidiariesDocument_,
                GetParentTargetsDrDocument_,
                GetMaterialStandardDocument_,
              ],
              awaitRefetchQueries: true,
            });

            if (filesAttached?.length) {
              const fileData =
                filesAttached?.map((file: DocumentationFile) => {
                  return {
                    attachmentBoxId:
                      response.data?.insert_esrs_Target?.returning[0].attachmentBox?.id,
                    fileId: file.id,
                  };
                }) ?? [];

              upsertAttachments({
                variables: {
                  attachmentInputs: fileData,
                },
              }).then(() => setFilesAttached?.([]));
            }
            if (note) {
              addNote({
                variables: {
                  noteInput: {
                    body: note,
                    noteHistoryId: response.data?.insert_esrs_Target?.returning[0].noteHistory?.id,
                    authorId: userId,
                  },
                },
              }).then(() => setNote?.(''));
            }
          })
          .catch(() => {
            setIsSaveDisabled(false);
            toast({
              text: !!targetData?.id
                ? 'Failed to save changes'
                : 'Failed to add target. Make sure the target name is unique.',
              variant: 'danger',
            });
          });
      });
    },
    [upsertTarget]
  );
};

export const openSubsidiary = (subsidiary: NonNullable<SubsidiariesTargetsType>[number]) => {
  window.open(
    `${getDisclosureRequirementLink({
      companyId: subsidiary.company.id,
      assessmentId: subsidiary?.target?.materialStandard.esrsAssessment.id,
      standardRef: subsidiary?.target?.materialStandard.standardRef,
      disclosureRequirement: {
        reference: subsidiary?.target?.disclosureRequirementRef ?? '',
        type: DREnums.target,
      },
    })}/target-form/${subsidiary.target?.id}/edit`
  );
};

export const useGetEsrsSubsidiaries = ({ assessmentId }: { assessmentId: string }) => {
  const [standards, setStandards] = useState<GetSubsidiariesQuery_>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (assessmentId) {
      setLoading(true);
      nhost.functions
        .call<GetSubsidiariesQuery_>('get-esrs-subsidiaries', {
          assessmentId,
        })
        .then((res) => {
          setLoading(false);
          if (res.error) {
            console.error(res.error);
            return;
          }

          setStandards(res.res.data);
        });
    }
  }, [assessmentId]);
  return { data: standards, loading };
};
