import { HStack } from '@chakra-ui/react';
import { CancelIcon, CommentIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { useState, useMemo, useEffect } from 'react';
import { AggregatedMetricsTableData, MetricsTableData, areArraysOfObjectsEqual } from '..';
import { TimePeriodsEnums } from '../../Requirement';
import { GeneratedAnswerStatus, GeneratedAnswer } from '../MetricAI';
import { AssessableMetrics } from '../Metrics';
import { useGetDatapointValues } from './MetricsTableComponents/InputFields/QuantitativeInputs';
import { Button, IconButton } from 'Atoms';
import { useNotifications } from 'Features';
import { Typography } from 'Tokens';
import { Select } from 'Molecules';

export const AnswerApprovalButton = ({
  state,
  onApprove,
  onDiscard,
  onCancelApprove,
  onCancelDiscard,
}: {
  onApprove: () => void;
  onDiscard: () => void;
  onCancelApprove: () => void;
  onCancelDiscard: () => void;
  state: GeneratedAnswerStatus | null;
}) => {
  if (state === GeneratedAnswerStatus.approved) {
    return (
      <HStack spacing="2px">
        <Button selected variant="secondary" size="sm">
          Approved
        </Button>
        <IconButton
          tooltipLabel="Cancel"
          aria-label="Cancel"
          size="sm"
          variant="ghost"
          icon={<CancelIcon />}
          onClick={onCancelApprove}
        />
      </HStack>
    );
  }

  if (state === GeneratedAnswerStatus.discarded) {
    return (
      <HStack spacing="2px">
        <Button selected variant="secondary" size="sm">
          Discarded
        </Button>{' '}
        <IconButton
          tooltipLabel="Cancel"
          aria-label="Cancel"
          size="sm"
          variant="ghost"
          icon={<CancelIcon />}
          onClick={onCancelDiscard}
        />
      </HStack>
    );
  }

  return (
    <HStack spacing="2px">
      <Button variant="secondary" size="sm" onClick={onApprove}>
        Approve
      </Button>
      <IconButton
        tooltipLabel="Discard"
        aria-label="Discard"
        size="sm"
        variant="ghost"
        icon={<RemoveIcon />}
        onClick={onDiscard}
      />
    </HStack>
  );
};

export const AnswerApprovalCell = ({
  row,
  companyReportingUnitId,
  generatedAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
  assessmentProjectLeaderId,
}: {
  row: MetricsTableData;
  companyReportingUnitId?: string;
  generatedAnswers: GeneratedAnswer[];
  setGeneratedAnswers?: (generatedAnswers: GeneratedAnswer[]) => void;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  populateNarrativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    narrativeMetrics: AssessableMetrics
  ) => void;
  assessmentProjectLeaderId?: string;
}) => {
  const [prevValue, setPrevValue] = useState<string | null>();

  const { dataPointPerYear, answer, onDatapointChange } = useGetDatapointValues(
    row,
    companyReportingUnitId
  );

  const generatedAnswer = useMemo(
    () =>
      generatedAnswers?.find(
        (a) => a.metricRef === row?.metric?.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
      ),
    [generatedAnswers, row]
  );

  useEffect(() => {
    if (dataPointPerYear) {
      setPrevValue(dataPointPerYear.value);
    }
  }, []);

  const handleChangeStatus = (status: GeneratedAnswerStatus | null) => {
    setGeneratedAnswers?.(
      (generatedAnswers ?? []).map((a) => {
        if (a.metricRef === row.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)) {
          return {
            ...a,
            status,
          };
        }
        return a;
      })
    );
  };

  return (
    generatedAnswer && (
      <AnswerApprovalButton
        state={generatedAnswer?.status}
        onApprove={() => {
          if (populateQuantitativeAnswers) populateQuantitativeAnswers([generatedAnswer], [row]);
          if (populateNarrativeAnswers) populateNarrativeAnswers([generatedAnswer], [row.metric]);
          handleChangeStatus(GeneratedAnswerStatus.approved);
        }}
        onDiscard={() => handleChangeStatus(GeneratedAnswerStatus.discarded)}
        onCancelApprove={() => {
          handleChangeStatus(null);
          if (prevValue) {
            onDatapointChange({
              dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
              value: prevValue ?? null,
              hasOptedOut: answer?.hasOptedOut ?? false,
              optOutReason: answer?.optOutReason ?? '',
              assessmentProjectLeaderId: assessmentProjectLeaderId,
              tags: row.tags,
            });
          }
        }}
        onCancelDiscard={() => handleChangeStatus(null)}
      />
    )
  );
};

export enum GroupOrderOptions {
  byType = 'Group by type',
  byEsrsOrder = 'ESRS order',
}

export const GroupOrderSelector = ({
  groupOrOrder,
  setGroupOrOrder,
}: {
  groupOrOrder: GroupOrderOptions;
  setGroupOrOrder: (val: GroupOrderOptions) => void;
}) => {
  return (
    <Select
      isSearchable={false}
      ghostVariant
      size="sm"
      width="fit-content"
      menuWidth="150px"
      defaultValue={{ label: groupOrOrder, value: groupOrOrder }}
      onChange={(val) => setGroupOrOrder(val?.value ?? GroupOrderOptions.byType)}
      options={[
        {
          label: GroupOrderOptions.byType,
          value: GroupOrderOptions.byType,
        },
        {
          label: GroupOrderOptions.byEsrsOrder,
          value: GroupOrderOptions.byEsrsOrder,
        },
      ]}
    />
  );
};

export const QuarterSelector = ({
  defaultQuarter,
  setSelectedQuarter,
}: {
  defaultQuarter: TimePeriodsEnums;
  setSelectedQuarter: (val: TimePeriodsEnums) => void;
}) => {
  return (
    <Select
      isSearchable={false}
      ghostVariant
      size="sm"
      width="fit-content"
      menuWidth="110px"
      onChange={(val) => setSelectedQuarter(val?.value ?? TimePeriodsEnums.year)}
      defaultValue={{
        label: defaultQuarter === TimePeriodsEnums.year ? 'Full year' : defaultQuarter,
        value: defaultQuarter,
      }}
      options={[
        {
          label: 'Full year' as TimePeriodsEnums,
          value: TimePeriodsEnums.year,
        },
        {
          label: TimePeriodsEnums.q1,
          value: TimePeriodsEnums.q1,
        },
        {
          label: TimePeriodsEnums.q2,
          value: TimePeriodsEnums.q2,
        },
        {
          label: TimePeriodsEnums.q3,
          value: TimePeriodsEnums.q3,
        },
        {
          label: TimePeriodsEnums.q4,
          value: TimePeriodsEnums.q4,
        },
      ]}
    />
  );
};

export const CommentsCounter = ({
  row,
  selectedQuarter,
  companyReportingUnit,
  onClick,
}: {
  row: AggregatedMetricsTableData;
  selectedQuarter: string;
  companyReportingUnit?: string;
  onClick: () => void;
}) => {
  const { answer } = useGetDatapointValues(row, companyReportingUnit);
  const { notifications } = useNotifications();

  const dataPoint = useMemo(
    () => answer?.datapoints.find((dp) => dp.timeframe === selectedQuarter),
    [answer, selectedQuarter]
  );

  const commentsCount = useMemo(
    () => dataPoint?.thread?.comments_aggregate.aggregate?.count ?? 0,
    [dataPoint]
  );

  const hasUnread = useMemo(
    () =>
      notifications.some(
        (notf) => notf.comment.thread.esrsDatapoint?.id === dataPoint?.id && !notf.isRead
      ),
    [notifications, dataPoint]
  );

  return (
    <Button
      size="sm"
      variant="ghost"
      leftIcon={<CommentIcon color={hasUnread ? 'text.action' : 'text.muted'} />}
      onClick={onClick}
    >
      <Typography
        variant="bodyStrong"
        color={hasUnread ? 'text.action' : 'text.muted'}
        opacity={commentsCount === 0 ? 0.2 : 1}
      >
        {commentsCount}
      </Typography>
    </Button>
  );
};
