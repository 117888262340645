import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  HStack,
  Checkbox,
  VStack,
  Divider,
  Box,
} from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { Typography } from 'Tokens';
import { useState, useRef, useEffect, useMemo } from 'react';

const ChoicesDisplay = ({ choices }: { choices: string[] | undefined }) => {
  const [displayText, setDisplayText] = useState('Select');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (choices?.length) {
      const container = containerRef.current;
      if (!container) return;

      const formatText = (arr: string[], limit: number) => {
        let text = arr.slice(0, limit).join(', ');
        if (arr.length > limit) {
          text += `, +${arr.length - limit}`;
        }
        return text;
      };

      let currentText = formatText(choices, choices.length);
      container.innerText = currentText;

      if (container.scrollWidth <= container.clientWidth) {
        setDisplayText(currentText);
        return;
      }

      for (let i = choices.length - 1; i >= 0; i--) {
        currentText = formatText(choices, i);
        container.innerText = currentText;
        if (container.scrollWidth <= container.clientWidth) {
          setDisplayText(currentText);
          break;
        }
      }
    } else {
      setDisplayText('Select');
    }
  }, [choices]);

  return (
    <HStack spacing="4px">
      <Box
        ref={containerRef}
        width="calc(100%-20px)"
        maxW="480px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {displayText}
      </Box>
      <ChevronDownIcon color="text.muted" boxSize="16px" />
    </HStack>
  );
};

export const MenuSelector = ({
  options,
  isSingleSelect,
  data,
  handleChange,
  isTagMenuButton = false,
  menuWidth,
  additionalContent,
  isOptionDisabled = () => false,
}: {
  options: { value: string; label: string }[];
  isSingleSelect: boolean;
  data: string[] | undefined;
  handleChange: (val: string[] | undefined) => void;
  isTagMenuButton?: boolean;
  menuWidth?: string;
  additionalContent?: React.ReactNode;
  isOptionDisabled?: (val: string) => boolean;
}) => {
  const [selectedChoices, setSelectedChoices] = useState<string[] | undefined>(data);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>();

  const choicesLabels = useMemo(
    () => data?.map((d) => options.find((opt) => opt.value === d)?.label ?? ''),
    [data]
  );

  useEffect(() => setSelectedChoices(data), [data]);

  return (
    <Menu matchWidth={false} isOpen={isMenuOpen} closeOnSelect={isSingleSelect ? undefined : false}>
      {isTagMenuButton ? (
        <MenuButton as={Tag} clickable>
          <ChoicesDisplay choices={choicesLabels} />
        </MenuButton>
      ) : (
        <MenuButton
          as={Button}
          variant={'ghost'}
          size="md"
          width="fit-content"
          textAlign="start"
          onClick={() => setIsMenuOpen(undefined)}
          pl="4px"
        >
          <ChoicesDisplay choices={choicesLabels} />
        </MenuButton>
      )}
      <MenuList padding="8px" w={menuWidth ?? 'unset'}>
        <VStack alignItems="stretch">
          <Box maxH="400px" overflow="auto">
            {options.map((option) => (
              <MenuItem
                isDisabled={isOptionDisabled(option.value)}
                onClick={() => {
                  if (isSingleSelect) handleChange([option.value]);
                  else
                    setSelectedChoices(
                      selectedChoices?.includes(option.value) ?? false
                        ? selectedChoices?.filter((c) => c !== option.value)
                        : [...(selectedChoices ?? []), option.value]
                    );
                }}
              >
                {isSingleSelect ? (
                  <HStack justifyContent="space-between" w="100%">
                    <Typography
                      variant="bodyStrong"
                      color={
                        selectedChoices?.includes(option.value) ? 'text.selected' : 'text.muted'
                      }
                    >
                      {option.label}
                    </Typography>
                    {selectedChoices?.includes(option.value) && <CheckIcon color="text.selected" />}
                  </HStack>
                ) : (
                  <HStack width="100%">
                    <Checkbox isChecked={selectedChoices?.includes(option.value) ?? false} />
                    <Typography variant="bodyStrong" color="text.muted">
                      {option.label}
                    </Typography>
                  </HStack>
                )}
              </MenuItem>
            ))}
          </Box>
          {!isSingleSelect && (
            <VStack gap="0px">
              <Divider color="border.decorative" mb="8px" ml="-8px" pr="16px" />
              <Box w="100%">
                <Button
                  variant="primary"
                  w="100%"
                  onClick={() => {
                    handleChange(selectedChoices);
                    if (!isSingleSelect) setIsMenuOpen(false);
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </VStack>
          )}
          {additionalContent && (
            <>
              <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
              {additionalContent}
            </>
          )}
        </VStack>
      </MenuList>
    </Menu>
  );
};
