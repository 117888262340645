import { MetricsTableData } from '../../../MetricAnswers.hooks';

export const getRowName = (row: MetricsTableData, isTooltip?: boolean) => {
  if (row.tagName) {
    if (!isTooltip) {
      if (row.parentMetric) {
        return `${row.metric.shortTitle ?? row.metric.title}`;
      }
      if (row?.subRows) {
        return `${row.metric.shortTitle ?? row.metric.title} ${row.tagName}`;
      }
    }
    return row.tagName;
  }

  return `${row.metric.shortTitle ?? row.metric.title} ${row.tagType ? 'by ' : ''}`;
};
