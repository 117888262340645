import { Box, HStack } from '@chakra-ui/react';
import { ContentHeader, ContentLayout } from 'Molecules';
import { GetMaterialStandardQuery_ } from 'models';
import { Alert, Infobox, Tag } from 'Atoms';
import { LockedIcon } from 'Tokens/Icons/Function';
import {
  AdditionalType,
  Materiality,
  MaterialityState,
  MaterialityStatus,
  ParentMaterialityState,
  StandardMaterialityState,
} from './DoubleMaterialityAssessment.d';
import { Typography } from 'Tokens';
import { useMemo } from 'react';
import { AdditionalTypesGroup } from 'Molecules/MetricTagsList';

export const BOX_STATES = {
  [MaterialityState.toAssess]: {
    textColor: 'text.warning',
    text: 'To assess',
    bgColor: 'bg.warning',
    width: '84px',
  },
  defaultToAssess: {
    textColor: 'text.muted',
    text: 'To assess',
    bgColor: 'bg.unknown',
    width: '84px',
  },
  [MaterialityState.materialMandatory]: {
    textColor: 'text.info',
    text: 'Material',
    bgColor: 'bg.info',
    width: '95px',
    iconColor: 'text.info',
  },
  [MaterialityState.mandatory]: {
    textColor: 'text.muted',
    text: 'Mandatory',
    bgColor: 'bg.unknown',
    width: '115px',
    iconColor: 'text.muted',
  },
  [MaterialityState.material]: {
    textColor: 'text.info',
    text: 'Material',
    bgColor: 'bg.info',
    width: '77px',
  },
  [MaterialityState.notMaterial]: {
    textColor: 'text.muted',
    text: 'Not material',
    bgColor: 'bg.unknown',
    width: '106px',
  },
  [MaterialityState.gatherData]: {
    textColor: 'text.muted',
    text: 'Gather data',
    bgColor: 'bg.unknown',
    width: '106px',
  },
  [MaterialityState.collectDataMandatory]: {
    textColor: 'text.info',
    text: 'Collect data',
    bgColor: 'bg.info',
    width: '95px',
    iconColor: 'text.info',
  },
  [MaterialityState.collectData]: {
    textColor: 'text.info',
    text: 'Collect data',
    bgColor: 'bg.info',
    width: '95px',
  },
  [MaterialityState.doNotCollect]: {
    textColor: 'text.muted',
    text: 'Do not collect',
    bgColor: 'bg.unknown',
    width: '95px',
  },
};

const TAG_STATES = {
  material: {
    variant: 'info',
    text: MaterialityStatus.material,
  },
  notMaterial: {
    variant: 'undefined',
    text: MaterialityStatus.notMaterial,
  },
  mandatory: {
    variant: 'undefined',
    text: MaterialityState.mandatory,
  },
  toAssess: {
    variant: 'warning',
    text: 'To assess',
  },
  gatherData: {
    variant: 'undefined',
    text: 'Gather data',
  },
};

const PARENT_INFO_STATES = {
  [MaterialityState.toAssess]: 'Not assessed by a parent company',
  [MaterialityState.notMaterial]: 'Not material for a parent company',
  [MaterialityState.material]: 'Material for a parent company',
  [MaterialityState.mandatory]: 'Material for a parent company',
  [MaterialityState.gatherData]: 'Not material for a parent company but gathering data',
  [MaterialityState.materialMandatory]: 'Material for a parent company',
};

export const MaterialityStatusTag = ({
  isAssessed,
  isMaterial,
  isDataGather,
  isMandatory,
}: {
  isAssessed: boolean;
  isMaterial?: boolean | null;
  isDataGather: boolean;
  isMandatory: boolean;
}) => {
  const state = useMemo(() => {
    if (isMandatory) return MaterialityState.mandatory;
    if (isDataGather) return 'gatherData';
    if (!isAssessed || isMaterial === null) return 'toAssess';
    if (isMaterial) return 'material';
    return 'notMaterial';
  }, [isAssessed, isMaterial, isDataGather]);
  return <Tag variant={TAG_STATES[state].variant}>{TAG_STATES[state].text}</Tag>;
};

export const MaterialityStatusBox = ({
  materiality,
}: {
  materiality: Materiality | 'defaultToAssess';
}) => {
  return (
    <HStack
      backgroundColor={BOX_STATES[materiality].bgColor}
      p="4px 10px 4px 10px"
      width="fit-content"
      spacing="6px"
      borderRadius="6px"
    >
      {(materiality === MaterialityState.mandatory ||
        materiality === MaterialityState.materialMandatory ||
        materiality === MaterialityState.collectDataMandatory) && (
        <LockedIcon color={BOX_STATES[materiality].iconColor} />
      )}
      <Typography variant="bodyStrong" color={BOX_STATES[materiality].textColor}>
        {BOX_STATES[materiality].text}
      </Typography>
    </HStack>
  );
};

export const ParentInfoBox = ({ status }: { status: ParentMaterialityState }) => {
  return (
    <Alert
      status="neutral"
      title={PARENT_INFO_STATES[status]}
      textColor="text.default"
      closable={false}
    />
  );
};

export const MaterialityHeader = ({
  title,
  reference,
  isCollectOnly,
  materiality,
  hasParent,
  materialStandard,
  parentMateriality,
  standardAdditionalTypes,
  isStandardMandatory,
}: {
  title: string;
  reference: string;
  materialStandard?: GetMaterialStandardQuery_['materialityAssessment'][number];
  hasParent: boolean;
  materiality: StandardMaterialityState;
  standardAdditionalTypes: AdditionalType[];
  parentMateriality: ParentMaterialityState;
  isStandardMandatory: boolean;
  isCollectOnly: boolean;
}) => {
  return (
    <ContentLayout
      variant="inline.nopad"
      header={
        <HStack alignItems="flex-start" p="0px" justifyContent="space-between">
          <ContentHeader
            title={title}
            subtitle={reference}
            props={{ padding: '0px 0px 8px', width: 'unset' }}
            height="fit-content"
          />

          {isCollectOnly || isStandardMandatory ? (
            <MaterialityStatusBox materiality={materiality} />
          ) : (
            <MaterialityStatusTag
              isAssessed={true}
              isMaterial={materialStandard?.isMaterial}
              isDataGather={materialStandard?.isDataGatheringOnly ?? false}
              isMandatory={isStandardMandatory}
            />
          )}
        </HStack>
      }
    >
      {!!standardAdditionalTypes.length && (
        <Box mt="12px">
          <AdditionalTypesGroup
            tagsWithHelp={standardAdditionalTypes.map((aType) => {
              return {
                reference: aType.reference,
                title: aType.title ?? '',
                helpText: aType.description ?? '',
              };
            })}
          />
        </Box>
      )}
      {hasParent &&
        !isStandardMandatory &&
        (isCollectOnly ? (
          materialStandard?.isMaterial ? (
            <Box mt="8px">
              <Infobox
                status="info"
                title="Collect data"
                closable={false}
                titleProps={{ variant: 'h3' }}
                description="Disclosure requirements in this standard are material for a parent, so it is required to collect data"
              />
            </Box>
          ) : (
            <Box mt="8px">
              <Infobox
                status="neutral"
                title="Not required to collect data"
                closable={false}
                titleProps={{ variant: 'h3' }}
                description="This standard is not material for a parent, so you don't have to collect data. However, you can choose to collect data if you wish"
              />
            </Box>
          )
        ) : (
          <Box mt="20px">
            <ParentInfoBox status={parentMateriality} />
          </Box>
        ))}
      {isStandardMandatory && (
        <Infobox
          status="neutral"
          title="Mandatory"
          description="This topic is mandatory for all companies"
          icon={<LockedIcon color="text.muted" mt="3px" />}
          closable={false}
        />
      )}
    </ContentLayout>
  );
};
