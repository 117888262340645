import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import { Button, DateInput, Infobox, Input, Tag } from 'Atoms';
import {
  CompanyAssessment,
  QuarterEnum_Enum_,
  useCompanyAssessmentsQuery,
  useInvestorsQuery,
} from 'models';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'utils/translation';
import {
  formatDisplayQuarterYear,
  formatQuarterYearToDate,
  getEndQuarterYear,
  getQuarterFromDate,
  quarterValue,
} from 'utils/date';
import { UserAssigner } from 'Organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getInvestorsByReportingPeriods,
  useCompanyAssessmentsMapByReportingPeriod,
  useFlagAndUnlockAssessment,
  useUpsertCompanyAssessment,
} from '../Assessments.hooks';
import { useToast, useCurrentCompanyId } from 'utils/hooks';
import { Typography } from 'Tokens';
import { addYears, format, max } from 'date-fns';
import QuarterPicker from 'containers/Portfolios/pieces/QuarterDateInput';
import AdvancedDateSwitch from 'containers/Portfolios/pieces/AdvancedDateSwitch';
import { useShareAssessmentWithInvestors } from './Assessment.hooks';
import { FormItem } from './AssessmentFormItem';

type AssessmentFields = {
  businessUnits: Array<string>;
  startDate: Date;
  name: string;
  contactPersonId: string;
};
export const AssessmentEditor = ({
  cAssessment,
  reportingPeriod,
}: {
  cAssessment?: CompanyAssessment;
  reportingPeriod?: string;
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { companyId } = useCurrentCompanyId();
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [flagAndUnlockAssessment] = useFlagAndUnlockAssessment();
  const { t } = useTranslation(['assessment', 'common']);
  const { handleSubmit, control, reset, resetField, watch, getValues } = useForm<AssessmentFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      businessUnits: cAssessment?.bAssessments.map((ba) => ba.businessUnit?.id) || [],
      startDate: cAssessment?.startDate || new Date(),
      name: cAssessment?.aggregate.title ?? '',
      contactPersonId: cAssessment?.aggregate.contactPerson?.id,
    },
  });

  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const { data: assessmentsData } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const assessments = useMemo(() => {
    return assessmentsData?.assessments ?? [];
  }, [assessmentsData]);

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  const investorsByReportingPeriod = useMemo(() => {
    return getInvestorsByReportingPeriods(investors, reportingPeriod ?? '');
  }, [investors, reportingPeriod, assessmentsData]);

  const upsertCompanyAssessment = useUpsertCompanyAssessment();
  const shareAssessmentWithInvestors = useShareAssessmentWithInvestors();
  const { assessments: assessmentsMap } = useCompanyAssessmentsMapByReportingPeriod(
    assessments,
    reportingPeriod ? investorsByReportingPeriod : investors
  );

  const [quarter, year] = useMemo(() => {
    return reportingPeriod?.split('-') ?? ['', ''];
  }, [reportingPeriod]);

  const [endQuarter, endYear] = useMemo(() => {
    return getEndQuarterYear(quarter, year);
  }, [quarter, year]);

  const [customPeriodsEnabled, setCustomPeriodEnabled] = useState(false);

  const { errors, isValid, isDirty } = useFormState({ control });

  const assessmentsByReportingPeriod = useMemo(() => {
    return assessmentsMap.get(reportingPeriod ?? '') ?? [];
  }, [assessmentsMap, reportingPeriod]);

  const shouldBeSharedAutomatically = useMemo(() => {
    return cAssessment
      ? assessmentsByReportingPeriod.length <= 1
      : assessmentsByReportingPeriod.length === 0;
  }, [assessmentsByReportingPeriod, cAssessment]);

  const investorsPeriods = useMemo(() => {
    return investors.map((investor) => new Date(investor.year ?? 0, 0, 1));
  }, [investors]);

  const isPortfolioCompany = useMemo(() => !!investors.length, [investors]);

  const isSharedAssessment = useMemo(() => {
    return !!investors.find((inv) => inv.sharedCompanyAssessmentId === cAssessment?.id);
  }, [cAssessment]);

  useEffect(() => {
    setCustomPeriodEnabled(
      () =>
        (!!reportingPeriod && quarter !== 'Q1') ||
        (!!cAssessment && getQuarterFromDate(new Date(cAssessment?.startDate)) !== 'Q1')
    );
  }, [reportingPeriod, quarter, cAssessment]);

  useEffect(() => {
    let startDate: Date;

    if (cAssessment) {
      startDate = new Date(cAssessment.startDate || formatQuarterYearToDate(quarter, year));
      reset({
        businessUnits: cAssessment.bAssessments.map((ba) => ba.businessUnit?.id) || [],
        startDate,
        name: cAssessment.aggregate.title,
        contactPersonId: cAssessment.aggregate.contactPerson?.id,
      });
    } else {
      startDate = reportingPeriod
        ? new Date(formatQuarterYearToDate(quarter, year))
        : investorsPeriods.length
          ? addYears(max(investorsPeriods), 1)
          : new Date(formatQuarterYearToDate(quarter, year));

      reset({
        businessUnits: getValues('businessUnits'),
        startDate,
        name: getValues('name'),
        contactPersonId: getValues('contactPersonId'),
      });
    }

    setSelectedDate(startDate);
  }, [cAssessment, investorsPeriods, customPeriodsEnabled]);

  const onSubmit = (data: AssessmentFields) => {
    setIsSubmitting(true);
    upsertCompanyAssessment(
      {
        id: cAssessment?.id,
        title: data.name,
        startDate: format(
          reportingPeriod ? formatQuarterYearToDate(quarter, year) : data.startDate,
          'yyyy-MM-dd'
        ),
        businessUnits: [
          ...data.businessUnits.map((bu) => ({ id: bu, isDeleted: false })),
          ...(cAssessment?.bAssessments || [])
            .filter((ba) => !data.businessUnits.includes(ba.businessUnit?.id))
            .map((ba) => ({ id: ba.businessUnit?.id, isDeleted: true })),
        ],
        contactPersonId: data.contactPersonId,
      },
      cAssessment
    )
      .then(async (res) => {
        const date = new Date(res.data?.cAssessment?.aggregate.startDate);

        await shareAssessmentWithInvestors(
          date,
          investors,
          reportingPeriod,
          assessmentsMap,
          cAssessment,
          res.data?.cAssessment?.id
        );
        flagAndUnlockAssessment(cAssessment);
        toast({
          text: cAssessment
            ? t('assessment:form.assessmentUpdated')
            : t('assessment:form.assessmentCreated'),
        });
        if (!cAssessment) {
          navigate(`/${companyId}/assessments/${res.data?.cAssessment?.id}`, { replace: true });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="32px" alignItems="stretch" width="808px" pt="16px">
        <VStack spacing="20px" flex="1 0 auto" width="100%" alignItems="stretch">
          <VStack spacing="8px" alignItems="stretch">
            <Typography variant="h2">{t('assessment:details')}</Typography>
            <Divider width="100%" />
          </VStack>

          <Controller
            name="name"
            rules={{
              required: true,
              validate: (value) => value.trim().length !== 0,
            }}
            control={control}
            render={({ field }) => (
              <FormItem
                title={t('common:assessment.assessmentName')}
                isInvalid={!!errors?.name}
                error={t('assessment:form.assessmentInputName')}
                isRequired
                description={t('common:assessment.assessmentNameDescription')}
              >
                <Input
                  size="md"
                  isInvalid={!!errors?.name}
                  onBlur={field.onBlur}
                  value={field.value}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e.currentTarget.value)}
                />
              </FormItem>
            )}
          />
          <Controller
            name="contactPersonId"
            rules={{ required: t('assessment:form.contactPerson.requiredRules') }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormItem
                isRequired
                title={t('common:assessment.assessmentOwner')}
                description={t('common:assessment.assessmentOwnerDescription')}
                error={errors?.contactPersonId?.message ?? ''}
                isInvalid={!!errors?.contactPersonId}
              >
                <UserAssigner assignedTo={value} setAssignedTo={onChange} width="200px" />
              </FormItem>
            )}
          />

          {/* 
              if there is no reporting period, then the assessment is in other group for 
              portfolio company or in a regular company
            */}
          {(!reportingPeriod || !isSharedAssessment) && (
            <FormItem
              isRequired={false}
              title={t('assessment:form.reportingPeriod.title')}
              description={t('assessment:form.reportingPeriod.description')}
            >
              {customPeriodsEnabled ? (
                <QuarterPicker
                  startDateFieldName="startDate"
                  endDateFieldName="endDate"
                  control={control}
                  resetField={resetField}
                  watch={watch}
                  disabled={!!reportingPeriod && (isSharedAssessment || !cAssessment)}
                />
              ) : (
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DateInput
                        value={new Date(field.value)}
                        setValue={(date) => {
                          setSelectedDate(date ?? new Date());
                          field.onChange(date ?? new Date());
                        }}
                        showYearPicker
                        dateFormat="yyyy"
                        excludeDates={cAssessment ? undefined : investorsPeriods}
                        disabled={!!reportingPeriod && (isSharedAssessment || !cAssessment)}
                      />
                    );
                  }}
                />
              )}

              {!(!!reportingPeriod && (isSharedAssessment || !cAssessment)) && (
                <AdvancedDateSwitch
                  isChecked={customPeriodsEnabled}
                  onChange={() => setCustomPeriodEnabled(!customPeriodsEnabled)}
                />
              )}
            </FormItem>
          )}
        </VStack>
        <VStack>
          {isPortfolioCompany && (
            <VStack alignItems="flex-start" width="100%" gap="20px">
              <VStack alignItems="flex-start" width="100%" gap="8px">
                <Typography variant="h2">{t('assessment:stakeholders')}</Typography>
                <Divider width="100%" />
              </VStack>
              {isSharedAssessment && (
                <VStack alignItems="flex-start" gap="12px">
                  <VStack gap="2px" alignItems="flex-start">
                    <Typography variant="bodyStrong">
                      {t('assessment:sharedModal.reportingPeriod')}
                    </Typography>
                    <Typography variant="detail">
                      {t('assessment:sharedModal.reportingPeriodInfo')}
                    </Typography>
                  </VStack>
                  <Tag size="md">
                    {endYear ? (
                      <HStack>
                        <Typography variant="body">
                          {quarterValue[quarter as QuarterEnum_Enum_]}.{year} -
                        </Typography>
                        <Typography variant="body">
                          {endQuarter}.{endYear}
                        </Typography>
                      </HStack>
                    ) : (
                      <HStack>
                        <Typography variant="body">{year} </Typography>
                        <Typography variant="detail">
                          {quarterValue[quarter as QuarterEnum_Enum_]} - {endYear} {endQuarter}
                        </Typography>
                      </HStack>
                    )}
                  </Tag>
                </VStack>
              )}
              <VStack alignItems="flex-start" gap="12px">
                <VStack gap="2px" alignItems="flex-start">
                  <Typography variant="bodyStrong">
                    {t('assessment:assessmentSettings.access')}
                  </Typography>
                  <Typography variant="detail">
                    {t('assessment:assessmentSettings.accessInfo')}
                  </Typography>
                </VStack>
                <Box width="100%">
                  <Infobox
                    closable={false}
                    status="neutral"
                    minHeight="36px"
                    title={
                      !reportingPeriod
                        ? t('assessment:notShared')
                        : shouldBeSharedAutomatically
                          ? t('assessment:sharedAutomatically')
                          : t('assessment:shareAccessManually')
                    }
                    description={
                      !reportingPeriod ? (
                        <Typography>
                          {t('assessment:notSharedWithStakeholdersInfo', {
                            year: selectedDate.getFullYear(),
                          })}
                        </Typography>
                      ) : shouldBeSharedAutomatically ? (
                        <HStack pt="3px" gap={0}>
                          <Typography variant="body">
                            {t('assessment:sharedWithStakeholders')}
                            <strong style={{ fontWeight: 500 }}>
                              {' '}
                              (
                              {investorsByReportingPeriod
                                .map((investor) => investor.portfolio.ownerCompany.name)
                                .join(', ')}
                              )
                            </strong>
                          </Typography>
                        </HStack>
                      ) : (
                        <Typography variant="body">
                          {t('assessment:manualSharingInfo', {
                            period: formatDisplayQuarterYear(reportingPeriod),
                          })}
                        </Typography>
                      )
                    }
                  />
                </Box>
              </VStack>
            </VStack>
          )}
        </VStack>
        <HStack width="100%">
          <Button
            type="submit"
            variant="primary"
            isDisabled={!isDirty || !isValid || isSubmitting}
            isLoading={isSubmitting}
          >
            {cAssessment
              ? t('assessment:structure.department.form.save')
              : t('assessment:structure.department.form.create')}
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};
