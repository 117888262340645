import { useColorMode, HStack, VStack, IconButton } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CalendarIcon, TargetIcon } from 'Tokens/Icons/Data';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { sortBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { MilestoneFields, TargetFields } from '../../Requirement';
import { NA, TargetProgressChart, TotalWithWarning } from './AddTargetUtils';
import { getBUMilestoneTotal, getSubMilestoneTotal } from './Milestones/Milestones.hooks';
import { Table } from 'Molecules';
import { GetReportingUnitsQuery_ } from 'models';
import { Tag } from 'Atoms';
import { SubsidiariesTargetsType } from './AddTargets.hooks';
import { ColumnDef } from '@tanstack/react-table';
import { percentageToNumber } from 'containers/Esrs/utils';

const Year = ({ year, lastRow }: { year: number; lastRow: boolean }) => {
  if (lastRow)
    return (
      <HStack>
        <TargetIcon />
        <VStack spacing="0px" alignItems="start">
          <Typography variant="bodyStrong" as="span">
            {year}
          </Typography>
          <Typography variant="micro">Final target</Typography>
        </VStack>
      </HStack>
    );
  return (
    <HStack>
      <CalendarIcon />
      <Typography variant="bodyStrong" as="span">
        {year}
      </Typography>
    </HStack>
  );
};

const MilestoneValue = ({
  milestone,
  unit,
  localAnswers,
  subsidiariesTargets,
  isGroup,
  reportingUnits,
}: {
  milestone: MilestoneFields;
  unit: string;
  localAnswers: TargetFields;
  subsidiariesTargets: SubsidiariesTargetsType;
  isGroup: boolean;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined;
}) => {
  const { totalGoal, missingBaselines, missingMilestones } = useMemo(
    () =>
      isGroup
        ? getSubMilestoneTotal(subsidiariesTargets, milestone.year)
        : getBUMilestoneTotal(localAnswers, reportingUnits, milestone),
    [isGroup, subsidiariesTargets, milestone, localAnswers, reportingUnits]
  );

  if (localAnswers.isBottomUp) {
    if (totalGoal)
      return (
        <TotalWithWarning
          value={totalGoal}
          unit={unit}
          isGroup={isGroup}
          missingValues={uniq([...missingMilestones, ...missingBaselines])}
        />
      );
    else return <NA />;
  }

  if (milestone.value && !!localAnswers.baseline && localAnswers.baseline !== 0)
    return (
      <Typography variant="body" as="span">
        {percentageToNumber(milestone.value, localAnswers.baseline)}{' '}
        {unit === 't' ? 'tonnes' : unit}
      </Typography>
    );
  return <Tag title="To add" variant="warning" size="xs" />;
};

const Progress = ({
  milestone,
  subsidiariesTargets,
  isGroup,
  requiredLength,
  color,
}: {
  milestone: MilestoneFields;
  subsidiariesTargets: SubsidiariesTargetsType;
  isGroup: boolean;
  requiredLength: number;
  color: 'default' | '_dark';
}) => {
  const subAnswers = useMemo(
    () =>
      subsidiariesTargets?.map(
        (sub) =>
          sub.target?.milestones
            ?.find((m) => m.year === milestone.year)
            ?.milestoneResults?.find((mr) => mr.reportingUnitId === null)?.value
      ),
    [subsidiariesTargets, milestone]
  );
  const subAnswersLength = useMemo(
    () => subAnswers?.filter((val) => !!val && val !== 0)?.length ?? 0,
    [subAnswers]
  );

  const ruAnswersLength = useMemo(
    () =>
      Object.values(milestone?.reportingUnits ?? {})?.filter((val) => !!val && val !== 0)?.length ??
      0,
    [milestone]
  );

  const percentage = useMemo(
    () => ((isGroup ? subAnswersLength : ruAnswersLength) / requiredLength) * 100,
    [isGroup, subAnswersLength, ruAnswersLength, requiredLength]
  );

  return <TargetProgressChart percentage={Math.round(percentage)} color={color} />;
};

export const MilestoneOverview = ({
  setSelectedMilestone,
  onMilestoneOpen,
  localAnswers,
  unit,
  requiredLength = 1,
  isGroup,
  subsidiariesTargets,
  reportingUnits,
  isFlagged,
  parentMilestoneYears,
}: {
  setSelectedMilestone: (param: MilestoneFields | undefined) => void;
  onMilestoneOpen: () => void;
  localAnswers: TargetFields;
  unit: string;
  requiredLength: number;
  isGroup: boolean;
  subsidiariesTargets: SubsidiariesTargetsType;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined;
  isFlagged: boolean;
  parentMilestoneYears: number[];
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const columns: ColumnDef<MilestoneFields>[] = useMemo(() => {
    return [
      {
        header: 'Year',
        id: 'year',
        cell: ({ row }) => (
          <Year
            year={row.original.year}
            lastRow={row.index === localAnswers.milestones.length - 1}
          />
        ),
      },
      {
        header: localAnswers.isBottomUp ? 'Total value' : 'Value',
        id: 'value',
        cell: ({ row }) => (
          <MilestoneValue
            milestone={row.original}
            unit={unit}
            localAnswers={localAnswers}
            subsidiariesTargets={subsidiariesTargets}
            isGroup={isGroup}
            reportingUnits={reportingUnits}
          />
        ),
      },
      {
        header: 'Relative change from the baseline',
        id: 'relative',
        cell: ({ row }) => {
          const { totalMilestones, missingMilestones } = isGroup
            ? getSubMilestoneTotal(subsidiariesTargets, row.original.year)
            : getBUMilestoneTotal(localAnswers, reportingUnits, row.original);

          if (totalMilestones && !!localAnswers.baseline && localAnswers.isBottomUp)
            return (
              <TotalWithWarning
                value={totalMilestones}
                unit={'%'}
                isGroup={isGroup}
                missingValues={missingMilestones}
              />
            );

          if (row.original.value && !!localAnswers.baseline && !localAnswers.isBottomUp)
            return (
              <Typography variant="body" as="span">
                {row.original.value.toFixed(2)} %
              </Typography>
            );
          return <NA />;
        },
      },
      {
        header: 'Data collected',
        id: 'progress',
        cell: ({ row }) => (
          <Progress
            milestone={row.original}
            subsidiariesTargets={subsidiariesTargets}
            isGroup={isGroup}
            requiredLength={requiredLength}
            color={color}
          />
        ),
      },
      {
        header: 'Parent company status',
        id: 'status',
        cell: ({ row }) => {
          const isRequested = parentMilestoneYears.includes(row.original.year);
          if (isRequested) return <Tag title="Requested" variant="info" size="xs" />;
          if (!isRequested && row.original.isRequired)
            return <Tag title="No longer requested" variant="warning" size="xs" />;
          return <Tag title="Not requested" variant="undefined" size="xs" />;
        },
      },
      {
        header: '',
        id: 'arrow',
        meta: {
          width: '5%',
        },
        cell: () => (
          <IconButton aria-label="open" icon={<ArrowNarrowRightIcon />} variant="ghost" />
        ),
      },
    ];
  }, [
    setSelectedMilestone,
    onMilestoneOpen,
    localAnswers,
    unit,
    requiredLength,
    isGroup,
    subsidiariesTargets,
    reportingUnits,
  ]);

  const filteredColumns = useMemo(
    () =>
      columns
        .filter((m) => (localAnswers.isBottomUp ? true : m.id !== 'progress'))
        .filter((m) => (!!localAnswers.parentTargetId ? true : m.id !== 'status')),
    [columns, localAnswers]
  );

  return (
    <Table<MilestoneFields>
      onRowClick={(row) => {
        setSelectedMilestone(row);
        onMilestoneOpen();
      }}
      rowProps={{
        _hover: {
          cursor: 'pointer',
          bg: 'bg.hover',
        },
      }}
      conditionalRowProps={(row) => ({
        bg: isFlagged && parentMilestoneYears.includes(row.year) ? 'bg.warning' : '',
      })}
      bottomRowBorder={false}
      headerHeight="48px"
      headerPadding="0px 8px"
      headerBorderColor="border.decorative"
      cellPadding="0px 8px"
      columns={filteredColumns}
      data={sortBy(localAnswers.milestones, ['year'])}
      withBorder={true}
    />
  );
};
