import { Table as ChakraTable, CircularProgress, HStack, Td, Tr, VStack } from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import {
  useBusinessUnitDRProgress,
  useGroupDRProgress,
  useRegularCompanyDRProgress,
} from 'containers/Esrs/pieces/Assessment/StandardProgress/StandardProgress.hooks';
import { StructureNode } from 'containers/Esrs/pieces/Assessment/Structure';
import { ShortUser, User } from 'models';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CompanyDrProgress = ({ drRef, assessmentId }: { drRef: string; assessmentId: string }) => {
  const { progress } = useRegularCompanyDRProgress({ drRef, assessmentId });
  return (
    <HStack spacing="8px">
      <CircularProgress
        size="16px"
        thickness="12px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="body">{`${progress}%`}</Typography>
    </HStack>
  );
};

const GroupDrProgress = ({ drRef, assessmentId }: { drRef: string; assessmentId: string }) => {
  const { progress } = useGroupDRProgress({ drRef, assessmentId });
  return (
    <HStack spacing="8px">
      <CircularProgress
        size="16px"
        thickness="12px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="body">{`${progress}%`}</Typography>
    </HStack>
  );
};

const BusinessUnitDrProgress = ({
  drRef,
  assessmentId,
}: {
  drRef: string;
  assessmentId: string;
}) => {
  const { progress } = useBusinessUnitDRProgress({ drRef, assessmentId });
  return (
    <HStack spacing="8px">
      <CircularProgress
        size="16px"
        thickness="12px"
        color="text.info"
        value={Number(progress ?? '0')}
      />
      <Typography variant="body">{`${progress}%`}</Typography>
    </HStack>
  );
};

export const DisclosureRequirementTable = ({
  title,
  requirements,
  owner,
  isGroup,
}: {
  title: string;
  requirements: StructureNode[];
  owner?: Partial<User> | null;
  isGroup: boolean;
}) => {
  const { esrsAssessmentId, reportingUnitId } = useParams();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const navigate = useNavigate();
  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);
  const rowStyling = {
    p: '8px',
    borderColor: 'border.decorative',
  };

  const handleRowClick = (drRef: string, category?: string) => {
    if (isBusinessUnit) navigate(`disclosure-requirement/${drRef}`);
    else navigate(`disclosure-requirement/${category}/${drRef}`);
  };

  return (
    <VStack alignItems="start" gap="4px">
      <Typography variant="overline" color="text.hint">
        {title.toUpperCase()}
      </Typography>
      <ChakraTable borderTop="1px solid" borderColor="border.decorative">
        {requirements.map((req, index) => (
          <Tr
            _hover={{ background: 'bg.hover', cursor: 'pointer' }}
            transition="0.16s all"
            onMouseEnter={() => setHoveredRowIndex(index)}
            onMouseLeave={() => setHoveredRowIndex(-1)}
            onClick={() => handleRowClick(req.reference ?? '', req.key?.split('_')[0] ?? '')}
          >
            {/* icon */}
            <Td
              w="28px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <DocumentIcon boxSize="16px" />
            </Td>
            {/* ref */}
            <Td
              w="92px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <TruncatableText variant="body" text={req.reference ?? ''} />
            </Td>
            {/* title */}
            <Td
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <TruncatableText tooltipMaxWidth="400px" variant="bodyStrong" text={req.title} />
            </Td>
            {/* progress */}
            <Td
              w="88px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              {isBusinessUnit ? (
                <BusinessUnitDrProgress
                  drRef={req.reference ?? ''}
                  assessmentId={esrsAssessmentId ?? ''}
                />
              ) : isGroup ? (
                <GroupDrProgress
                  drRef={req.reference ?? ''}
                  assessmentId={esrsAssessmentId ?? ''}
                />
              ) : (
                <CompanyDrProgress
                  drRef={req.reference ?? ''}
                  assessmentId={esrsAssessmentId ?? ''}
                />
              )}
            </Td>
            {/* owner */}
            <Td
              w="64px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <UserAvatar user={owner as ShortUser} size="xs" border="none" />
            </Td>
            {/* action */}
            <Td
              w="54px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <IconButton
                aria-label="side-bar"
                size="sm"
                variant="ghost"
                bg={hoveredRowIndex === index ? 'bg.hover' : ''}
                transition="0.16s all"
                icon={<ArrowNarrowRightIcon />}
                onClick={() => handleRowClick(req.reference ?? '', req.key?.split('_')[0] ?? '')}
              />
            </Td>
          </Tr>
        ))}
      </ChakraTable>
    </VStack>
  );
};
