import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import { useGetDRGroupData } from '../DisclosureRequirements.hooks';
import { DisclosureRequirementHeader } from './StandardPageComponents/DisclosureRequirementHeader';
import { useParams } from 'react-router-dom';
import { useEsrsAssessmentQuery, useGetBuDisclosureRequirementMetricsQuery } from 'models';
import { VStack } from '@chakra-ui/react';
import { DisclosureRequirementTable } from './StandardPageComponents/DisclosureRequirementsTable';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { useMemo } from 'react';

export const StandardPage = () => {
  const { standard, sortedRequirements, loading } = useGetDRGroupData();
  const { esrsAssessmentId, reportingUnitId } = useParams();
  const { companyAssessmentId: companyStandardId, parentAssessmentId: parentStandardId } =
    useMaterialStandardId(standard?.reference ?? '', esrsAssessmentId ?? '');

  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const { data: esrsAssessmentData } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const { data: buData } = useGetBuDisclosureRequirementMetricsQuery({
    variables: {
      standardRef: standard?.reference ?? '',
      companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: !isBusinessUnit,
  });

  const buDisclosureRequirements = useMemo(
    () =>
      [...(buData?.requirements ?? [])].sort((a, b) => {
        if (a.order !== b.order) {
          return a.order - b.order;
        }
        return a.reference.localeCompare(b.reference);
      }),
    [buData]
  );

  if (loading) return <Loader />;

  return (
    <ContentLayout
      backButton
      header={<ContentHeader title={standard?.title} subtitle={standard?.reference} />}
      variant="narrow"
    >
      <VStack alignItems="stretch" gap="28px" mt="24px">
        <DisclosureRequirementHeader
          isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
          owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
        />
        {isBusinessUnit ? (
          <DisclosureRequirementTable
            title={'Metrics'}
            requirements={buDisclosureRequirements}
            owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
            isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
          />
        ) : (
          sortedRequirements.map((requirements) => (
            <DisclosureRequirementTable
              title={requirements[0].title}
              requirements={requirements.slice(1)}
              owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
              isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
            />
          ))
        )}
      </VStack>
    </ContentLayout>
  );
};
