import {
  Box,
  Tag as ChakraTag,
  TagProps as ChakraTagProps,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
} from '@chakra-ui/react';
import { selectedStateStyles } from 'Atoms/SelectedStateStyles';
import { TruncatableText } from 'Atoms/TruncatableText';
import { HelpTooltip } from 'Molecules';
import React from 'react';
import { TagTheme } from './TagTheme';

//
// See tag styles in TagTheme.tsx
//

export const TAG_VARIANTS = Object.keys(TagTheme.variants ?? {});
export type TagVariant = (typeof TAG_VARIANTS)[number];

export const TAG_SIZES = Object.keys(TagTheme.sizes ?? {});
export type TagSize = (typeof TAG_SIZES)[number];

export type TagProps = Omit<ChakraTagProps, 'variant'> & {
  variant?: TagVariant;
  leftIcon?: ChakraTagProps['children'];
  rightIcon?: ChakraTagProps['children'];
  title?: ChakraTagProps['children'];
  size?: TagSize;
  rightIconMargin?: string;
  leftIconMargin?: string;
  helpLabel?: string;
  clickable?: boolean;
  isSelected?: boolean;
  truncatable?: boolean;
  maxWidthInPixels?: number;
  disabled?: boolean;
};

export const Tag = React.forwardRef(
  (
    {
      title,
      size = 'sm',
      variant = 'default',
      rightIconMargin = '0px',
      leftIcon,
      rightIcon,
      maxWidthInPixels,
      isSelected = false,
      truncatable = false,
      clickable = false,
      disabled = false,
      ...props
    }: TagProps,
    ref: React.LegacyRef<HTMLSpanElement>
  ) => {
    const customHoverAndActiveStyles = {
      _hover: clickable ? props._hover : {},
      _active: clickable ? props._active : {},
    };

    return (
      <ChakraTag
        ref={ref}
        size={size}
        variant={variant}
        disabled={disabled}
        {...props}
        {...customHoverAndActiveStyles}
        sx={isSelected ? selectedStateStyles : {}}
      >
        <>
          {leftIcon && (
            <TagLeftIcon
              children={leftIcon}
              boxSize={size === 'xs' ? '12px' : '16px'}
              marginRight={size === 'xs' ? '2px' : '4px'}
              color={props.color}
            />
          )}
          {title ? (
            <TagLabel paddingLeft={leftIcon ? '2px' : '0px'} maxWidth={maxWidthInPixels}>
              {truncatable ? (
                <TruncatableText
                  variant={size === 'xs' ? 'detailStrong' : 'bodyStrong'}
                  color="currentColor"
                  text={title}
                  maxTextWidth={maxWidthInPixels}
                />
              ) : (
                title
              )}
            </TagLabel>
          ) : (
            props.children
          )}
          {rightIcon && (
            <TagRightIcon
              children={rightIcon}
              boxSize={size === 'xs' ? '12px' : '16px'}
              marginLeft={size === 'xs' ? '2px' : '4px'}
              color={props.color}
            />
          )}
          {props.helpLabel && (
            <Box marginLeft={rightIconMargin}>
              <HelpTooltip label={props.helpLabel} />
            </Box>
          )}
        </>
      </ChakraTag>
    );
  }
);

export const AdditionalTypeTag = ({
  label,
  helpText,
  maxWidthInPixels,
}: {
  label: string;
  helpText: string;
  maxWidthInPixels?: number;
}) => {
  return (
    <Tag helpLabel={helpText} maxWidth={maxWidthInPixels}>
      <TruncatableText text={label} maxTextWidth={maxWidthInPixels}></TruncatableText>
    </Tag>
  );
};
