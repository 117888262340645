import { HStack, IconButton, VStack } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowLeftIcon, ArrowRightIcon } from 'Tokens/Icons/Direction';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type EsrsDrSwitch = {
  title: string;
  reference?: string;
  category: string;
};

export const DrBottomNavigation = ({
  drIndex,
  nextLink,
  prevLink,
  disclosureRequirements,
}: {
  drIndex: number;
  nextLink?: string;
  prevLink?: string;
  disclosureRequirements?: EsrsDrSwitch[];
}) => {
  const navigate = useNavigate();

  const nextDr = useMemo(() => disclosureRequirements?.[drIndex + 1], [disclosureRequirements]);
  const prevDr = useMemo(() => disclosureRequirements?.[drIndex - 1], [disclosureRequirements]);

  return (
    <HStack justifyContent="space-between" w="100%" pt="32px" pb="48px">
      <HStack w="45%">
        {drIndex > 0 && (
          <HStack gap="4px">
            <IconButton
              aria-label="prev-dr"
              variant="ghost"
              size="sm"
              icon={<ArrowLeftIcon />}
              onClick={() => prevLink && navigate(prevLink)}
            />
            <VStack alignItems="flex-start" gap="0px">
              <Typography variant="detail">Previous DR</Typography>
              <TruncatableText
                variant="bodyStrong"
                text={prevDr?.title ?? ''}
                tooltipMaxWidth="400px"
              />
            </VStack>
          </HStack>
        )}
      </HStack>
      <HStack w="45%" justifyContent="flex-end">
        {drIndex < (disclosureRequirements?.length ?? 1) - 1 && (
          <HStack gap="4px">
            <VStack alignItems="flex-end" gap="0px">
              <Typography variant="detail">Next DR</Typography>
              <TruncatableText
                variant="bodyStrong"
                text={nextDr?.title ?? ''}
                tooltipMaxWidth="400px"
              />
            </VStack>
            <IconButton
              aria-label="next-dr"
              variant="ghost"
              size="sm"
              icon={<ArrowRightIcon />}
              onClick={() => nextLink && navigate(nextLink)}
            />
          </HStack>
        )}
      </HStack>
    </HStack>
  );
};
