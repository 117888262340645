import {
  InputGroup,
  InputLeftElement,
  useMultiStyleConfig,
  InputProps,
  Box,
} from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import React from 'react';
import './DateInput.css';
import { CalendarIcon } from 'Tokens/Icons/Data';

const TypedDatePicker = DatePicker as unknown as React.FC<ReactDatePickerProps>;

export type DateInputProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  value: Date;
  setValue: (val: Date | null) => void;
  isBorderless?: boolean;
  attachToId?: string;
  renderQuarterContent?: (quarter: number, shortQuarter: string) => React.ReactNode;
} & Omit<InputProps, 'value'>;

export type StartToEndDateInputProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  startDate: Date;
  endDate: Date;
  setStartDate: (val: Date | null) => void;
  setEndDate: (val: Date | null) => void;
  isBorderless?: boolean;
  attachToId?: string;
} & Omit<InputProps, 'value'>;

export function DateInput({
  value,
  setValue,
  onChange: _onChange,
  width,
  isBorderless = false,
  attachToId = 'root',
  ...props
}: DateInputProps) {
  const styles = useMultiStyleConfig('Input', props);
  return (
    <InputGroup
      sx={{
        input: {
          ...styles.field,
          paddingLeft: '32px',
          width: width ?? 'fit-content',
          border: isBorderless ? 'none' : '',
          _focus: isBorderless
            ? {
                border: '1px solid',
                borderColor: 'border.selected.accent',
                borderRadius: '4px',
              }
            : {},
        },
      }}
      size="md"
      width={width ?? '100%'}
      zIndex={1}
    >
      <InputLeftElement
        pointerEvents="none"
        color="text.hint"
        fontSize="14px"
        height={props.size === 'md' ? '24px' : '34px'}
        width="22px"
        pl="10px"
      >
        <CalendarIcon />
      </InputLeftElement>
      <TypedDatePicker
        selected={new Date(value)}
        onChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        onMonthChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        onYearChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        className="chakra-input"
        dateFormat="yyyy-MM-dd"
        placeholderText="2022"
        autoComplete="on"
        popperContainer={(popper) => <Box zIndex={9999} children={popper.children} />}
        portalId={attachToId}
        {...props}
      />
    </InputGroup>
  );
}

export function StartToEndDateInput({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onChange: _onChange,
  width,
  attachToId,
  ...props
}: StartToEndDateInputProps) {
  const styles = useMultiStyleConfig('Input', props);
  return (
    <InputGroup
      sx={{
        input: {
          ...styles.field,
          paddingLeft: '32px',
          width: '308px',
          h: '38px',
        },
      }}
      size="md"
      width="308px"
      zIndex="1"
    >
      <InputLeftElement
        pointerEvents="none"
        color="text.hint"
        fontSize="14px"
        height={props.size === 'md' ? '24px' : '34px'}
        width="22px"
        pl="10px"
      >
        <CalendarIcon />
      </InputLeftElement>
      <DatePicker
        selected={new Date(startDate)}
        selectsRange
        onChange={(dates) => {
          const [start, end] = dates;
          setStartDate(Number.isNaN(start) ? null : start);
          setEndDate(Number.isNaN(end) ? null : end);
        }}
        startDate={startDate}
        endDate={endDate}
        className="chakra-input"
        dateFormat="yyyy-MM-dd"
        placeholderText="2023"
        autoComplete="on"
        popperContainer={(popper) => <Box zIndex={9999} children={popper.children} />}
        portalId={attachToId}
        {...props}
      />
    </InputGroup>
  );
}
