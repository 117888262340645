import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { BackToTopButton } from 'Atoms/BackToTopButton';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GeneratedSummaryStatus } from '../../AggregatedMetrics';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import {
  EsrsAssessmentProjectLeaderQuery_,
  GetMetricsDrQuery_,
  QuestionType_Enum_,
  useGetMetricAnswersPerDrQuery,
} from 'models';
import { useCurrentCompany } from 'utils/hooks';
import { ClickableReportAnswer } from './ReportClickableAnswers/ClickableReportAnswer';
import { AssessableMetrics } from '../../Metrics';
import { MetricSidebar } from '../../MetricSidebar';
import { DREnums, TimePeriodsEnums } from '../../../Requirement';
import { MetricsTableData, filterMetricLevels, useGetAggregatedMetrics } from '../..';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { OptOutModal } from '../../OptOutModal';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { DrBottomNavigation } from './DrBottomNavigation';
import { getDisclosureRequirementLink } from 'utils/links';
import { EsrsStructure } from '../DisclosureRequirement.utils';
import { NarrativeReportAnswer } from './ReportClickableAnswers/NarrativeReportAnswer';
import { orderBy, uniqBy } from 'lodash';
import { Loader } from 'Molecules';

export const EditableReport = ({
  isLoading,
  companyLevelReportingUnitId,
  companyAssessmentId,
  isGroup,
  metricDR,
  standards,
  metrics,
  projectLeader,
}: {
  isLoading: boolean;
  companyLevelReportingUnitId: string;
  companyAssessmentId: string;
  isGroup: boolean;
  metricDR: GetMetricsDrQuery_['DisclosureRequirement_by_pk'];
  metrics: AssessableMetrics;
  standards: EsrsStructure['standards'];
  projectLeader:
    | NonNullable<EsrsAssessmentProjectLeaderQuery_['esrsAssessment']>['projectLeader']
    | undefined;
}) => {
  const reportRef = useRef<HTMLDivElement>(null);
  const company = useCurrentCompany();
  const [selectedMetric, setSelectedMetric] = useState<AssessableMetrics[number] | undefined>();
  const [rowData, setRowData] = useState<MetricsTableData>();
  const [sidebarMetric, setSidebarMetric] = useState<SelectedMetric>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
    drType = '',
  } = useParams();
  const [generatedSummaryStatus, setGeneratedSummaryStatus] = useState<GeneratedSummaryStatus>();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const handleScroll = useCallback(() => {
    setShowBackToTop((reportRef.current?.scrollTop ?? 0) > 0);
  }, []);

  const { allAggregatedMetrics } = useGetAggregatedMetrics(
    esrsAssessmentId,
    metrics ?? [],
    standardRef,
    companyAssessmentId
  );

  const isTargetOrAction = useMemo(
    () => metricDR?.type === 'action' || metricDR?.type === 'target',
    [metricDR]
  );
  const filteredMetrics = useMemo(() => {
    if (metrics) {
      const filteredLevels = filterMetricLevels(metrics, companyAssessmentId);
      return uniqBy(
        orderBy(
          filteredLevels,
          [
            (metric) => metric.globalOrder === null,
            (metric) => metric.globalOrder,
            (metric) => metric.order,
          ],
          ['asc', 'asc', 'asc']
        ),
        'reference'
      );
    }
    return [];
  }, [metrics]);

  const [withAssociationMetrics, withoutAssociationMetrics] = useMemo(() => {
    return [
      filteredMetrics.filter((metric) => metric.hasAssociation === true) ?? [],
      filteredMetrics.filter((metric) => metric.hasAssociation === false) ?? [],
    ];
  }, [filteredMetrics]);

  const { data: answersData } = useGetMetricAnswersPerDrQuery({
    variables: {
      reportingUnitId: companyLevelReportingUnitId,
      drRef: disclosureRequirementRef,
    },
  });

  const drTypeName = useMemo(
    () => (metricDR?.type === 'target' ? 'targets' : 'actions'),
    [metricDR]
  );

  const answersMap = useMemo(() => {
    const map = new Map();
    answersData?.esrs_Answer.forEach((ans) => {
      map.set(ans.metricRef, ans.datapoints.find((dp) => !!dp.value)?.value);
    });
    return map;
  }, [answersData]);

  const standard = useMemo(
    () => standards.find((std) => std.reference === standardRef),
    [standards]
  );

  const currentDrIndex = useMemo(
    () =>
      standard?.disclosureRequirementRefs.findIndex(
        (req) => req.reference === metricDR?.reference
      ) ?? 0,
    [standard, metricDR]
  );

  const [standardName, showStandardName] = useMemo(() => {
    const drIndex = standard?.disclosureRequirementRefs.findIndex(
      (dr) => dr.reference === disclosureRequirementRef
    );
    return [standard?.title, drIndex === 0];
  }, [standard, standardRef]);

  const [categoryName, showCategoryName] = useMemo(() => {
    const standardIndex = standards
      .filter((std) => std.category === standard?.category)
      .findIndex((std) => std.reference === standardRef);
    return [standard?.category, standardIndex === 0 && currentDrIndex === 0];
  }, [standards, standardRef]);

  const [prevDrLink, nextDrLink] = useMemo(() => {
    return [
      getDisclosureRequirementLink({
        companyId: companyId,
        assessmentId: esrsAssessmentId,
        standardRef,
        disclosureRequirement: {
          reference: standard?.disclosureRequirementRefs[currentDrIndex - 1]?.reference ?? '',
          type: standard?.disclosureRequirementRefs[currentDrIndex - 1]?.type ?? '',
        },
      }),
      getDisclosureRequirementLink({
        companyId: companyId,
        assessmentId: esrsAssessmentId,
        standardRef,
        disclosureRequirement: {
          reference: standard?.disclosureRequirementRefs[currentDrIndex + 1]?.reference ?? '',
          type: standard?.disclosureRequirementRefs[currentDrIndex + 1]?.type ?? '',
        },
      }),
    ];
  }, [standard, currentDrIndex]);

  useEffect(() => {
    if (!rowData) setSelectedMetric(undefined);
  }, [rowData]);

  return (
    <HStack>
      <VStack
        key={metricDR?.reference}
        overflowY="scroll"
        height="100%"
        flex={1}
        alignItems="stretch"
        w="100%"
        pt="16px"
        onScroll={handleScroll}
        paddingInline="8px"
        bg="bg.muted"
        css={{
          '&::-webkit-scrollbar': {
            width: '0',
          },
          scrollbarWidth: 'none',
        }}
        ref={reportRef}
      >
        <VStack alignItems="stretch" margin="auto" maxW="780px">
          <VStack
            minW="712px"
            w="780px"
            maxWidth="780px"
            p="40px 48px"
            margin="auto"
            shadow="interactive.accent"
            background="bg.elevated.interactive"
            alignItems="start"
            borderRadius="12px"
            gap="16px"
            minH=" 1200px"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {showBackToTop && <BackToTopButton containerRef={reportRef} />}
                <VStack gap="16px" alignItems="start">
                  {showCategoryName && <Typography variant="h1">{categoryName}</Typography>}
                  {showStandardName && <Typography variant="h2">{standardName}</Typography>}
                  <Typography variant="h3">
                    {metricDR?.reference} {metricDR?.title}
                  </Typography>
                </VStack>
                {!isTargetOrAction ? (
                  filteredMetrics?.map((m) => {
                    const aggregatedMetric = allAggregatedMetrics.find(
                      (met) => met.metric.reference === m.reference
                    );
                    if (m.metricType !== QuestionType_Enum_.Decimal_ && !m.parentMetrics.length) {
                      return (
                        <NarrativeReportAnswer
                          metric={m}
                          answersMap={answersMap}
                          aggregatedMetrics={allAggregatedMetrics}
                          textAnswer={answersMap.get(m.reference)}
                          materialStandardId={companyAssessmentId}
                          companyLevelReportingUnitId={companyLevelReportingUnitId}
                          currency={company.company?.currency}
                          selectedMetric={selectedMetric}
                          projectLeader={projectLeader ?? undefined}
                          setSelectedMetric={setSelectedMetric}
                          rowData={rowData}
                          setRowData={setRowData}
                        />
                      );
                    }
                    return (
                      m.metricType === QuestionType_Enum_.Decimal_ && (
                        <ClickableReportAnswer
                          metric={m}
                          aggregatedMetric={aggregatedMetric}
                          materialStandardId={companyAssessmentId}
                          companyLevelReportingUnitId={companyLevelReportingUnitId}
                          currency={company.company?.currency}
                          projectLeader={projectLeader ?? undefined}
                          setSelectedMetric={setSelectedMetric}
                          setRowData={setRowData}
                          rowData={rowData}
                          selectedMetric={selectedMetric}
                        />
                      )
                    );
                  })
                ) : (
                  <VStack w="100%" alignItems="stretch" gap="24px">
                    {!!withAssociationMetrics.length && (
                      <VStack w="100%" alignItems="start">
                        <Typography variant="h3">
                          Disclosures per standard if {drTypeName} are set
                        </Typography>
                        {withAssociationMetrics?.map((m) => {
                          const aggregatedMetric = allAggregatedMetrics.find(
                            (met) => met.metric.reference === m.reference
                          );
                          if (
                            m.metricType !== QuestionType_Enum_.Decimal_ &&
                            !m.parentMetrics.length
                          ) {
                            return (
                              <NarrativeReportAnswer
                                metric={m}
                                answersMap={answersMap}
                                aggregatedMetrics={allAggregatedMetrics}
                                textAnswer={answersMap.get(m.reference)}
                                materialStandardId={companyAssessmentId}
                                companyLevelReportingUnitId={companyLevelReportingUnitId}
                                currency={company.company?.currency}
                                selectedMetric={selectedMetric}
                                projectLeader={projectLeader ?? undefined}
                                setSelectedMetric={setSelectedMetric}
                                rowData={rowData}
                                setRowData={setRowData}
                              />
                            );
                          }
                          return (
                            m.metricType === QuestionType_Enum_.Decimal_ && (
                              <ClickableReportAnswer
                                metric={m}
                                aggregatedMetric={aggregatedMetric}
                                materialStandardId={companyAssessmentId}
                                companyLevelReportingUnitId={companyLevelReportingUnitId}
                                currency={company.company?.currency}
                                projectLeader={projectLeader ?? undefined}
                                setSelectedMetric={setSelectedMetric}
                                setRowData={setRowData}
                                rowData={rowData}
                                selectedMetric={selectedMetric}
                              />
                            )
                          );
                        })}
                      </VStack>
                    )}
                    {!!withoutAssociationMetrics.length && (
                      <VStack w="100%" alignItems="start">
                        <Typography variant="h3">
                          Disclosures per standard if {drTypeName} are not set
                        </Typography>
                        {withoutAssociationMetrics?.map((m) => {
                          const aggregatedMetric = allAggregatedMetrics.find(
                            (met) => met.metric.reference === m.reference
                          );
                          if (
                            m.metricType !== QuestionType_Enum_.Decimal_ &&
                            !m.parentMetrics.length
                          ) {
                            return (
                              <NarrativeReportAnswer
                                metric={m}
                                answersMap={answersMap}
                                aggregatedMetrics={allAggregatedMetrics}
                                textAnswer={answersMap.get(m.reference)}
                                materialStandardId={companyAssessmentId}
                                companyLevelReportingUnitId={companyLevelReportingUnitId}
                                currency={company.company?.currency}
                                selectedMetric={selectedMetric}
                                projectLeader={projectLeader ?? undefined}
                                setSelectedMetric={setSelectedMetric}
                                rowData={rowData}
                                setRowData={setRowData}
                              />
                            );
                          }
                          return (
                            m.metricType === QuestionType_Enum_.Decimal_ && (
                              <ClickableReportAnswer
                                metric={m}
                                aggregatedMetric={aggregatedMetric}
                                materialStandardId={companyAssessmentId}
                                companyLevelReportingUnitId={companyLevelReportingUnitId}
                                currency={company.company?.currency}
                                projectLeader={projectLeader ?? undefined}
                                setSelectedMetric={setSelectedMetric}
                                setRowData={setRowData}
                                rowData={rowData}
                                selectedMetric={selectedMetric}
                              />
                            )
                          );
                        })}
                      </VStack>
                    )}
                  </VStack>
                )}
              </>
            )}
          </VStack>
          <DrBottomNavigation
            disclosureRequirements={
              standard?.disclosureRequirementRefs.map((req) => ({
                title: req.title,
                reference: req.reference,
                category: standard.category,
              })) ?? []
            }
            drIndex={currentDrIndex}
            nextLink={nextDrLink}
            prevLink={prevDrLink}
          />
        </VStack>
      </VStack>
      {rowData && (
        <Box w="330px">
          <OptOutModal
            isOpen={isOpen}
            onClose={onClose}
            assessmentId={esrsAssessmentId}
            reportingUnitId={companyLevelReportingUnitId}
            metricRef={selectedMetric?.reference ?? ''}
            isCompany={false}
          />
          <Box
            position="fixed"
            top={`${TOP_MENU_HEIGHT}`}
            right="0px"
            width="352px"
            height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
            bg="bg.default"
            overflowY="auto"
          >
            <MetricSidebar
              row={rowData}
              companyCurrency={company.company?.currency ?? ''}
              companyReportingUnit={companyLevelReportingUnitId}
              isGroup={isGroup}
              esrsAssessmentProjectLeader={projectLeader ?? undefined}
              metric={rowData.metric}
              materialStandardId={companyAssessmentId}
              selectedQuarter={TimePeriodsEnums.year}
              selectedMetric={sidebarMetric}
              setSelectedMetric={setSidebarMetric}
              onMenuOpen={onOpen}
              setRowData={setRowData}
              isOpenedFromAI
              isMetricDr={drType === DREnums.metric}
              generatedSummaryStatus={generatedSummaryStatus}
              setGeneratedSummaryStatus={setGeneratedSummaryStatus}
            />
          </Box>
        </Box>
      )}
    </HStack>
  );
};
