import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { Tag, VisibleIf } from 'Atoms';
import { useMemo } from 'react';
import { Typography } from 'Tokens';
import { AlignedIcon, MinimumSocialSafeguardsIcon, NotAlignedIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

export enum EntityAlignmentStatus {
  todo = 'todo',
  compliant = 'compliant',
  soFarCompliant = 'soFarCompliant',
  notCompliant = 'notCompliant',
  partiallyCompliant = 'partiallyCompliant',
  soFarPartiallyCompliant = 'soFarPartiallyCompliant',
  soFarNoSubstantialContribution = 'soFarNoSubstantialContribution',
  noSubstantialContribution = 'noSubstantialContribution',
  adaptationOnlyCompliant = 'adaptationOnlyCompliant',
  nonEnablingAdaptationOnlyCompliant = 'nonEnablingAdaptationOnlyCompliant',
}

export enum AlignmentType {
  mss = 'minimum social safeguards',
  activities = 'activities',
}
export enum AlignmentStatusVariant {
  tag = 'tag',
  icon = 'icon',
}

export type AlignmentStatusItem = {
  type: AlignmentType;
  isInherited?: boolean;
  status: EntityAlignmentStatus;
  variant?: 'tag' | 'icon';
};

const STATUS_TO_TAG_VARIANT = {
  [EntityAlignmentStatus.todo]: 'undefined',
  [EntityAlignmentStatus.compliant]: 'compliant',
  [EntityAlignmentStatus.soFarCompliant]: 'compliant',
  [EntityAlignmentStatus.adaptationOnlyCompliant]: 'compliant',
  [EntityAlignmentStatus.nonEnablingAdaptationOnlyCompliant]: 'compliant',
  [EntityAlignmentStatus.notCompliant]: 'notCompliant',
  [EntityAlignmentStatus.partiallyCompliant]: 'warning',
  [EntityAlignmentStatus.soFarPartiallyCompliant]: 'warning',
  [EntityAlignmentStatus.soFarNoSubstantialContribution]: 'notCompliant',
  [EntityAlignmentStatus.noSubstantialContribution]: 'notCompliant',
};

export const AlignmentStatus = ({
  type,
  status,
  isInherited,
  variant = 'icon',
}: AlignmentStatusItem) => {
  const { t } = useTranslation('assessment');
  const getIcon = (): React.ReactNode => {
    if (type == AlignmentType.mss)
      return <MinimumSocialSafeguardsIcon boxSize="12px" color="inherit" />;
    if (
      [
        EntityAlignmentStatus.notCompliant,
        EntityAlignmentStatus.soFarNoSubstantialContribution,
        EntityAlignmentStatus.noSubstantialContribution,
      ].includes(status)
    ) {
      return <NotAlignedIcon boxSize="12px" color="inherit" />;
    }
    return <AlignedIcon boxSize="12px" color="inherit" />;
  };

  const label = useMemo(() => {
    if (type == AlignmentType.mss) {
      return t(`assessment:tooltip.mss.${status}`);
    }
    return t(`assessment:tooltip.activities.${status}`);
  }, [type, status]);

  return (
    <Tooltip label={label} placement="right">
      <Tag size="xs" variant={STATUS_TO_TAG_VARIANT[status]}>
        <VisibleIf condition={variant === AlignmentStatusVariant.icon}>
          {isInherited ? (
            <HStack spacing="2px" width="100%" padding="0px" maxH="20px">
              <Box height="100%" alignItems="center" justifyContent="center" display="flex">
                {getIcon()}
              </Box>
              <Typography variant="microStrong" color="notCompliant">
                {t('assessment:inherited')}
              </Typography>
            </HStack>
          ) : (
            <Box height="100%" alignItems="center" justifyContent="center" display="flex">
              {getIcon()}
            </Box>
          )}
        </VisibleIf>
        <VisibleIf condition={variant === AlignmentStatusVariant.tag}>
          <HStack spacing="2px" width="100%" padding="0px" maxH="20px">
            <Box height="100%" alignItems="center" justifyContent="center" display="flex">
              {getIcon()}
            </Box>
            <Typography variant="microStrong" color="notCompliant">
              {t(`assessment:status.${status}`)}
            </Typography>
          </HStack>
        </VisibleIf>
      </Tag>
    </Tooltip>
  );
};
