import { VStack, TableContainer, Box, HStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyState, Tag } from 'Atoms';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { ContentHeader, ContentLayout, Table } from 'Molecules';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { CompanyBothIcon, CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useCompanyType } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import { MaterialMap, MaterialStandard } from './MaterialityAssessment.d';
import { useGetMaterialityAssessment } from './MaterialityAssessment.hooks';
import { MaterialityAssessmentHeader } from './MaterialitySettingsHeader';

const getMaterialStandards = (materialityAssessments: MaterialMap[]): MaterialStandard[] => {
  return materialityAssessments.flatMap((mAssessment) =>
    mAssessment.materialStandards.filter((materialStandard) => {
      const hasMaterialMetrics = materialStandard.metrics.some((materialMetric) =>
        isMetricStateMaterial(materialMetric.isMaterial)
      );
      const hasParentMaterialMetrics = materialStandard.parentCompanyMetrics.some(
        (materialMetric) =>
          materialMetric.isMaterial &&
          materialMetric?.dataCollection === DataCollectionLevel.subsidiaries
      );

      const isStandardMaterial = materialStandard.isMaterial && hasMaterialMetrics;
      const isParentStandardMaterial =
        materialStandard.isParentMaterial && hasParentMaterialMetrics;

      return !!materialStandard.id && (isStandardMaterial || isParentStandardMaterial);
    })
  );
};

const checkMetricsLevel = (
  level: DataCollectionLevel,
  metrics: MaterialStandard['metrics']
): boolean => {
  return metrics.every((metric) => metric?.dataCollection === level);
};

const getRelevantMaterialMetrics = (row: MaterialStandard) => {
  const parentMetrics = row.isParentMaterial ? row.parentCompanyMetrics : [];

  const metrics = row.metrics.filter((m) => {
    const parentMetric = parentMetrics.find((pm) => pm.metricRef === m.metricRef);
    const isMaterialForParent =
      parentMetric?.isMaterial && parentMetric?.dataCollection === DataCollectionLevel.subsidiaries;
    return (isMaterialForParent || m.isMaterial) && m.metric?.isAssessable === true;
  });

  return metrics;
};

export const IconWithText = ({ icon, text }: { icon: React.ReactNode; text: string }) => (
  <HStack>
    {icon}
    <Typography variant="body">{text}</Typography>
  </HStack>
);

export const DataGatheringLevelStatus = ({
  isGroup,
  metrics,
}: {
  isGroup: boolean;
  metrics: MaterialStandard['metrics'];
}) => {
  if (isGroup) {
    if (checkMetricsLevel(DataCollectionLevel.group, metrics))
      return (
        <IconWithText icon={<CompanyTopDownIcon color="text.default" />} text="Parent company" />
      );
    else if (checkMetricsLevel(DataCollectionLevel.subsidiaries, metrics))
      return (
        <IconWithText icon={<CompanyBottomUpIcon color="text.default" />} text="Subsidiaries" />
      );
    return <IconWithText icon={<CompanyBothIcon color="text.default" />} text="Mixed" />;
  } else {
    if (checkMetricsLevel(DataCollectionLevel.company, metrics))
      return (
        <IconWithText icon={<CompanyTopDownIcon color="text.default" />} text="Company level" />
      );
    else if (checkMetricsLevel(DataCollectionLevel.reportingUnits, metrics))
      return (
        <IconWithText icon={<CompanyBottomUpIcon color="text.default" />} text="Business units" />
      );
    return <IconWithText icon={<CompanyBothIcon color="text.default" />} text="Mixed" />;
  }
};

export const DataCollectionSetupStatusTag = ({ isDataCollected }: { isDataCollected: boolean }) => {
  return (
    <Tag
      title={isDataCollected ? 'Done' : 'To setup'}
      variant={isDataCollected ? 'success' : 'warning'}
      borderRadius="6px"
    />
  );
};

const DataCollectionStandardHeader = ({ name, reference }: { name: string; reference: string }) => {
  return (
    <VStack spacing={0} alignItems="start">
      <Typography variant="bodyStrong">{name}</Typography>
      <Typography variant="micro">{reference}</Typography>
    </VStack>
  );
};

export const DataCollectionSetup = ({
  isOnboarding,
  onChangeTab,
  setStep,
}: {
  isOnboarding: boolean;
  onChangeTab?: ((tabId: string) => void) | null;
  setStep?: (step: number) => void;
}) => {
  const { esrsAssessmentId } = useParams();
  const { materialityAssessments, loading } = useGetMaterialityAssessment(esrsAssessmentId);
  const { isGroup } = useCompanyType();

  const navigate = useNavigate();

  const materialStandards = useMemo(() => {
    return getMaterialStandards(materialityAssessments);
  }, [materialityAssessments]);

  const columns: ColumnDef<MaterialStandard>[] = useMemo(() => {
    return [
      {
        header: 'Standard',
        accessorKey: 'standardName',
        enableSorting: false,
        cell: ({ row }) => {
          const { standardName, standardRef } = row.original;
          return <DataCollectionStandardHeader name={standardName} reference={standardRef} />;
        },
        meta: {
          width: '60%',
        },
      },
      {
        header: 'Data gathering level',
        accessorKey: 'dataGathering',
        enableSorting: false,
        cell: ({ row }) => {
          const metrics = getRelevantMaterialMetrics(row.original);
          return <DataGatheringLevelStatus isGroup={isGroup} metrics={metrics} />;
        },
        meta: {
          width: '20%',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) => {
          const isDataCollected = row.original.isDataCollected;
          return <DataCollectionSetupStatusTag isDataCollected={isDataCollected} />;
        },
        meta: {
          width: '20%',
        },
      },
    ];
  }, [materialStandards, isGroup]);

  return (
    <ContentLayout
      variant="inline.nopad"
      header={
        isOnboarding && (
          <ContentHeader
            title="Data collection setup"
            subtitle="Select which metrics you want to track and how"
          />
        )
      }
      height={isOnboarding ? 'fit-content' : undefined}
      isLoading={loading}
    >
      {!!materialStandards.length ? (
        <VStack mt={isOnboarding ? '32px' : '28px'} w="100%" spacing="24px">
          {!isOnboarding && (
            <MaterialityAssessmentHeader title="Manage data collection setup" noDownload={true} />
          )}

          <TableContainer
            border="1px solid"
            borderRadius="8px"
            borderColor="border.decorative"
            w="100%"
          >
            <Typography pt="12px" pl="1.5rem" variant="h3">
              Your material topics
            </Typography>
            <Table
              columns={columns}
              data={materialStandards}
              onRowClick={(row) => {
                navigate(`data-collection/${row.standardRef}`);
              }}
              rowProps={{
                _hover: {
                  cursor: 'pointer',
                  bg: 'bg.hover',
                },
              }}
              rowIcon={<ArrowNarrowRightIcon />}
              bottomRowBorder={false}
            />
          </TableContainer>
        </VStack>
      ) : (
        <Box my="16px" w="100%" flexGrow="1">
          <EmptyState
            title="Set up your data collection"
            description="There are no material metrics that could be set up available. Assess some metrics as material, and you will be able to configure here how they should be gathered "
            callToAction={{
              text: 'Go to materiality assessment',
              variant: 'secondary',
              onClick: () => (isOnboarding ? setStep?.(3) : onChangeTab?.('materiality')),
            }}
            component={true}
          />
        </Box>
      )}
    </ContentLayout>
  );
};
