import { ReportMetricTable } from 'containers/Esrs/pieces/Report/ReportComponents/ReportMetricTable';
import { AssessableMetrics } from '../../../Metrics';
import { Typography } from 'Tokens';
import { QuestionType_Enum_, ShortUser } from 'models';
import { AggregatedMetricsTableData } from '../../../AggregatedMetrics';
import { VStack } from '@chakra-ui/react';
import { Dispatch, MouseEvent, SetStateAction, useMemo, useState } from 'react';
import { MetricsTableData } from '../../..';
import { AnswerPopover } from './AnswerPopover';
import { useGetDatapointValues } from '../../../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';

export const ClickableReportAnswer = ({
  metric,
  aggregatedMetric,
  companyLevelReportingUnitId,
  currency,
  materialStandardId,
  projectLeader,
  padding = 24,
  rowData,
  selectedMetric,
  setSelectedMetric,
  setRowData,
}: {
  metric: AssessableMetrics[number];
  aggregatedMetric?: AggregatedMetricsTableData;
  companyLevelReportingUnitId?: string;
  currency?: string;
  projectLeader?: ShortUser;
  rowData?: MetricsTableData;
  materialStandardId: string;
  padding?: number;
  selectedMetric?: AssessableMetrics[number] | null;
  setSelectedMetric: (metric: AssessableMetrics[number]) => void;
  setRowData: Dispatch<SetStateAction<MetricsTableData | undefined>>;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { answer } = useGetDatapointValues(
    {
      metric,
    },
    companyLevelReportingUnitId ?? ''
  );
  const isDecimal = useMemo(() => metric.metricType === QuestionType_Enum_.Decimal_, [metric]);

  const isSelected = useMemo(
    () => selectedMetric?.reference === metric.reference,
    [selectedMetric, metric]
  );

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setSelectedMetric(metric);
    if (rowData === aggregatedMetric) setRowData(undefined);
    else setRowData(aggregatedMetric ?? { metric });
  };

  return (
    <VStack
      alignItems="stretch"
      width={`calc(100% + ${padding}px)`}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
      ml={`-${padding}px`}
      paddingLeft={`${padding}px`}
      _before={{
        content: `''`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        opacity: isHovered || isSelected ? 1 : 0,
        width: '2px',
        backgroundColor: 'border.selected.accent',
        transition: 'opacity 0.3s',
      }}
      boxSizing="border-box"
      borderColor="border.selected.accent"
      paddingY="4px"
      bg={isSelected ? 'bg.selected' : ''}
      transition="all 0.4s"
      onClick={handleClick}
    >
      <AnswerPopover
        metric={metric}
        owner={answer?.datapoints?.[0]?.owner ?? projectLeader}
        isVisible={isHovered}
        padding={24}
      />
      {isDecimal && <Typography variant="bodyStrong">{metric.title}</Typography>}
      {aggregatedMetric && (
        <ReportMetricTable
          metrics={[aggregatedMetric]}
          currency={currency ?? ''}
          standardId={materialStandardId}
        />
      )}
    </VStack>
  );
};
