import {
  useEsrsAssessmentQuery,
  useGetEsrsCategoriesQuery,
  useGetParentMaterialityAssessmentQuery,
} from 'models';
import { useMemo } from 'react';
import {
  EsrsCategoryMap,
  StandardsOverviewData,
} from '../../GroupAssessment/GroupAssessmentOverview.hooks';
import { MetricDataCollection } from '../../MaterialityAssessment';
import { isMetricStateMaterial, orderCategories } from 'containers/Esrs/utils';
import { captureException } from '@sentry/react';

export const useGetSubsidiaryStandardsOverview = (filter: string, esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return orderCategories(esrsCategoriesData?.EsrsCategory ?? []);
  }, [esrsCategoriesData]);

  const groupMaterialityMap: EsrsCategoryMap[] = useMemo(() => {
    const materialStandards = data?.esrsAssessment?.materialStandards;
    const parentMaterialityAssessments =
      parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards;

    if (materialStandards) {
      return categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                return {
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isMaterialForGroup:
                    parentMaterialityAssessments?.find(
                      (materialityAssessment) =>
                        materialityAssessment.standardRef === standard.reference
                    )?.isMaterial === true,
                  isMaterialForSubsidiary:
                    materialStandards.find((materialStandard) => {
                      return materialStandard.standardRef === standard.reference;
                    })?.isMaterial === true,
                };
              })
              .filter(
                (standard) =>
                  standard.isMaterialForGroup == true || standard.isMaterialForSubsidiary == true
              ) ?? [],
        };
      });
    } else return [];
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData, filter]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  const groupStandardsOverviewData: StandardsOverviewData = {
    categories: groupMaterialityMap,
    loading,
  };

  return groupStandardsOverviewData;
};

export const useGetBusinessUnitStandardsOverview = (
  filter: string,
  esrsAssessmentId?: string,
  isBusinessUnitLevel?: boolean
) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return esrsCategoriesData?.EsrsCategory ?? [];
  }, [esrsCategoriesData]);

  const materialityMap: EsrsCategoryMap[] = useMemo(() => {
    try {
      const materialStandardsViaMetrics = data?.esrsAssessment?.materialStandards?.map(
        (materialStandard) => {
          return {
            standardRef: materialStandard.standardRef,
            dataCollection: materialStandard.materialMetrics?.map((materialMetric) => {
              return materialMetric.dataCollection;
            }),
          };
        }
      );

      const materialStandards = data?.esrsAssessment?.materialStandards;
      const parentMaterialityAssessments =
        parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards;

      if (materialStandards) {
        return categories.map((category) => {
          return {
            categoryName: category.title,
            categoryRef: category.reference,
            materialStandards:
              category.standards
                ?.map((standard) => {
                  const parentStandard = parentMaterialityAssessments?.find(
                    (ms) => ms.standardRef === standard.reference
                  );
                  const materialStandard = materialStandards?.find(
                    (ms) => ms.standardRef === standard.reference
                  );

                  return {
                    standardRef: standard.reference,
                    standardName: standard.title,
                    description: standard.description,
                    isMaterialForGroup: parentStandard?.isMaterial,
                    isMaterialForSubsidiary: isMetricStateMaterial(materialStandard?.isMaterial),
                    materialMetrics: materialStandard?.materialMetrics,
                    parentMaterialMetrics:
                      parentStandard?.materialMetrics.filter((metric) => metric.isMaterial) ?? [],
                  };
                })
                .filter((standard) => {
                  const metricsDataCollection = materialStandardsViaMetrics?.find(
                    (materialStandardViaMetric) =>
                      materialStandardViaMetric.standardRef === standard.standardRef
                  )?.dataCollection;

                  const dataCollectionCondition = isBusinessUnitLevel
                    ? metricsDataCollection?.some(
                        (dataCollection) =>
                          dataCollection === MetricDataCollection.reportingUnitLevel
                      )
                    : metricsDataCollection?.some(
                        (dataCollection) => dataCollection === MetricDataCollection.companyLevel
                      );

                  if (filter === 'all') {
                    return (
                      (standard.isMaterialForGroup == true ||
                        standard.isMaterialForSubsidiary == true) &&
                      dataCollectionCondition
                    );
                  } else if (filter === 'not-material') {
                    return (
                      standard.isMaterialForGroup == true &&
                      standard.isMaterialForSubsidiary == false &&
                      dataCollectionCondition
                    );
                  } else if (filter === 'material') {
                    return standard.isMaterialForSubsidiary == true && dataCollectionCondition;
                  }
                }) ?? [],
          };
        }) as unknown as EsrsCategoryMap[];
      } else return [];
    } catch (e) {
      captureException(Error('Failed to get business unit standards overview'), { extra: { e } });
      return [];
    }
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData, filter, isBusinessUnitLevel]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  const standardsOverviewData: StandardsOverviewData = {
    categories: materialityMap,
    loading,
  };

  return standardsOverviewData;
};
