import { isAfter } from 'date-fns';
import {
  ActivityReportWithVersion,
  ActivityVersionConfig,
  ActivityVersionMigrationStatus,
  ResolvedQuestion,
  useAllActivityAssessmentsWithVersionQuery,
  useCompanyAssessmentQuery,
  useGetActivityAssessmentVersionQuery,
  useOldActivityAssessmentQuery,
  useUpdateActivityAssessmentVersionMutation,
  useValidActivityVersionsForCompanyLazyQuery_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useActivityAssessmentWithAnswers, useFlagAndUnlockAssessment } from './Assessments.hooks';
import { isEqual, uniq, uniqWith } from 'lodash';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';
import { stringToDate } from 'utils/date';
import { toRoman } from 'roman-numerals';
import {
  useSaveAnswerAttachmentMutation,
  useSaveAnswerNoteMutation,
} from 'Features/Screening/Screening.hooks';

export type ActivityMetaData = {
  name: string;
  reference: string;
  changeDescription?: string;
  versionNumber?: number;
  validFrom?: Date;
};

const shouldUpdateVersion = (
  assessmentVersion: { startDate: Date; versionNumber?: number },
  newestVersion: { startDate: Date; versionNumber?: number }
) => {
  if (!assessmentVersion.versionNumber) return true;
  if (!newestVersion.versionNumber) return false;

  return (
    assessmentVersion.versionNumber < newestVersion.versionNumber &&
    !isAfter(newestVersion.startDate, assessmentVersion.startDate)
  );
};

const hasNotSkippedVersion = (activityReport: ActivityReportWithVersion) =>
  activityReport.activity.currentVersion?.versionNumber !==
  activityReport.activityVersionConfig?.skipVersion;

export const useActivityChangelog = (cAssessmentId?: string) => {
  const { data, loading, error } = useAllActivityAssessmentsWithVersionQuery({
    variables: { cAssessmentId },
    skip: !cAssessmentId,
  });
  const {
    data: cAssessmentData,
    loading: cAssessmentLoading,
    error: cAssessmentError,
  } = useCompanyAssessmentQuery({
    variables: { cAssessmentId: cAssessmentId },
    skip: !cAssessmentId,
  });

  // TODO: What should we do if an activityAssessment is two or more versions behind, and the validFrom in currentVersion is too new?
  const outdatedActivityReports = useMemo(
    () =>
      data?.ActivityReport.filter((ar) =>
        shouldUpdateVersion(
          {
            versionNumber: ar.activityVersionNumber,
            startDate: stringToDate(ar.bAssessment.cAssessment.startDate),
          },
          {
            versionNumber: ar.activity.currentVersion?.versionNumber,
            startDate: stringToDate(ar.activity.currentVersion?.validFrom),
          }
        )
      ) ?? [],
    [data, loading]
  );

  const outdatedActivities: ActivityMetaData[] = useMemo(
    () =>
      uniqWith(
        outdatedActivityReports.map((ar) => ({
          name: ar.activity.name,
          reference: ar.activityRef,
          ...ar.activity.currentVersion,
        })),
        isEqual
      ) ?? [],
    [data, loading]
  );

  const updatedActivityReports = useMemo(
    () =>
      data?.ActivityReport.filter((ar) => {
        const previousVersion = parseInt(ar.activityVersionConfig?.previousVersion);
        if (!isNaN(previousVersion) && previousVersion + 1 === ar.activityVersionNumber)
          return true;
        return false;
      }) ?? [],
    [data, loading]
  );

  const updatedActivities: ActivityMetaData[] = useMemo(
    () =>
      uniqWith(
        updatedActivityReports.map((ar) => ({
          name: ar.activity.name,
          reference: ar.activityRef,
          ...ar.activity.currentVersion,
        })),
        isEqual
      ) ?? [],
    [data, loading]
  );

  const showActivityChangeModal = useMemo(
    () =>
      !cAssessmentData?.companyAssessment?.isLocked &&
      outdatedActivityReports.some(hasNotSkippedVersion),
    [outdatedActivityReports, cAssessmentData]
  );
  const showActivityChangeBanner = useMemo(
    () =>
      !cAssessmentData?.companyAssessment?.isLocked &&
      (!!outdatedActivities.length ||
        data?.ActivityReport.some(
          (ar) => ar.activityVersionConfig?.migrationStatus === 'inProgress'
        )),
    [outdatedActivities, data?.ActivityReport, cAssessmentData]
  );

  const isVersioningMigrationInProgress = useMemo(
    () =>
      data?.ActivityReport.some((ar) => ar.activityVersionConfig?.migrationStatus === 'inProgress'),
    [data?.ActivityReport]
  );

  const [
    updateActivityAssessmentVersion,
    { loading: updateActivityVersionLoading, error: updateActivityVersionError },
  ] = useUpdateActivityAssessmentVersionMutation();

  const skipActivityVersion = useCallback(() => {
    const updates = outdatedActivityReports.filter(hasNotSkippedVersion).map((ar) => ({
      _append: {
        activityVersionConfig: {
          skipVersion: ar.activity.currentVersion?.versionNumber,
          migrationStatus: 'skipped',
        },
      },
      where: { id: { _eq: ar.id } },
    }));

    if (updates.length) {
      return updateActivityAssessmentVersion({ variables: { updates } });
    }
  }, [outdatedActivityReports, updateActivityAssessmentVersion]);

  const [flagAssessment] = useFlagAndUnlockAssessment();

  const upgradeActivityVersion = useCallback(() => {
    const updates = outdatedActivityReports.map((ar) => ({
      _set: {
        activityVersionNumber: ar.activity.currentVersion?.versionNumber,
        activityVersionConfig: {
          previousVersion: ar.activityVersionNumber,
          dismissedRemovedQuestionsObjectives: [],
          migrationStatus: 'inProgress',
          migrationStartDate: new Date().toISOString(),
        },
      },
      where: { id: { _eq: ar.id } },
    }));

    if (updates.length) {
      return updateActivityAssessmentVersion({ variables: { updates } }).then((res) => {
        if (res.data?.update_ActivityReport_many?.some((r) => !!r?.affected_rows)) {
          return flagAssessment(data?.ActivityReport[0].bAssessment.cAssessment);
        }
      });
    }
  }, [outdatedActivityReports, updateActivityAssessmentVersion]);

  return {
    outdatedActivities,
    updatedActivities,
    showActivityChangeBanner,
    showActivityChangeModal,
    skipActivityVersion,
    upgradeActivityVersion,
    loading: loading || cAssessmentLoading,
    error: error || cAssessmentError,
    updateActivityVersionLoading,
    updateActivityVersionError,
    isVersioningMigrationInProgress,
  };
};

export const useActivityAssessmentVersion = (
  activityAssessmentId: string,
  config: { skip?: boolean } = { skip: false }
) => {
  const { skip } = config;
  const { data: versionData } = useGetActivityAssessmentVersionQuery({
    variables: { activityAssessmentId },
    skip: !activityAssessmentId || skip,
  });

  const currentVersion = useMemo(
    () => versionData?.activityAssessment?.activityVersionNumber,
    [versionData]
  );
  const newestVersion = useMemo(
    () => versionData?.activityAssessment?.activity.currentVersion?.versionNumber,
    [versionData]
  );

  const previousVersion = useMemo(
    () => versionData?.activityAssessment?.activityVersionConfig?.previousVersion,
    [versionData]
  );

  return { currentVersion, previousVersion, newestVersion };
};

export const getRemovedQuestions = (oldQuestions: Array<ResolvedQuestion>) =>
  oldQuestions.filter((oldQuestion) => !oldQuestion.newerRevisions?.length);

export const getNewQuestionIds = (
  currentQuestions: Array<ResolvedQuestion>,
  oldQuestions: Array<Pick<ResolvedQuestion, 'id' | 'uniqueId'>>
) => {
  const qs = currentQuestions
    .filter(
      (q) =>
        !oldQuestions.map((oq) => oq.id).includes(q.id) &&
        !q.olderRevisions?.length &&
        !q.answer?.data
    )
    .map((q) => q.id);
  // console.log(
  //   'neq questions',
  //   oldQuestions.map((oq) => oq.uniqueId),
  //   a.map((q) => q.uniqueId)
  // );
  return qs;
};

export const useActivityVersionComparison = (activityAssessmentId: string) => {
  const { currentVersion, previousVersion } = useActivityAssessmentVersion(activityAssessmentId);
  const { data: currentObjectiveData } = useActivityAssessmentWithAnswers(
    activityAssessmentId,
    currentVersion
  );
  const { data: previousObjectiveData } = useActivityAssessmentWithAnswers(
    activityAssessmentId,
    previousVersion
  );
  const { data: replacedQuestionInfo } = useOldActivityAssessmentQuery({
    variables: {
      activityAssessmentId,
      currentVersion: currentVersion ?? 1,
      previousVersion: previousVersion ?? 1,
    },
    skip: !activityAssessmentId || !currentVersion || !previousVersion,
  });

  const activityVersionConfig: ActivityVersionConfig = useMemo(() => {
    return currentObjectiveData.activityAssessment?.activityVersionConfig ?? {};
  }, [currentObjectiveData]);

  const replacedQuestions = useMemo(
    () => replacedQuestionInfo?.objectives.flatMap((o) => o.oldQuestions) ?? [],
    [replacedQuestionInfo]
  );

  const currentQuestions = useMemo(
    () => currentObjectiveData?.objectives.flatMap((o) => o.questions) ?? [],
    [currentObjectiveData]
  );

  const previousQuestions = useMemo(
    () =>
      previousObjectiveData?.objectives
        .flatMap((o) => o.questions)
        .filter((q) => replacedQuestions.find((rq) => rq.id === q.id))
        .map((q) => ({
          ...q,
          newerRevisions: replacedQuestions.find((rq) => rq.id === q.id)?.newerRevisions,
        })) ?? [],
    [previousObjectiveData, replacedQuestions]
  );

  const unchangedQuestions = useMemo(
    () => previousObjectiveData?.objectives.flatMap((o) => o.questions) ?? [],
    [previousObjectiveData]
  );

  const removedQuestions = useMemo(
    () => getRemovedQuestions(previousQuestions),
    [previousQuestions]
  );
  const removedQuestionsPerObjective = useMemo(
    () =>
      removedQuestions.reduce(
        (objectiveMap, question) => ({
          ...objectiveMap,
          [question.objective.key]: (
            objectiveMap[question.objective.key as ObjectiveId] ?? []
          ).concat(question),
        }),
        {} as Record<ObjectiveId, ResolvedQuestion[]>
      ),
    [removedQuestions]
  );

  const newQuestionIds = useMemo(
    () => getNewQuestionIds(currentQuestions, unchangedQuestions.concat(previousQuestions)),
    [currentQuestions, unchangedQuestions, previousQuestions]
  );

  const checkIfIsNewQuestion = useCallback(
    (question: { id: string }) => newQuestionIds.includes(question.id),
    [newQuestionIds]
  );

  const hasNewChanges = useMemo(
    () =>
      activityVersionConfig.migrationStatus === ActivityVersionMigrationStatus.inProgress &&
      (!!newQuestionIds.length ||
        (!!activityVersionConfig?.dismissedRemovedQuestionsObjectives?.length &&
          activityVersionConfig?.dismissedRemovedQuestionsObjectives?.length !==
            Object.keys(removedQuestionsPerObjective).length) ||
        !currentQuestions.filter((q) => q.olderRevisions?.length).every((q) => !!q.answer.data)),
    [
      newQuestionIds,
      activityVersionConfig?.dismissedRemovedQuestionsObjectives,
      Object.keys(removedQuestionsPerObjective),
      currentQuestions,
    ]
  );

  const [updateActivityAssessmentVersion, { loading: updateActivityVersionLoading }] =
    useUpdateActivityAssessmentVersionMutation();

  const markRemovedQuestionsAsReviewed = useCallback(
    (objectiveKey: ObjectiveId) => {
      return updateActivityAssessmentVersion({
        variables: {
          updates: [
            {
              _append: {
                activityVersionConfig: {
                  dismissedRemovedQuestionsObjectives: (
                    activityVersionConfig?.dismissedRemovedQuestionsObjectives ?? []
                  ).concat(objectiveKey),
                },
              },
              where: { id: { _eq: activityAssessmentId } },
            },
          ],
        },
      });
    },
    [currentObjectiveData, updateActivityAssessmentVersion]
  );

  const getRevision = useCallback(
    (currentQuestionId: string, olderRevisions: ResolvedQuestion['olderRevisions']) => {
      const sortQuestions = (a?: ResolvedQuestion, b?: ResolvedQuestion) => {
        if (a?.id === currentQuestionId) return -1;
        if (b?.id === currentQuestionId) return 1;
        return a?.orderIndex.localeCompare(b?.orderIndex ?? '') ?? -1;
      };
      const oldQuestions = previousQuestions
        .filter((rq) => olderRevisions?.find((r) => r.oldQuestion.id === rq.id))
        .sort(sortQuestions);
      const revisedQuestions = uniq(
        oldQuestions.flatMap((q) => q.newerRevisions?.map((r) => r.newQuestion.id))
      )
        .map((qId) => currentQuestions.find((q) => q.id === qId))
        .filter((q): q is (typeof currentQuestions)[number] => q?.id)
        .sort(sortQuestions);

      const message = uniq(
        oldQuestions
          .flatMap((q) => q.newerRevisions?.map((r) => r.description))
          .concat(olderRevisions?.map((r) => r.description))
          .filter((d) => !!d)
      ).join('\n');

      return { oldQuestions, revisedQuestions, message };
    },
    [previousQuestions]
  );

  return {
    removedQuestionsPerObjective,
    getRevision,
    markRemovedQuestionsAsReviewed,
    updateActivityVersionLoading,
    activityVersionConfig,
    checkIfIsNewQuestion,
    hasNewChanges,
  };
};

export const useGetNewestValidActivityVersion = () => {
  const [activityVersionsForCompany] = useValidActivityVersionsForCompanyLazyQuery_();

  // This function should get the newest version of the activity that this assessment is allowed to used.
  // It checks that valid from is ok, that the activity has been used in the assessment before, and if so uses the same version
  const getNewestValidActivityVersion = useCallback(
    async (activityRef: string, startDate: string, cAssessmentId: string) => {
      const { data: activityVersions } = await activityVersionsForCompany({
        variables: { activityRef, startDate, cAssessmentId },
      });

      // The current version might be lower than newest allowed version, in case we are
      // working on a new one that hasn't been released yet, in that case use current version
      const currentVersion = activityVersions?.activity?.currentQuestionSetVersion;
      const newestAllowedVersion =
        activityVersions?.activity?.versions[0]?.versionNumber ?? currentVersion;
      const newestInCompanyVersion =
        activityVersions?.activity?.versionsInCompanyAssessment?.[0]?.activityVersionNumber;

      const versionNumber = Math.min(
        ...[currentVersion, newestAllowedVersion, newestInCompanyVersion].filter(
          (v): v is number => !!v
        )
      );

      return versionNumber;
    },
    []
  );

  return {
    getNewestValidActivityVersion,
  };
};

export const indexToRoman = (index: number) => toRoman(index + 1).toLocaleLowerCase();

export const useCopyDocumentation = (activityAssessmentId: string) => {
  const saveAttachments = useSaveAnswerAttachmentMutation();
  const saveNotes = useSaveAnswerNoteMutation();

  const copyNotes = useCallback(
    (oldQuestion: ResolvedQuestion, newQuestion: ResolvedQuestion) => {
      const note = oldQuestion.answer?.noteHistory?.notes[0].body ?? '';

      return saveNotes(
        note,
        newQuestion.answer?.reportId ?? activityAssessmentId,
        newQuestion.id,
        newQuestion.answer?.noteHistory?.id
      );
    },
    [saveNotes, activityAssessmentId]
  );

  const copyAttachments = useCallback(
    (oldQuestion: ResolvedQuestion, newQuestion: ResolvedQuestion) => {
      const attachments = oldQuestion.answer?.attachmentBox?.attachments.map((a) => a.file) ?? [];
      return saveAttachments(
        attachments,
        newQuestion.answer?.reportId ?? activityAssessmentId,
        newQuestion.id,
        newQuestion.answer?.attachmentBox ?? undefined
      );
    },
    [saveAttachments, activityAssessmentId]
  );

  return {
    copyNotes,
    copyAttachments,
  };
};
