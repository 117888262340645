import { HStack, VStack, Box, FormLabel, ListItem, UnorderedList } from '@chakra-ui/react';
import { IconButton, Infobox, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  GetMetricsDrDocument_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  MaterialMetricsPerDisclosureDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useDeleteMaterialMetricTagMutation,
  useDeleteMaterialMetricTagValueMutation,
  useUpsertMaterialMetricsMutation,
  GetRequiredBuMaterialMetricsDocument_,
  GetRequiredMaterialMetricsDocument_,
} from 'models';
import { useToast } from 'utils/hooks';
import { groupBy, uniq } from 'lodash';
import { TagSelectionMenu, TagValuesSelector } from './TagSelectMenus';
import {
  MetricConfigModalProps,
  NoSelectedTags,
  TagBreakdown,
  TagStatus,
} from './MetricConfigModalParent';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { DataCollectionLevel } from '../DataCollection.d';

export const MetricConfigModal = ({
  isOpen,
  onClose,
  selectedMetricData,
  hasParentCompany,
  companyStandardId,
  parentStandardId,
}: MetricConfigModalProps & { hasParentCompany: boolean }) => {
  const [showAll, setShowAll] = useState(false);
  const toast = useToast();

  const materialMetric = useMemo(
    () =>
      selectedMetricData?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
    [selectedMetricData, companyStandardId]
  );

  const selectedMaterialMetricId = useMemo(() => materialMetric?.id, [materialMetric]);

  const showParentWarning = useMemo(
    () => selectedMetricData?.isChild && (selectedMetricData?.parentMetrics?.length ?? 0) > 1,
    [selectedMetricData]
  );

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromParent = useMemo(() => {
    if (!hasParentCompany) return [];
    return selectedMetricData?.materialMetrics.find(
      (mm) => mm.materialStandardId === parentStandardId
    )?.materialMetricTags;
  }, [selectedMetricData, hasParentCompany]);

  const tagsFromCompany = useMemo(() => {
    return materialMetric?.materialMetricTags;
  }, [materialMetric]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const justFromESRS =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
          alwaysAll: x.values.alwaysAll,
        })) ?? [];
    const justFromParent =
      tagsFromParent
        ?.filter((x) => !justFromESRS?.find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: true,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];
    const justFromCompany =
      tagsFromCompany
        ?.filter((x) => ![...justFromESRS, ...justFromParent].find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: false,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];

    const existing: TagBreakdown[] = [...justFromESRS, ...justFromParent, ...justFromCompany];
    const options: TagBreakdown[] =
      (adminPanelTags?.filter((x) => !existing.find((y) => y.type === x.type)) ?? [])
        .filter((t) => !!t.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: !x.isOptional,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromParent, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    if (tag.isFromParent) return TagStatus.requested;
    return TagStatus.added;
  };

  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [configuredTags, setConfiguredTags] = useState<{ tagType: string; tagValue: string }[]>([]);

  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation();

  const handleConfirm = async () => {
    const groupedConfiguredTags = groupBy(configuredTags, 'tagType');
    upsertMaterialMetric({
      variables: {
        objects: [
          {
            id: selectedMaterialMetricId,
            frequency: materialMetric?.frequency,
            isMaterial: materialMetric?.isMaterial,
            dataCollection: materialMetric?.dataCollection ?? DataCollectionLevel.company,
            materialStandardId: companyStandardId,
            metricRef: selectedMetricData?.reference,
            materialMetricTags: {
              data: Object.keys(groupedConfiguredTags).map((key) => ({
                tagType: key,
                materialTagValues: {
                  data: groupedConfiguredTags[key],
                  on_conflict: {
                    constraint:
                      Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                    update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                  },
                },
              })),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                update_columns: [Esrs_MaterialMetricTag_Update_Column_.IsOptional_],
              },
            },
          },
        ],
      },
      refetchQueries: [
        GetMetricsDrDocument_,
        ReportingUnitsMetricsPerDisclosureDocument_,
        GetRequiredMaterialMetricsDocument_,
        GetRequiredBuMaterialMetricsDocument_,
        MaterialMetricsPerDisclosureDocument_,
        EsrsAssessmentDocument_,
      ],
    })
      .then(() =>
        toast({
          text: 'Configured metric breakdown settings',
        })
      )
      .catch((error) => {
        console.log(error);
        toast({
          text: 'Unable to configure metric breakdown settings',
          variant: 'danger',
        });
      });

    const existingMaterialTags = materialMetric?.materialMetricTags.flatMap((materialTag) =>
      materialTag.materialTagValues.map((t) => ({
        tagType: t.tagType,
        tagValue: t.tagValue,
      }))
    );
    const tagValuesToDelete = existingMaterialTags?.filter((existingTag) => {
      return !configuredTags.some((configuredTag) => {
        return (
          configuredTag.tagType === existingTag.tagType &&
          configuredTag.tagValue === existingTag.tagValue
        );
      });
    });

    const existingMaterialTagTypes = materialMetric?.materialMetricTags.map((t) => t.tagType);
    const configuredMaterialTagTypes = uniq(availableTags.map((t) => t.type));
    const tagTypesToDelete = existingMaterialTagTypes?.filter(
      (tag) => !configuredMaterialTagTypes?.includes(tag)
    );

    try {
      if (tagTypesToDelete?.length)
        deleteMaterialMetricTag({
          variables: {
            materialMetricId: selectedMaterialMetricId,
            tagTypes: tagTypesToDelete ?? [],
          },
          refetchQueries: [
            GetMetricsDrDocument_,
            ReportingUnitsMetricsPerDisclosureDocument_,
            GetRequiredMaterialMetricsDocument_,
            GetRequiredBuMaterialMetricsDocument_,
            MaterialMetricsPerDisclosureDocument_,
          ],
        });

      if (tagValuesToDelete?.length)
        tagValuesToDelete?.forEach((tag) => {
          deleteMaterialMetricTagValue({
            variables: {
              materialMetricId: selectedMaterialMetricId,
              tagType: tag.tagType,
              tagValue: tag.tagValue,
            },
            refetchQueries: [
              GetMetricsDrDocument_,
              ReportingUnitsMetricsPerDisclosureDocument_,
              GetRequiredMaterialMetricsDocument_,
              GetRequiredBuMaterialMetricsDocument_,
              MaterialMetricsPerDisclosureDocument_,
            ],
          });
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (materialMetric?.materialMetricTags.length === 0) {
      setConfiguredTags(
        selectedMetricData?.adminPanelTags.flatMap((tag) => {
          if (tag.values.alwaysAll) {
            return tag.values.tagValues.map((t) => ({
              tagType: t.type,
              tagValue: t.value,
            }));
          }
          return [];
        }) ?? []
      );
    } else {
      setConfiguredTags(
        materialMetric?.materialMetricTags.flatMap((materialTag) =>
          materialTag.materialTagValues.map((t) => ({
            tagType: t.tagType,
            tagValue: t.tagValue,
          }))
        ) ?? []
      );
    }
  }, [selectedMetricData, materialMetric]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle } = useMemo(() => {
    return {
      title: selectedMetricData?.title,
      shortTitle: selectedMetricData?.shortTitle,
    };
  }, [selectedMetricData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <VStack spacing="2px" alignItems="start">
          <Typography variant="h2" mr="40px">
            {shortTitle ?? title ?? ''}
            {!!adminPanelTags?.length && (
              <>
                {' by '}
                <MetricTagsList
                  tags={adminPanelTags.map((tag) => tag.type)}
                  isHStack={false}
                  maxWidthInPixels={196}
                />
              </>
            )}
          </Typography>
          <Typography variant="detail">
            {selectedMetricData?.isChild ? 'Datapoint settings' : 'Metric settings'}
          </Typography>
        </VStack>
      }
      size={hasBreakdown ? 'lg' : 'md'}
      onConfirm={() => {
        handleConfirm();
        onClose();
      }}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        {showParentWarning && (
          <Box w="100%">
            <Infobox
              status="warning"
              title="Important"
              closable={false}
              description={
                <VStack spacing="0px" alignItems="start">
                  <Typography variant="body">
                    Note that this metric is used to calculate several other metrics, so changing
                    the settings here will propagate throughout the system.
                    {!showAll && '... '}
                    {!showAll && (
                      <Typography
                        variant="bodyStrong"
                        color="text.muted"
                        cursor="pointer"
                        as="span"
                        onClick={() => setShowAll(true)}
                      >
                        Show more
                      </Typography>
                    )}
                    {showAll && 'Below is the full list of metrics that rely on this metric.'}
                  </Typography>
                  {showAll && (
                    <>
                      <br />
                      <UnorderedList>
                        {selectedMetricData?.parentMetrics.map((parent) => (
                          <ListItem>{parent.parentMetric.title}</ListItem>
                        ))}
                      </UnorderedList>
                      {showAll && (
                        <Typography
                          variant="bodyStrong"
                          color="text.muted"
                          cursor="pointer"
                          as="span"
                          mt="8px"
                          onClick={() => setShowAll(false)}
                        >
                          Show less
                        </Typography>
                      )}
                    </>
                  )}
                </VStack>
              }
            />
          </Box>
        )}

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag minW="fit-content" variant="info" size="xs" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box w="150px">
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>

                        <TagValuesSelector
                          options={tag.values.map((v) => ({ tagType: tag.type, tagValue: v }))}
                          configured={configuredTags}
                          type={tag.type}
                          setConfigured={setConfiguredTags}
                        />
                        {!tag.isFromESRS && !tag.isFromParent ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                              setConfiguredTags((prev) =>
                                prev.filter((t) => t.tagType !== tag.type)
                              );
                            }}
                          />
                        ) : (
                          <Tooltip
                            label={
                              tag.isFromESRS
                                ? 'This breakdown is required by the ESRS. You can not delete this breakdown'
                                : 'This breakdown is requested by the parent company. You can not delete this breakdown'
                            }
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}
            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
