import { SelectedMetric } from 'containers/Esrs';
import { useOptOutMetricMutation, GetMetricAnswersDocument_ } from 'models';
import { Menu } from 'Molecules/Menu';
import { useParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { MetricsTableData, useEsrsAnswer } from '../MetricAnswers.hooks';

export const MetricMenu = ({
  metric,
  onOpen,
  setSelectedMetric,
  companyReportingUnit,
}: {
  metric: MetricsTableData['metric'];
  onOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  companyReportingUnit?: string;
}) => {
  const { reportingUnitId = companyReportingUnit, esrsAssessmentId = '' } = useParams();
  const [optOut] = useOptOutMetricMutation();
  const toast = useToast();
  const { answer } = useEsrsAnswer(metric?.reference, companyReportingUnit);

  const startReporting = () => {
    optOut({
      variables: {
        object: {
          metricRef: metric.reference,
          reportingUnitId,
          assessmentId: esrsAssessmentId,
          hasOptedOut: false,
          optOutReason: '',
        },
      },
      refetchQueries: [GetMetricAnswersDocument_],
    }).catch(() => {
      toast({ text: 'Failed to start reporting', variant: 'danger' });
    });
  };

  return (
    <Menu
      buttonVariant="secondary"
      sections={[
        {
          actions: [
            answer?.hasOptedOut
              ? {
                  id: 'start-reporting',
                  title: 'Start Reporting',
                  description:
                    'You are opted out from reporting on this metric. You can start reporting again',
                  onClick: startReporting,
                }
              : {
                  id: 'opt-out',
                  title: 'Mark as irrelevant',
                  onClick: () => {
                    setSelectedMetric({ reference: metric.reference });
                    onOpen();
                  },
                },
          ],
        },
      ]}
      size={answer?.hasOptedOut ? 'md' : 'sm'}
    />
  );
};
